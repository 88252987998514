import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toggleFavoriteSitterRequest } from '../../domain/Favorites/actions';
import { selectFavoriteSitterIds } from '../../domain/Favorites/selectors';
import { isJwtAuthenticated, isJwtAuthenticatedLoading } from '../../domain/User/Authentication/selectors';
import transformImmutableToJS from '../../utils/transformImmutableToJS';
import { trackToggleFavoriteClicks } from './Analytics';
import NeedToLoginBeforeFavorite from './NeedToLoginBeforeFavorite';
import { Box } from './Styles';

const FavoriteIndicator = ({
    className,
    isJwtAuthenticatedLoading,
    isAuthenticated,
    favoriteSitterIds,
    sitterId,
    sitterName,
    callBack,
    toggleFavoriteSitterRequest,
}) => {
    if (isJwtAuthenticatedLoading) {
        return null;
    }
    if (isAuthenticated) {
        const isFavorite = favoriteSitterIds.includes(sitterId);

        const onClick = () => {
            toggleFavoriteSitterRequest({
                sitterId,
                isFavorite: !isFavorite,
            });
            callBack(sitterId);
            trackToggleFavoriteClicks(!isFavorite);
        };

        return (
            <Box className={className} onClick={onClick}>
                <img
                    width={32}
                    height={32}
                    alt={`${isFavorite ? 'is favorite' : 'is not favorite sitter'}`}
                    src={`https://static1.pawshakecdn.com/global/${
                        isFavorite ? 'redFilledHeartFixed.svg' : 'redHollowHeart.svg'
                    }`}
                />
            </Box>
        );
    } else {
        return <NeedToLoginBeforeFavorite className={className} sitterName={sitterName} />;
    }
};

const mapStateToProps = (state) => {
    const computedData = createStructuredSelector({
        favoriteSitterIds: selectFavoriteSitterIds,
        isJwtAuthenticatedLoading: isJwtAuthenticatedLoading,
        isAuthenticated: isJwtAuthenticated,
    })(state);

    return transformImmutableToJS(computedData);
};

export default connect(mapStateToProps, { toggleFavoriteSitterRequest })(FavoriteIndicator);
