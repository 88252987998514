import flowRight from 'lodash/flowRight';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { StickyContainer } from 'react-sticky';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { isTablet } from '../../Application/Device/selectors';
import FavoriteIndicator from '../../Application/Favorites/FavoriteIndicator';
import Container from '../../Application/Layout/Container';
import Footer from '../../Application/Layout/Footer/Footer';
import PageContainer from '../../Application/Layout/PageContainer';
import StickyComponent from '../../Application/Layout/StickyComponent/StickyComponent';
import { getLocalBrandNameOf } from '../../Pawshake/Core/Domain';
import { petSittingService } from '../../Pawshake/Core/Service';
import { getBookableServiceBasedUponId } from '../../Pawshake/Core/Service/BookableService';
import SitterCanBeContactedApi from '../../Pawshake/Sitter/SitterCanBeContactedApi';
import { determineSitterProfileUrl } from '../../Pawshake/Sitter/SitterProfileUrl';
import Banner from '../../components/Common/Banner';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { registerProfileView, sitterCanBeContactedRequest } from '../../domain/Sitter/actions';
import {
    hasResidentialPets,
    hasSitterReviews,
    selectAdditionalOffers,
    selectAlternateProfileUrls,
    selectCanBeContactedByPetOwner,
    selectCanBeContactedOrBooked,
    selectCountryCode,
    selectLastActiveInMinutes,
    selectRepeatClientsOnlyBookableServices,
    selectSitterAcceptedDogAges,
    selectSitterAcceptedDogSexes,
    selectSitterAcceptedDogSizes,
    selectSitterAcceptedOtherAnimals,
    selectSitterAdditionalSkills,
    selectSitterBookableRates,
    selectSitterCancellationPolicy,
    selectSitterCurrency,
    selectSitterDescription,
    selectSitterFavoriteCount,
    selectSitterHomeCharacteristics,
    selectSitterId,
    selectSitterIsEmailVerified,
    selectSitterIsFacebookVerified,
    selectSitterIsPhoneVerified,
    selectSitterIsTextMessageVerified,
    selectSitterLivesNear,
    selectSitterMetaDescription,
    selectSitterName,
    selectSitterNameSlug,
    selectSitterNearbyOffLeashDogArea,
    selectSitterPlaceDescriptive,
    selectSitterPoliceCheck,
    selectSitterProfileImages,
    selectSitterResidentPets,
    selectSitterResponsePercentage,
    selectSitterResponseTime,
    selectSitterReviewCount,
    selectSitterSearchPageUrls,
    selectSitterSkills,
    selectSitterSpeaksLanguages,
    selectSitterVibrancy,
    selectSitterWalkingAreas,
    selectSitterYearsOfExperience,
    selectStarRatings,
    selectTopCities,
    selectUserId,
    selectUserImage,
    selectUserName,
} from '../../domain/Sitter/selectors';
import {
    isJwtAuthenticated,
    isJwtAuthenticatedLoading,
    jwtTokenSelect,
} from '../../domain/User/Authentication/selectors';
import { withTranslation } from '../../i18n';
import { combineTitleWithLocalBrandName } from '../../utils/page';
import {
    determineHighlightedService,
    sitterProvidesDoggyDayCareOrDogWalks,
    sitterProvidesHomeServices,
    withoutWorldRegionPrefix,
} from '../../utils/sitter';
import transformImmutableToJS from '../../utils/transformImmutableToJS';
import CancellationPolicy from '../Finance/CancellationPolicy';
import BasicPageOpenGraph from '../OpenGraph/BasicPageOpenGraph';
import Avatar from '../Shared/Avatar/Avatar';
import { GreenButton } from '../Shared/Button/Buttons';
import StaticMap from '../StaticMap/StaticMap';
import BasicPageTwitterTags from '../TwitterTags/BasicPageTwitterTags';
import SitterAchievements from './SitterAchievements';
import SitterAdditionalOffers from './SitterAdditionalOffers';
import SitterBookableServiceAvailability from './SitterBookableServiceAvailability';
import SitterBreadcrumbs from './SitterBreadcrumbs';
import SitterContact from './SitterContact';
import SitterGallery from './SitterGallery';
import SitterHighlightedService from './SitterHighlightedService';
import SitterHighlightedStatistics from './SitterHighlightedStatistics';
import SitterHome from './SitterHome';
import SitterInfo from './SitterInfo';
import SitterInfoCard from './SitterInfoCard';
import SitterJsonLD from './SitterJsonLD';
import SitterMoreServicesAroundPlace from './SitterMoreServicesAroundPlace';
import SitterRatings from './SitterRatings';
import SitterResidentPet from './SitterResidentPet';
import SitterReviews from './SitterReviews';
import SitterSection from './SitterSection';
import SitterServices from './SitterServices';
import SitterSkills from './SitterSkills';
import SitterVerifications from './SitterVerifications';

const RequestBookingButton = styled(GreenButton)`
    text-align: center;
`;

const SitterProfilePageContainer = styled(PageContainer)`
    min-height: 100vh;
    padding-bottom: 0;
    margin: 64px auto 0;
`;

const SitterProfileContentWrapper = styled.div`
    display: flex;
`;

const SitterJsonContainer = styled(Container)`
    margin-top: 0;
    padding-top: 0;
`;

const Content = styled.div`
    width: 100%;
    ${breakpoint('lg')`
    width: 65%;
  `};
`;

const Sidebar = styled(StickyContainer)`
    opacity: 0;
    ${breakpoint('lg')`
    opacity: 1;
    width: 35%;
    padding-left: 3rem;
  `};
`;

const StyledCancellationPolicy = styled(CancellationPolicy)`
    padding-top: 1rem;
    border-top: 1px solid #cecece;
    ${breakpoint('lg')`
    display: none;
  `};
`;

const Achievements = styled(SitterAchievements)`
    padding-bottom: 1rem;
`;

const HighlightedStatistics = styled(SitterHighlightedStatistics)`
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #cecece;
`;

const HighlightedService = styled(SitterHighlightedService)`
    padding-top: 1rem;
    border-top: 1px solid #cecece;
    ${breakpoint('lg')`
    display: none;
  `};
`;

const MobileBookNowWrapper = styled.div`
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #cecece;
    ${breakpoint('lg')`
    display: none;
  `};
`;

const StickyFooter = styled.div`
    position: fixed;
    border-top: 1px solid #cecece;
    padding: 1rem;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;

    ${breakpoint('lg')`
    display: none;
  `}
`;

const UserAvatar = styled(Avatar)`
    position: absolute;
    display: inline-block;

    width: 6rem;
    height: 6rem;
    top: -3rem;
    border: 2px solid white;

    ${breakpoint('md')`
    width: 7rem;
    height: 7rem;
    top: -3.5rem;
    border: 4px solid white;
 `};

    margin: auto 1rem auto 0;
    border-radius: 50%;
`;

const MobileHeader = styled.div`
    position: relative;
    bottom: 1rem;
    word-break: break-word;
    margin: 0;
    left: 1rem;
    ${breakpoint('lg')`
    display: none;
  `};
`;

const Info = styled.div`
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding-left: calc(7rem + 8px);
    h2 {
        margin-right: 0;
    }
    ${breakpoint('md')`
    padding-left: calc(9rem + 8px); 
  `}
    ${breakpoint('lg')`
    padding-left: 1rem;
  `};
`;

const StyledFavoriteIndicator = styled(FavoriteIndicator)`
    float: right;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 1rem;
`;

const Description = styled.div`
    font-size: 1rem !important;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
    span {
        font-size: 1rem !important;
        font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
    }
    p:empty {
        display: none;
    }
`;

const SitterResidentPets = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const BannerLink = styled.a`
    color: inherit;
    font-weight: bolder;
    line-height: inherit;
    margin: 0 4px;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        position: absolute;
        border-radius: 4px;
        bottom: -1px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: #2a4365;
        z-index: -1;
    }
    &:hover {
        color: #4da5df;
    }
    &:hover:before {
        background: #4da5df;
    }
`;

const SitterProfile = (props) => {
    const router = useRouter();
    const [isLoadingCanBeContacted, setIsLoadingCanBeContacted] = useState(true);
    const [canBeContacted, setCanBeContacted] = useState(undefined);
    const determineServiceSearchPageLink = (service) => {
        const { t, searchPageUrls, application } = props;

        if (searchPageUrls.length === 0) {
            return false;
        }

        let specificSearchPageUrl = searchPageUrls.find(
            (searchPageUrl) =>
                searchPageUrl.service === service.id && searchPageUrl.locale === application.locale.toString()
        );

        if (typeof specificSearchPageUrl === 'undefined') {
            specificSearchPageUrl = searchPageUrls.find((searchPageUrl) => searchPageUrl.service === service.id);
        }

        return {
            title: t(`search:search.header.title.${specificSearchPageUrl.service}`, {
                location: specificSearchPageUrl.placeName,
            }),
            text: specificSearchPageUrl.placeName,
            href: specificSearchPageUrl.url,
        };
    };

    const determineServiceTopCityUrl = (service) => {
        const { t, topCities, application } = props;

        if (topCities.length === 0) {
            return determineServiceSearchPageLink(service);
        }

        const { locale } = application;

        let specificServiceTopCityUrl = topCities.find(
            (topCityUrl) => topCityUrl.service === service.id && topCityUrl.locale === locale.toString()
        );

        if (typeof specificServiceTopCityUrl === 'undefined') {
            specificServiceTopCityUrl = topCities.find((topCityUrl) => topCityUrl.service === service.id);
        }
        if (typeof specificServiceTopCityUrl === 'undefined') {
            return false;
        }

        return {
            title: t(`search:search.header.title.${specificServiceTopCityUrl.service}`, {
                location: specificServiceTopCityUrl.placeName,
            }),
            text: specificServiceTopCityUrl.placeName,
            href: specificServiceTopCityUrl.url,
        };
    };

    const determineCanonical = () => {
        const { alternateProfileUrls, localeString, sitterId } = props;

        if (alternateProfileUrls.length === 0) {
            return `${domain}/sitter/${sitterId}`;
        }

        return determineSitterProfileUrl(alternateProfileUrls, localeString);
    };

    const renderPetSitterInLocationString = () => {
        const { t, placeDescriptive } = props;
        const petSittingSearchPageUrl = determineServiceSearchPageLink(petSittingService);

        const location = petSittingSearchPageUrl ? petSittingSearchPageUrl.text : placeDescriptive;

        return t('sitter.metaData.petSitterInLocation', { location });
    };

    const renderPageTitle = () => {
        const { userName } = props;
        return `${userName} | ${renderPetSitterInLocationString()}`;
    };

    const renderPageDescription = () => {
        const { metaDescription } = props;
        return `${renderPetSitterInLocationString()}. ${metaDescription}`;
    };

    const {
        t,
        sitterCanBeContactedRequest,
        isAuthenticated,
        isJwtAuthenticatedLoading,
        userId,
        userName,
        sitterName,
        description,
        starRatings,
        responseTime,
        responsePercentage,
        lastActiveInMinutes,
        bookableRates,
        currency,
        policy,
        speaksLanguages,
        yearsOfExperience,
        skills,
        additionalSkills,
        acceptedOtherAnimals,
        acceptedDogSizes,
        acceptedDogAges,
        acceptedDogSexes,
        homeCharacteristics,
        walkingAreas,
        nearbyOffLeashDogArea,
        livesNear,
        residentPets,
        reviewCount,
        favoriteCount,
        vibrancy,
        userImage,
        profileImages,
        placeDescriptive,
        sitterId,
        sitterNameSlug,
        additionalOffers,
        hasReviews,
        hasResidentialPets,
        isPhoneInterviewed,
        isFacebookVerified,
        isTextMessageVerified,
        isEmailVerified,
        policeCheck,
        alternateProfileUrls,
        countryCode,
        canBeContactedOrBooked,
        canBeContactedByPetOwner,
        repeatClientsOnlyBookableServices,
        mobile,
        topCities,
        searchPageUrls,
        domain,
        application,
        jwtToken,
    } = props;

    const pageTitle = renderPageTitle();
    const pageDescription = renderPageDescription();
    const localBrandName = getLocalBrandNameOf(domain);

    const [highlightedService, setHighlightedService] = useState(
        determineHighlightedService(bookableRates, router.query.service)
    );

    useEffect(() => {
        const { registerProfileView, sitterId } = props;
        registerProfileView({ sitterId });

        if (window) {
            const params = new URLSearchParams(window.location.search);

            const bookableServices = Object.keys(bookableRates);

            if (params.service === 'homeVisits') {
                if (bookableServices.includes('oneHomeVisit')) {
                    setHighlightedService(getBookableServiceBasedUponId('oneHomeVisit'));
                } else {
                    if (bookableServices.includes('twoHomeVisits')) {
                        setHighlightedService(getBookableServiceBasedUponId('twoHomeVisits'));
                    }
                }
            } else {
                if (bookableServices.includes(params.service)) {
                    setHighlightedService(getBookableServiceBasedUponId(params.service));
                }
            }
        }
    }, []);

    useEffect(() => {
        if (isJwtAuthenticatedLoading === false && isAuthenticated && jwtToken) {
            sitterCanBeContactedRequest({ sitterId });

            SitterCanBeContactedApi.byPetOwner(jwtToken, sitterId)
                .then((data) => {
                    setIsLoadingCanBeContacted(false);
                    // for some reason the api just sends back true/false (not even json 🫠)
                    setCanBeContacted(data.data);
                })
                .catch(() => {
                    setIsLoadingCanBeContacted(false);
                });
        } else {
            setIsLoadingCanBeContacted(false);
        }
    }, [isJwtAuthenticatedLoading, isAuthenticated, jwtToken]);

    const canonical = determineCanonical();
    const petSittingSearchPage = determineServiceTopCityUrl(petSittingService);
    const showSitterMoreServicesAroundPlace = topCities.length > 0 || searchPageUrls.length > 0;

    const socialMediaProps = {
        title: pageTitle,
        description: pageDescription,
        url: canonical,
        image: profileImages[0].medium,
    };

    const showCancellationPolicyExtraEducation =
        policy === 'moderate' && sitterProvidesDoggyDayCareOrDogWalks(bookableRates);

    let similarSittersSearchUrl;

    if (livesNear && livesNear.latitude && livesNear.longitude) {
        similarSittersSearchUrl = `/search
            ?locale=${application.locale.toString()}
            &latitude=${livesNear.latitude}
            &longitude=${livesNear.longitude}
            &placeName=${placeDescriptive}
            &service=${highlightedService.id}
        `;
    }

    const queryparams = parsedUrlQueryToURLSearchParams(router.query, sitterId).toString();

    return (
        <>
            <Head>
                <title>{combineTitleWithLocalBrandName(pageTitle, localBrandName)}</title>
                <meta name='description' content={pageDescription} />
                <link rel='canonical' href={canonical} />
                <meta property='profile:first_name' content={userName} />
                <meta property='profile:username' content={sitterNameSlug} />

                {alternateProfileUrls.length > 1 &&
                    alternateProfileUrls.map((link) => (
                        <link key={link.locale} rel={'alternate'} hrefLang={link.locale} href={link.url} />
                    ))}
            </Head>
            <BasicPageOpenGraph type={'profile'} {...socialMediaProps} />
            <BasicPageTwitterTags {...socialMediaProps} />
            <SitterProfilePageContainer>
                <SitterProfileContentWrapper>
                    <Content>
                        {/* look at canBeContactedByPetOwner.canBeContacted when not authenticated */}
                        {isJwtAuthenticatedLoading === false &&
                            isAuthenticated === false &&
                            canBeContactedByPetOwner.canBeContacted === false &&
                            similarSittersSearchUrl && (
                                <Banner icon='/static/icons/ic-error.svg'>
                                    <div>
                                        {t('common:errors.cannotBeContacted.description1')}
                                        <Link href={similarSittersSearchUrl.replace(/\s/g, '')} passHref>
                                            <BannerLink>{t('common:errors.cannotBeContacted.action')}</BannerLink>
                                        </Link>
                                        {t('common:errors.cannotBeContacted.description2')}
                                    </div>
                                </Banner>
                            )}
                        {/* when authenticated, we should only show the banner if 
                the api call is done loading and the server returned false */}
                        {isJwtAuthenticatedLoading === false &&
                            isAuthenticated === true &&
                            isLoadingCanBeContacted === false &&
                            canBeContacted === false &&
                            similarSittersSearchUrl && (
                                <Banner icon='/static/icons/ic-error.svg'>
                                    <div>
                                        {t('common:errors.cannotBeContacted.description1')}
                                        <Link href={similarSittersSearchUrl.replace(/\s/g, '')} passHref>
                                            <BannerLink>{t('common:errors.cannotBeContacted.action')}</BannerLink>
                                        </Link>
                                        {t('common:errors.cannotBeContacted.description2')}
                                    </div>
                                </Banner>
                            )}
                        <SitterGallery
                            mobile={mobile}
                            images={profileImages}
                            userName={userName}
                            sitterName={sitterName}
                            userImage={userImage}
                            location={placeDescriptive}
                        />
                        <MobileHeader>
                            <UserAvatar alt={userName} src={userImage} />
                            <Info>
                                <StyledFavoriteIndicator sitterName={userName} sitterId={sitterId} />
                                <SitterInfo sitterName={sitterName} placeName={placeDescriptive} />
                            </Info>
                        </MobileHeader>
                        <Achievements
                            overallAverage={starRatings.overallAverage}
                            reviewCount={reviewCount}
                            userId={userId}
                            favoriteCount={favoriteCount}
                            vibrancy={vibrancy}
                        />
                        <HighlightedStatistics
                            lastActiveInMinutes={lastActiveInMinutes}
                            responseTime={responseTime}
                            responsePercentage={responsePercentage}
                        />
                        <HighlightedService
                            service={highlightedService}
                            repeatClientsOnlyBookableServices={repeatClientsOnlyBookableServices}
                            bookableRates={bookableRates}
                            currency={currency}
                            mobile={true}
                        />
                        {canBeContactedByPetOwner.canBeContacted === false && (
                            <MobileBookNowWrapper>
                                <SitterContact name={userName} {...canBeContactedByPetOwner} />
                            </MobileBookNowWrapper>
                        )}
                        <SitterSection
                            label={t('sitter.aboutSitterTitle', {
                                name: userName,
                            })}
                        >
                            <Description>
                                {ReactHtmlParser('<p>' + description.split('\n').join('</p><p>') + '</p>')}
                            </Description>
                        </SitterSection>
                        <SitterSection
                            label={t('sitter.sitterRatesTitle', {
                                name: userName,
                            })}
                            id={'services'}
                        >
                            <SitterServices
                                bookableRates={bookableRates}
                                currency={currency}
                                repeatClientsOnlyBookableServices={repeatClientsOnlyBookableServices}
                            />
                            {additionalOffers.length > 0 && (
                                <SitterAdditionalOffers name={userName} offers={additionalOffers} />
                            )}
                            <StyledCancellationPolicy
                                cancellationPolicy={policy}
                                showExtraEducation={showCancellationPolicyExtraEducation}
                            />
                        </SitterSection>
                        {hasReviews && (
                            <SitterSection label={t('sitter.reviews.groupTitle')} id={'reviews'}>
                                <SitterRatings
                                    userId={userId}
                                    value={starRatings.value}
                                    communication={starRatings.communication}
                                    profileCorrectness={starRatings.profileCorrectness}
                                    accuracy={starRatings.accuracy}
                                    animalTreatment={starRatings.animalTreatment}
                                />
                                <SitterReviews sitterId={sitterId} />
                            </SitterSection>
                        )}
                        <SitterSection label={t('sitter.availabilityTitle')} id={'availability'}>
                            <SitterBookableServiceAvailability
                                sitterId={sitterId}
                                bookableService={highlightedService}
                                bookableServices={bookableRates}
                            />
                        </SitterSection>
                        {hasResidentialPets && (
                            <SitterSection
                                label={t('sitter.petsTitle', {
                                    name: userName,
                                })}
                            >
                                <SitterResidentPets>
                                    {residentPets.map((pet) => (
                                        <SitterResidentPet
                                            key={pet.petId}
                                            name={pet.name}
                                            petId={pet.petId}
                                            breed={pet.breed}
                                            species={pet.species}
                                            imageUrl={pet.imageUrl}
                                        />
                                    ))}
                                </SitterResidentPets>
                            </SitterSection>
                        )}
                        {sitterProvidesHomeServices(bookableRates) && (
                            <SitterSection
                                label={t('sitter.aboutHome.groupTitle', {
                                    name: userName,
                                })}
                            >
                                <SitterHome
                                    name={userName}
                                    dogAges={acceptedDogAges}
                                    dogSizes={acceptedDogSizes}
                                    dogSexes={acceptedDogSexes}
                                    acceptedOtherAnimals={acceptedOtherAnimals}
                                    characteristics={homeCharacteristics}
                                    nearbyOffLeashDogArea={nearbyOffLeashDogArea}
                                    walkingAreas={walkingAreas}
                                />
                                {livesNear && (
                                    <StaticMap latitude={livesNear.latitude} longitude={livesNear.longitude} />
                                )}
                            </SitterSection>
                        )}
                        <SitterSection label={t('sitter.skills.groupTitle')}>
                            <SitterSkills
                                speaksLanguages={speaksLanguages}
                                yearsOfExperience={yearsOfExperience}
                                skills={skills}
                                additionalSkills={additionalSkills}
                            />
                        </SitterSection>
                        <SitterSection label={t('sitter.statistics.title')}>
                            <SitterVerifications
                                isPhoneInterviewed={isPhoneInterviewed}
                                isFacebookVerified={isFacebookVerified}
                                isTextMessageVerified={isTextMessageVerified}
                                isEmailVerified={isEmailVerified}
                                policeCheck={policeCheck}
                            />
                        </SitterSection>
                        {showSitterMoreServicesAroundPlace && (
                            <SitterSection
                                label={t('sitter.metaData.pageSearchLinks.moreServicesNear', {
                                    location: placeDescriptive,
                                })}
                            >
                                <SitterMoreServicesAroundPlace
                                    searchPageUrls={topCities.length > 0 ? topCities : searchPageUrls}
                                />
                            </SitterSection>
                        )}
                        <SitterBreadcrumbs
                            userName={userName}
                            sitterName={sitterName}
                            sitterUrl={canonical}
                            petSittingSearchPage={petSittingSearchPage}
                            sitterNameSlug={sitterNameSlug}
                            sitterId={sitterId}
                            pageDescription={pageDescription}
                        />
                    </Content>
                    <Sidebar>
                        <StickyComponent topOffset={83}>
                            <SitterInfoCard
                                sitterId={sitterId}
                                sitterName={sitterName}
                                userName={userName}
                                userImage={userImage}
                                policy={policy}
                                policeCheck={policeCheck}
                                showCancellationPolicyExtraEducation={showCancellationPolicyExtraEducation}
                                bookableRates={bookableRates}
                                repeatClientsOnlyBookableServices={repeatClientsOnlyBookableServices}
                                currency={currency}
                                service={highlightedService}
                                placeName={placeDescriptive}
                                canBeContactedOrBooked={canBeContactedOrBooked}
                                canBeContactedByPetOwner={canBeContactedByPetOwner}
                            />
                        </StickyComponent>
                    </Sidebar>
                </SitterProfileContentWrapper>
            </SitterProfilePageContainer>
            <SitterJsonContainer>
                {livesNear && (
                    <SitterJsonLD
                        userName={userName}
                        userImage={userImage}
                        sitterUrl={canonical}
                        placeName={placeDescriptive}
                        country={countryCode}
                        bookableRates={bookableRates}
                        currency={currency}
                        coordinates={livesNear}
                    />
                )}
            </SitterJsonContainer>
            {canBeContactedByPetOwner.canBeContacted && (
                <StickyFooter>
                    <Link
                        passHref
                        rel={'nofollow'}
                        href={`/booking/request?${queryparams}`}
                        as={`/sitter/${withoutWorldRegionPrefix(sitterId)}/request-booking?${queryparams}`}
                    >
                        <RequestBookingButton rel={'nofollow'}>
                            {t('sitter.contactSitter', { name: userName })}
                        </RequestBookingButton>
                    </Link>
                </StickyFooter>
            )}
            {isAuthenticated && <Footer showTopFooter={true} showBottomFooter={false} />}
        </>
    );
};

export const parsedUrlQueryToURLSearchParams = (query, sitterId) => {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(query)) {
        if (!value) continue;
        if (Array.isArray(value)) {
            value.forEach((element) => {
                searchParams.append(key, element);
            });
        } else {
            searchParams.append(key, value);
        }
    }

    //make sure sitterId is part of the queryparams so we can load profile details upon booking request
    if (query.sitterId === undefined) {
        searchParams.append('sitterId', sitterId);
    }

    return searchParams;
};

const mapStateToProps = (state) => {
    const computedData = createStructuredSelector({
        sitterName: selectSitterName,
        sitterId: selectSitterId,
        userImage: selectUserImage,
        userName: selectUserName,
        sitterNameSlug: selectSitterNameSlug,
        userId: selectUserId,
        description: selectSitterDescription,
        metaDescription: selectSitterMetaDescription,
        starRatings: selectStarRatings,
        responsePercentage: selectSitterResponsePercentage,
        responseTime: selectSitterResponseTime,
        lastActiveInMinutes: selectLastActiveInMinutes,
        currency: selectSitterCurrency,
        policy: selectSitterCancellationPolicy,
        speaksLanguages: selectSitterSpeaksLanguages,
        yearsOfExperience: selectSitterYearsOfExperience,
        skills: selectSitterSkills,
        additionalSkills: selectSitterAdditionalSkills,
        acceptedOtherAnimals: selectSitterAcceptedOtherAnimals,
        acceptedDogSizes: selectSitterAcceptedDogSizes,
        acceptedDogAges: selectSitterAcceptedDogAges,
        acceptedDogSexes: selectSitterAcceptedDogSexes,
        homeCharacteristics: selectSitterHomeCharacteristics,
        walkingAreas: selectSitterWalkingAreas,
        nearbyOffLeashDogArea: selectSitterNearbyOffLeashDogArea,
        livesNear: selectSitterLivesNear,
        residentPets: selectSitterResidentPets,
        reviewCount: selectSitterReviewCount,
        favoriteCount: selectSitterFavoriteCount,
        vibrancy: selectSitterVibrancy,
        profileImages: selectSitterProfileImages,
        placeDescriptive: selectSitterPlaceDescriptive,
        additionalOffers: selectAdditionalOffers,
        hasReviews: hasSitterReviews,
        hasResidentialPets: hasResidentialPets,
        bookableRates: selectSitterBookableRates,
        isPhoneInterviewed: selectSitterIsPhoneVerified,
        isAuthenticated: isJwtAuthenticated,
        isJwtAuthenticatedLoading: isJwtAuthenticatedLoading,
        jwtToken: jwtTokenSelect,
        isFacebookVerified: selectSitterIsFacebookVerified,
        isTextMessageVerified: selectSitterIsTextMessageVerified,
        isEmailVerified: selectSitterIsEmailVerified,
        policeCheck: selectSitterPoliceCheck,
        searchPageUrls: selectSitterSearchPageUrls,
        topCities: selectTopCities,
        alternateProfileUrls: selectAlternateProfileUrls,
        countryCode: selectCountryCode,
        canBeContactedOrBooked: selectCanBeContactedOrBooked,
        canBeContactedByPetOwner: selectCanBeContactedByPetOwner,
        repeatClientsOnlyBookableServices: selectRepeatClientsOnlyBookableServices,
        mobile: isTablet,
    })(state);

    return transformImmutableToJS(computedData);
};

export default withApplicationAwareness(
    flowRight(
        withTranslation('sitter'),
        connect(mapStateToProps, {
            registerProfileView,
            sitterCanBeContactedRequest,
        })
    )(SitterProfile)
);
