import {
    catSittingService,
    doggyDayCare,
    doggyDayCareService,
    dogSittingService,
    dogWalkingService,
    homeDogBoarding,
    homeDogBoardingService,
    homeVisitsService,
    houseSittingService,
    petSittingService,
} from './index';
import { getBookableServiceBasedUponId } from './BookableService';

export const AWAY = 'away';
export const HOME = 'home';

let all = [];

all[AWAY] = [homeDogBoardingService, doggyDayCareService];
all[HOME] = [
    dogWalkingService,
    homeVisitsService,
    catSittingService,
    dogSittingService,
    petSittingService,
    houseSittingService,
];

export const Groups = all;

export const searchableServicesWithActiveService = (service) => {
    let specificHomeVisitService = petSittingService;

    switch (service.id) {
        case catSittingService.id:
            specificHomeVisitService = catSittingService;
            break;
        case dogSittingService.id:
            specificHomeVisitService = dogSittingService;
            break;
    }

    let grouped = [];

    grouped[AWAY] = [homeDogBoardingService, doggyDayCareService];
    grouped[HOME] = [dogWalkingService, specificHomeVisitService, houseSittingService];

    return grouped;
};

export const getGroupOfService = (serviceId) => {
    return [homeDogBoarding, doggyDayCare].includes(serviceId) ? AWAY : HOME;
};

export const groupRatesBasedUponGroup = (rates) => {
    let orderedByGroup = [];

    Object.keys(rates).forEach((serviceId) => {
        const service = getBookableServiceBasedUponId(serviceId);
        const groupOfService = getGroupOfService(serviceId);

        if (orderedByGroup.hasOwnProperty(groupOfService)) {
            orderedByGroup[groupOfService].push(service);
        } else {
            orderedByGroup[groupOfService] = [service];
        }
    });

    return orderedByGroup;
};
