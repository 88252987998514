import styled from 'styled-components';
import { withTranslation } from '../../../i18n';
import { PoliceCheckBadge } from '../../../styles/icons';
import { combineWithCommasAnd } from '../../../utils';

const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${(props) => (props.label === 'hasAPoliceCheck' ? '#04227b' : '#00c38a')};
`;

const IconWrapper = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    color: #00c38a;
`;

const Vibrancy = ({ t, vibrancy, className }) => {
    let text;
    let pets = [];
    let icon;

    switch (vibrancy.label) {
        case 'hasAPoliceCheck':
            text = t('vibrancy:vibrancy.hasAPoliceCheck');
            icon = <PoliceCheckBadge />;
            break;
        case 'respondsWithinVibrancyLabel': {
            const part = t(`vibrancy:vibrancy.${vibrancy.parameters.timeRange}`);
            text = t('vibrancy:vibrancy.respondsWithinVibrancyLabel', {
                timeRange: part,
            });
            icon = (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 373 373' style={{ fill: 'currentcolor' }}>
                    <path d='M186.5 31.6c84.7 0 154.9 70.2 154.9 154.9s-70.2 154.9-154.9 154.9S31.6 271.2 31.6 186.5 101.8 31.6 186.5 31.6m0-20.6C89.5 11 11 89.5 11 186.5S89.5 362 186.5 362 362 283.5 362 186.5 283.5 11 186.5 11z' />
                    <path d='M275.3 186.5h-80.5V87.4c0-8.3-6.2-14.5-14.5-14.5s-14.5 6.2-14.5 14.5V201c0 8.3 6.2 14.5 14.5 14.5h95c8.3 0 14.5-6.2 14.5-14.5-.1-8.3-6.3-14.5-14.5-14.5z' />
                </svg>
            );
            break;
        }

        case 'livesWithPetsVibrancyLabel':
            Object.keys(vibrancy.parameters.pets).forEach((value) => {
                const amount = vibrancy.parameters.pets[value];
                pets.push(
                    t('vibrancy:vibrancy.' + value, {
                        amount: amount,
                        count: amount,
                    })
                );
            });
            pets = combineWithCommasAnd(pets, ', ', ' ' + t('common:and') + ' ');
            text = t('vibrancy:vibrancy.livesWithPetsVibrancyLabel', {
                pets: pets,
            });
            icon = (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 373 373' style={{ fill: 'currentcolor' }}>
                    <path d='M336.2 336.2H33c-9.7 0-17.6-7.9-17.6-17.6 0-44.2 20.9-86.4 57.5-115.6 3.1-2.5 7-3.9 11-3.9h.6l-4.9-4.9c-6.3 2.8-13.2 4.3-20.2 4.3-13 .1-25.7-5.2-34.9-14.5-9.3-9.2-14.6-21.9-14.5-35 0-13.2 5.1-25.6 14.5-34.9 7-7 15.9-11.7 25.6-13.6C51.9 90.9 56.6 82 63.6 75c9.1-9.2 21.8-14.5 34.8-14.5h.1c13.2 0 25.6 5.1 34.9 14.5 14.6 14.6 18.4 36.5 10.2 55l26.5 26.5 41.4-41.4c-3.8-7.6-5.7-15.9-5.7-24.4 0-13.2 4.7-25.8 13.2-35.7v-.2l2.9-2.9c10.2-10.3 24.3-16.1 38.8-16.1h.2c14.7 0 28.5 5.7 38.9 16.1 8.4 8.4 13.8 19.1 15.5 30.7 11.6 1.7 22.3 7.1 30.7 15.5 21.4 21.4 21.4 56.3 0 77.8-10.2 10.2-24.3 16.1-38.8 16.1h-.4c-8.4 0-16.7-1.9-24.2-5.7L269.9 199h15.5c4 0 7.9 1.4 11 3.9 36.5 29.3 57.5 71.4 57.5 115.6 0 9.8-7.9 17.7-17.7 17.7zM35.3 316.3H334c-.7-37.1-18.6-72.4-49.4-97.4h-200c-30.7 25-48.6 60.3-49.3 97.4zm120.5-117.2h85.9l37.9-37.9 8.6 5.4a34.6 34.6 0 0 0 18.7 5.5h.2c9.3 0 18.2-3.7 24.7-10.3 13.7-13.7 13.7-36 0-49.7-6.3-6.3-14.7-10-23.7-10.3l-11.9-.4-.3-11.9c-.3-8.9-3.9-17.3-10.3-23.6-6.6-6.6-15.5-10.3-24.8-10.3h-.1c-9 0-17.7 3.5-24.1 9.7l-.6.7c-6.6 6.6-10.3 15.5-10.3 24.8 0 6.7 1.9 13.2 5.5 18.8l5.5 8.5-80.9 81zm-43.3 0h15.1l28.4-28.4-37.2-37.2 5-8.5c6.9-11.6 5-26.3-4.5-35.9-5.6-5.6-13-8.6-20.8-8.6h-.1c-7.7 0-15.3 3.1-20.7 8.6-5.1 5.1-8.1 11.8-8.6 18.9l-.8 11-11 .8c-7.2.4-13.8 3.5-18.8 8.5-5.6 5.6-8.7 13-8.7 20.9 0 7.8 3.1 15.3 8.6 20.8s13 8.6 20.7 8.6h.2c5.3 0 10.5-1.4 15-4.1l8.5-5 29.7 29.6z' />
                </svg>
            );
            break;

        case 'livesWithBreedAndPetNameVibrancyLabel':
            text = t('vibrancy:vibrancy.' + vibrancy.label, vibrancy.parameters);
            icon = (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 373 373' style={{ fill: 'currentcolor' }}>
                    <path d='M336.2 336.2H33c-9.7 0-17.6-7.9-17.6-17.6 0-44.2 20.9-86.4 57.5-115.6 3.1-2.5 7-3.9 11-3.9h.6l-4.9-4.9c-6.3 2.8-13.2 4.3-20.2 4.3-13 .1-25.7-5.2-34.9-14.5-9.3-9.2-14.6-21.9-14.5-35 0-13.2 5.1-25.6 14.5-34.9 7-7 15.9-11.7 25.6-13.6C51.9 90.9 56.6 82 63.6 75c9.1-9.2 21.8-14.5 34.8-14.5h.1c13.2 0 25.6 5.1 34.9 14.5 14.6 14.6 18.4 36.5 10.2 55l26.5 26.5 41.4-41.4c-3.8-7.6-5.7-15.9-5.7-24.4 0-13.2 4.7-25.8 13.2-35.7v-.2l2.9-2.9c10.2-10.3 24.3-16.1 38.8-16.1h.2c14.7 0 28.5 5.7 38.9 16.1 8.4 8.4 13.8 19.1 15.5 30.7 11.6 1.7 22.3 7.1 30.7 15.5 21.4 21.4 21.4 56.3 0 77.8-10.2 10.2-24.3 16.1-38.8 16.1h-.4c-8.4 0-16.7-1.9-24.2-5.7L269.9 199h15.5c4 0 7.9 1.4 11 3.9 36.5 29.3 57.5 71.4 57.5 115.6 0 9.8-7.9 17.7-17.7 17.7zM35.3 316.3H334c-.7-37.1-18.6-72.4-49.4-97.4h-200c-30.7 25-48.6 60.3-49.3 97.4zm120.5-117.2h85.9l37.9-37.9 8.6 5.4a34.6 34.6 0 0 0 18.7 5.5h.2c9.3 0 18.2-3.7 24.7-10.3 13.7-13.7 13.7-36 0-49.7-6.3-6.3-14.7-10-23.7-10.3l-11.9-.4-.3-11.9c-.3-8.9-3.9-17.3-10.3-23.6-6.6-6.6-15.5-10.3-24.8-10.3h-.1c-9 0-17.7 3.5-24.1 9.7l-.6.7c-6.6 6.6-10.3 15.5-10.3 24.8 0 6.7 1.9 13.2 5.5 18.8l5.5 8.5-80.9 81zm-43.3 0h15.1l28.4-28.4-37.2-37.2 5-8.5c6.9-11.6 5-26.3-4.5-35.9-5.6-5.6-13-8.6-20.8-8.6h-.1c-7.7 0-15.3 3.1-20.7 8.6-5.1 5.1-8.1 11.8-8.6 18.9l-.8 11-11 .8c-7.2.4-13.8 3.5-18.8 8.5-5.6 5.6-8.7 13-8.7 20.9 0 7.8 3.1 15.3 8.6 20.8s13 8.6 20.7 8.6h.2c5.3 0 10.5-1.4 15-4.1l8.5-5 29.7 29.6z' />
                </svg>
            );
            break;

        case 'recurringGuestsVibrancyLabel':
            vibrancy.parameters.count = vibrancy.parameters.guests;
            text = t(`vibrancy:vibrancy.${vibrancy.label}`, vibrancy.parameters);
            icon = (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 373 373' style={{ fill: 'currentcolor' }}>
                    <path d='M186.5 327.3c-14.2 0-28.4-2.1-42.2-6.5-59.1-18.4-98.3-73.4-98.7-133.6l-20.7 18.6c-4.1 3.7-10.4 3.4-14.1-.7-3.7-4.1-3.4-10.4.7-14.1l38.9-35c3.9-3.5 9.8-3.4 13.6.2l36.4 35c4 3.8 4.1 10.2.3 14.1-3.8 4-10.2 4.1-14.1.3l-21-20.1c-.4 52.2 33.4 100.2 84.7 116.3 57.6 18 120.2-9.5 145.6-64.1 2.3-5 8.3-7.2 13.3-4.8 5 2.3 7.2 8.3 4.8 13.3-14.3 30.7-39.3 55.2-70.3 69-18.1 8.1-37.6 12.1-57.2 12.1zm129.6-108.7c-2.5 0-5-.9-6.9-2.8l-36.4-35c-4-3.8-4.1-10.2-.3-14.1 3.8-4 10.2-4.1 14.1-.3l20.7 19.9c0-22.1-6.2-44-18-63.2-15.2-24.8-38.9-43.2-66.7-51.9-57.5-18-120.1 9.5-145.6 64-2.3 5-8.3 7.2-13.3 4.8-5-2.3-7.2-8.3-4.8-13.3 14.3-30.6 39.3-55.1 70.3-68.9 31.3-13.9 66.6-16 99.3-5.7 32.4 10.2 60 31.6 77.8 60.5 13.5 21.9 20.7 47 20.9 72.4l21-18.9c4.1-3.7 10.4-3.4 14.1.7 3.7 4.1 3.4 10.4-.7 14.1l-38.9 35c-1.8 1.8-4.2 2.7-6.6 2.7z' />
                </svg>
            );
            break;

        case 'lastContactedVibrancyLabel':
        case 'lastBookedVibrancyLabel':
        case 'lastActiveVibrancyLabel':
            if (vibrancy.parameters.days === 0) {
                text = t(`vibrancy:vibrancy.${vibrancy.label}Today`);
            } else if (vibrancy.parameters.days === 1) {
                text = t(`vibrancy:vibrancy.${vibrancy.label}Yesterday`);
            } else {
                vibrancy.parameters.count = vibrancy.parameters.days;
                text = t(`vibrancy:vibrancy.${vibrancy.label}`, vibrancy.parameters);
            }
            icon = (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 373 373' style={{ fill: 'currentcolor' }}>
                    <path d='M186.5 31.6c84.7 0 154.9 70.2 154.9 154.9s-70.2 154.9-154.9 154.9S31.6 271.2 31.6 186.5 101.8 31.6 186.5 31.6m0-20.6C89.5 11 11 89.5 11 186.5S89.5 362 186.5 362 362 283.5 362 186.5 283.5 11 186.5 11z' />
                    <path d='M275.3 186.5h-80.5V87.4c0-8.3-6.2-14.5-14.5-14.5s-14.5 6.2-14.5 14.5V201c0 8.3 6.2 14.5 14.5 14.5h95c8.3 0 14.5-6.2 14.5-14.5-.1-8.3-6.3-14.5-14.5-14.5z' />
                </svg>
            );
            break;

        case 'pendingBookingsVibrancyLabel':
            vibrancy.parameters.count = vibrancy.parameters.bookings;
            text = t(`vibrancy:vibrancy.${vibrancy.label}`, vibrancy.parameters);
            icon = (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56' style={{ fill: 'currentcolor' }}>
                    <path d='M50 30h-8v8h8v-8zm-2 6h-4v-4h4v4zM47 6V0h-2v6H11V0H9v6H0v50h56V6h-9zm7 48H2V14h52v40zm0-42H2V8h7v2h2V8h34v2h2V8h7v4zm-4 6h-8v8h8v-8zm-2 6h-4v-4h4v4zm-10-6h-8v8h8v-8zm-2 6h-4v-4h4v4zm-10-6h-8v8h8v-8zm-2 6h-4v-4h4v4zm2 18h-8v8h8v-8zm-2 6h-4v-4h4v4zM14 30H6v8h8v-8zm-2 6H8v-4h4v4zm2 6H6v8h8v-8zm-2 6H8v-4h4v4zm14-18h-8v8h8v-8zm-2 6h-4v-4h4v4zm14-6h-8v8h8v-8zm-2 6h-4v-4h4v4zm2 6h-8v8h8v-8zm-2 6h-4v-4h4v4z' />
                </svg>
            );
            break;

        default:
            text = t(`vibrancy:vibrancy.${vibrancy.label}`, vibrancy.parameters);
            break;
    }

    return (
        <Flex label={vibrancy.label} className={className}>
            <IconWrapper>{icon}</IconWrapper>
            {text}
        </Flex>
    );
};

export default withTranslation('vibrancy')(Vibrancy);
