import { catSitting, dogSitting, homeVisits, petSitting } from './index';

export const determineServiceRate = (service, rates) => {
    if ([petSitting, dogSitting, catSitting].includes(service.id)) {
        return rates[homeVisits];
    } else {
        return rates[service.id];
    }
};

export const determineBasePriceOfServiceRate = (service, rates) => {
    if (rates[service.id].hasOwnProperty('basePrice')) {
        return rates[service.id].basePrice;
    }

    return 0;
};

export const determineSecondDailyVisitPriceOfServiceRate = (service, rates) => {
    if (service.id !== homeVisits) {
        return false;
    }

    if (rates[service.id].hasOwnProperty('secondDailyVisit')) {
        return rates[service.id].secondDailyVisit;
    }

    return false;
};

export const determineAdditionalPetPriceOfServiceRate = (service, rates) => {
    if (rates[service.id].hasOwnProperty('additionalPet')) {
        return rates[service.id].additionalPet;
    }

    return false;
};
