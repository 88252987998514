import React from 'react';
import styled from 'styled-components';
import SpeakLanguages from './Skills/Specific/SpeakLanguages';
import YearsOfExperience from './Skills/Specific/YearsOfExperience';
import DogTraining from './Skills/Specific/DogTraining';
import {
    CAN_ADMINISTER_FIRST_AID_AND_PET_CPR,
    CAN_ADMINISTER_INJECTED_MEDICATIONS,
    CAN_ADMINISTER_ORAL_MEDICATIONS,
    CAN_TAKE_CARE_OF_EXOTIC_ANIMALS,
    HAS_EXPERIENCE_AS_ANIMAL_WELFARE_VOLUNTEER,
    HAS_EXPERIENCE_WITH_BEHAVIOURAL_PROBLEMS,
    HAS_EXPERIENCE_WITH_RESCUING_PETS,
    IS_FAMILIAR_WITH_DOG_TRAINING_TECHNIQUES,
} from '../../Pawshake/Sitter/Skills/Skill';
import ExoticAnimals from './Skills/Specific/ExoticAnimals';
import InjectedMedications from './Skills/Administer/InjectedMedications';
import FirstAidAndCPR from './Skills/Administer/FirstAidAndCPR';
import OralMedications from './Skills/Administer/OralMedications';
import BehaviouralProblems from './Skills/Experiences/BehaviouralProblems';
import RescuingPets from './Skills/Experiences/RescuingPets';
import AnimalWelfare from './Skills/Experiences/AnimalWelfare';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from '../../i18n';

const Group = styled.div`
    margin-bottom: 1rem;
`;

const Header = styled.h3`
    margin-bottom: 1rem;
    color: #5c5c5c;
`;

const Skills = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const AdditionalSkills = styled.div`
    font-size: 1rem !important;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
    span {
        font-size: 1rem !important;
        font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
    }
`;

const SitterSkills = ({ t, yearsOfExperience, speaksLanguages, skills, additionalSkills }) => {
    return (
        <>
            <Group>
                <Header>{t('sitter.skills.specificSkills.title')}</Header>
                <Skills>
                    {speaksLanguages.length > 0 && <SpeakLanguages languages={speaksLanguages} />}
                    {yearsOfExperience && <YearsOfExperience years={yearsOfExperience} />}
                    {skills.includes(IS_FAMILIAR_WITH_DOG_TRAINING_TECHNIQUES) && <DogTraining />}
                    {skills.includes(CAN_TAKE_CARE_OF_EXOTIC_ANIMALS) && <ExoticAnimals />}
                </Skills>
                {additionalSkills && <AdditionalSkills>{ReactHtmlParser(additionalSkills)}</AdditionalSkills>}
            </Group>
            {[
                CAN_ADMINISTER_ORAL_MEDICATIONS,
                CAN_ADMINISTER_FIRST_AID_AND_PET_CPR,
                CAN_ADMINISTER_INJECTED_MEDICATIONS,
            ].some((r) => skills.includes(r)) && (
                <Group>
                    <Header>{t('sitter.skills.administer.title')}</Header>
                    <Skills>
                        {skills.includes(CAN_ADMINISTER_ORAL_MEDICATIONS) && <OralMedications />}
                        {skills.includes(CAN_ADMINISTER_FIRST_AID_AND_PET_CPR) && <FirstAidAndCPR />}
                        {skills.includes(CAN_ADMINISTER_INJECTED_MEDICATIONS) && <InjectedMedications />}
                    </Skills>
                </Group>
            )}
            {[
                HAS_EXPERIENCE_AS_ANIMAL_WELFARE_VOLUNTEER,
                HAS_EXPERIENCE_WITH_RESCUING_PETS,
                HAS_EXPERIENCE_WITH_BEHAVIOURAL_PROBLEMS,
            ].some((r) => skills.includes(r)) && (
                <Group>
                    <Header>{t('sitter.skills.experience.title')}</Header>
                    <Skills>
                        {skills.includes(HAS_EXPERIENCE_AS_ANIMAL_WELFARE_VOLUNTEER) && <AnimalWelfare />}
                        {skills.includes(HAS_EXPERIENCE_WITH_RESCUING_PETS) && <RescuingPets />}
                        {skills.includes(HAS_EXPERIENCE_WITH_BEHAVIOURAL_PROBLEMS) && <BehaviouralProblems />}
                    </Skills>
                </Group>
            )}
        </>
    );
};

export default withTranslation('sitter')(SitterSkills);
