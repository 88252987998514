import React from 'react';
import SVGInline from 'react-svg-inline';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Pet = styled.div`
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 30%;
    ${breakpoint('lg')`
    width: 20%;
  `}
`;

const Icon = styled(SVGInline)`
    margin: auto 1rem auto 0;
    svg {
        width: 21px;
        height: 21px;

        path {
            stroke: #00c38a;
        }
    }
`;

const Label = styled.div`
    margin-bottom: 0.5rem;
`;

const Description = styled.div`
    font-size: 0.9rem;
`;

const AcceptedPet = ({ label, icon, description, className }) => {
    return (
        <Pet className={className}>
            <Icon svg={icon} />
            <Label>{label}</Label>
            <Description>{description}</Description>
        </Pet>
    );
};

export default AcceptedPet;
