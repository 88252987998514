import styled from 'styled-components';
import { withTranslation } from '../../../i18n';
import IconWithLabel from '../../Shared/IconWithLabel/IconWithLabel';

const RedIcon = styled(IconWithLabel)`
    margin: 0;
    span {
        color: #dc5858;
    }
    svg {
        path {
            fill: #dc5858;
        }
    }
`;

const Favorited = ({ t, count, className }) => {
    return (
        <RedIcon
            className={className}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 469.2"><path d="M255 469.2l-35.7-35.7C86.7 316.2 0 237.1 0 140.2 0 61.2 61.2 0 140.2 0c43.4 0 86.7 20.4 114.8 53.5C283 20.4 326.4 0 369.8 0 448.8 0 510 61.2 510 140.2c0 96.9-86.7 175.9-219.3 293.2L255 469.2z"/></svg>'
            }
            inline={true}
            label={t('sitter.timesFavorited', { count })}
        />
    );
};

export default withTranslation('sitter')(Favorited);
