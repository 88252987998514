import { withTranslation } from '../../../i18n';
import { CheckmarkIcon } from '../../../styles/icons';
import Statistic from '../../Shared/Statistic/Statistic';

const PhoneInterviewed = ({ t }) => {
    return (
        <Statistic
            value={t('sitter.statistics.phoneInterviewed')}
            label={t('sitter.statistics.verified')}
            icon={<CheckmarkIcon />}
        />
    );
};

export default withTranslation('sitter')(PhoneInterviewed);
