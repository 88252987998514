import { ChevronRightIcon } from '../../../styles/icons';

const Separator = () => {
    return (
        <li style={{ paddingTop: '.25rem' }}>
            <ChevronRightIcon style={{ width: '1rem', height: '1rem' }} />
        </li>
    );
};

export default Separator;
