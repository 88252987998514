import React from 'react';
import styled from 'styled-components';
import { dogAges, PUPPY } from '../../Pawshake/Sitter/AcceptedPets/DogAge';
import Puppies from './Home/AcceptedPets/Puppies';
import DogSizes from './Home/AcceptedPets/DogSizes';
import DogAges from './Home/AcceptedPets/DogAges';
import DogSexes from './Home/AcceptedPets/DogSexes';
import { CATS, FERRETS, SMALL_ANIMALS } from '../../Pawshake/Sitter/AcceptedPets/Pets';
import Cats from './Home/AcceptedPets/Cats';
import SmallAnimals from './Home/AcceptedPets/SmallAnimals';
import Ferrets from './Home/AcceptedPets/Ferrets';
import { APARTMENT, FARM } from '../../Pawshake/Sitter/Home/Type';
import IsAFarm from './Home/IsAFarm';
import { HOME } from '../../Pawshake/Core/Service/Groups';
import IsAHome from './Home/isAHome';
import IsAnApartment from './Home/IsAnApartment';
import { LARGE, MEDIUM, SMALL } from '../../Pawshake/Sitter/Home/OutdoorArea';
import OutdoorArea from './Home/OutdoorArea';
import { NONE, OLDER_THAN_TEN, YOUNGER_THAN_TEN, YOUNGER_THAN_THREE } from '../../Pawshake/Sitter/Home/Kids';
import Kids from './Home/Kids';
import { EMERGENCY_TRANSPORT, FULLY_FENCED_BACKYARD, SUPERVISION } from '../../Pawshake/Sitter/Home/Features';
import Has247Supervision from './Home/Has247Supervision';
import HasFullyFencedBackyard from './Home/HasFullyFencedBackyard';
import HasEmergencyTransport from './Home/HasEmergencyTransport';
import NearbyOffLeashDogArea from './Home/NearbyOffLeashDogArea';
import WalkingAreas from './Home/WalkingAreas';
import { dogSizes } from '../../Pawshake/Sitter/AcceptedPets/DogSize';
import { withTranslation } from '../../i18n';

const Group = styled.div`
    margin-bottom: 1rem;
`;

const Header = styled.h3`
    margin-bottom: 1rem;
    color: #5c5c5c;
`;

const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const calculateDogSizes = (sizes) => {
    return sizes.reduce(
        (result, size) => {
            return {
                min: Math.min(dogSizes[size].min, result.min),
                max: Math.max(dogSizes[size].max, result.max),
            };
        },
        { min: Number.MAX_VALUE, max: Number.MIN_VALUE }
    );
};

const calculateDogAges = (ages) => {
    return ages.reduce(
        (result, age) => {
            return {
                min: Math.min(dogAges[age].min, result.min),
                max: Math.max(dogAges[age].max, result.max),
            };
        },
        { min: Number.MAX_VALUE, max: Number.MIN_VALUE }
    );
};

const SitterHome = (props) => {
    const {
        t,
        name,
        dogAges,
        dogSizes,
        dogSexes,
        acceptedOtherAnimals,
        characteristics,
        nearbyOffLeashDogArea,
        walkingAreas,
    } = props;
    let ages,
        sizes = null;

    if (dogSizes.length > 0) {
        sizes = calculateDogSizes(dogSizes);
    }

    if (dogAges.length > 0) {
        ages = calculateDogAges(dogAges);
    }

    const showWalkingAreasGroup = walkingAreas.length > 0 && nearbyOffLeashDogArea;

    const showAcceptedPets = dogAges || ages || sizes || dogSexes.length > 0 || acceptedOtherAnimals.length > 0;

    return (
        <>
            {showAcceptedPets && (
                <Group>
                    <Header>{t('sitter.aboutHome.acceptedPets.title')}</Header>
                    <Items>
                        {dogAges && dogAges.includes(PUPPY) && <Puppies />}
                        {sizes && <DogSizes min={sizes.min} max={sizes.max} />}
                        {ages && <DogAges min={ages.min} max={ages.max} />}
                        {dogSexes.length > 0 && <DogSexes sexes={dogSexes} />}
                        {acceptedOtherAnimals.length > 0 && (
                            <>
                                {acceptedOtherAnimals.includes(CATS) && <Cats />}
                                {acceptedOtherAnimals.includes(FERRETS) && <Ferrets />}
                                {acceptedOtherAnimals.includes(SMALL_ANIMALS) && <SmallAnimals />}
                            </>
                        )}
                    </Items>
                </Group>
            )}
            <Group>
                <Header>{t('sitter.aboutHome.home.title', { name: name })}</Header>
                <Items>
                    {characteristics.includes(FARM) && <IsAFarm />}
                    {characteristics.includes(APARTMENT) && <IsAnApartment />}
                    {characteristics.includes(HOME) && <IsAHome />}
                    {characteristics.includes(SMALL) && <OutdoorArea outdoorArea={SMALL} />}
                    {characteristics.includes(MEDIUM) && <OutdoorArea outdoorArea={MEDIUM} />}
                    {characteristics.includes(LARGE) && <OutdoorArea outdoorArea={LARGE} />}
                    {characteristics.includes(NONE) && <Kids kids={NONE} />}
                    {characteristics.includes(YOUNGER_THAN_THREE) && <Kids kids={YOUNGER_THAN_THREE} />}
                    {characteristics.includes(YOUNGER_THAN_TEN) && <Kids kids={YOUNGER_THAN_TEN} />}
                    {characteristics.includes(OLDER_THAN_TEN) && <Kids kids={OLDER_THAN_TEN} />}
                    {characteristics.includes(SUPERVISION) && <Has247Supervision />}
                    {characteristics.includes(FULLY_FENCED_BACKYARD) && <HasFullyFencedBackyard />}
                    {characteristics.includes(EMERGENCY_TRANSPORT) && <HasEmergencyTransport />}
                </Items>
            </Group>
            {showWalkingAreasGroup && (
                <Group>
                    <Header>{t('sitter.aboutHome.walkingAreas.title')}</Header>
                    <Items>
                        {nearbyOffLeashDogArea && <NearbyOffLeashDogArea />}
                        {walkingAreas.length > 0 && <WalkingAreas areas={walkingAreas} />}
                    </Items>
                </Group>
            )}
        </>
    );
};

export default withTranslation('sitter')(SitterHome);
