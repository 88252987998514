import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazy-load';

const Map = styled.div`
    position: relative;
`;

const CircleComponent = styled.div`
    border-radius: 100%;
    width: 20%;
    height: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
`;

const Circle = styled(CircleComponent)`
    background-color: #00afed;
    opacity: 0.25;
    margin: -10%;
`;

const CircleBorder = styled(CircleComponent)`
    margin: calc(-10% - 3px);
    border: 3px solid #00afed;
`;

const MapImage = styled.img`
    width: 100%;
    border-radius: 0.5rem;
`;

const StaticMap = ({ latitude, longitude }) => {
    const center = latitude + ',' + longitude;
    const zoom = 12;
    const width = 600;
    const height = 300;
    return (
        <LazyLoad threshold={200}>
            <Map>
                <Circle />
                <CircleBorder />
                <MapImage
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=${zoom}&scale=1&size=${width}x${height}&maptype=roadmap&format=png&visual_refresh=true&key=${
                        __CONFIG__.googleMapsApiKey
                    }`}
                />
            </Map>
        </LazyLoad>
    );
};

export default StaticMap;
