import { convertLocaleToNumberFormatLocale } from './format.utils';

/**  Make sure every number is formatted according to the locale it's displayed in */
export const formatNumber = (number: number, locale: string) => {
    const formatter = new Intl.NumberFormat(locale);
    return formatter.format(number);
};

/**  Make sure every percentage number is formatted according to the locale it's displayed in */
export const formatPercentage = (number: number, locale: string) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'percent',
    });
    return formatter.format(number / 100);
};

/**  Make sure every currency number is formatted according to the locale it's displayed in */
export const formatCurrency = (number: number, currency: string, locale: string, fractionDigits = 0) => {
    const mappedLocale = convertLocaleToNumberFormatLocale(locale);
    const formatter = new Intl.NumberFormat(mappedLocale, {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: currency,
        minimumFractionDigits: fractionDigits,
    });

    return formatter.format(number);
};
