import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { withTranslation } from '../../i18n';

const Title = styled.h3`
    margin-bottom: 1rem;
`;

const Info = styled.div`
    color: #acacac;
    font-size: 0.9rem;
`;

const ExtraEducation = styled.div`
    margin-top: 1rem;
    color: #acacac;
    font-size: 0.9rem;
`;

const CancellationPolicy = ({ t, cancellationPolicy, className, showExtraEducation }) => {
    return (
        <div className={className}>
            <Title>{`${t('cancellationPolicy.title')}: ${t(`cancellationPolicy.${cancellationPolicy}.title`)}`}</Title>
            <Info>{ReactHtmlParser(t(`cancellationPolicy.${cancellationPolicy}.info`))}</Info>
            {showExtraEducation && (
                <ExtraEducation>{ReactHtmlParser(t('cancellationPolicy.extraEducation'))}</ExtraEducation>
            )}
        </div>
    );
};

export default withTranslation('finance')(CancellationPolicy);
