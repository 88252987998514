import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useRouter } from 'next/router';
import { Box } from './Styles';
import { withTranslation } from '../../i18n';
import { trackAnonymousClicks } from './Analytics';

const WithAriaLabelBox = styled(Box)`
    ${breakpoint('md')`
  &:hover {
    &:before {
      border-bottom-color: #1b1f23;

      border-width: 6px;
      bottom: -7px;
      margin-right: -6px;
      right: 50%;
      top: auto;

      color: #1b1f23;
      content: " ";
      height: 0;
      pointer-events: none;
      position: absolute;
      width: 0;
      z-index: 1000001;
    }

    &:after {
      right: auto;
      transform: translateX(-50%);
      display: table-cell;

      border-collapse: separate;
      max-width: 250px;
      white-space: pre-line;
      width: max-content;
      word-wrap: break-word;
      font-size: 0.8rem;

      margin-top: 6px;

      -webkit-font-smoothing: subpixel-antialiased;
      background: #1b1f23;
      border-radius: 3px;
      color: #fff;
      content: attr(aria-label);

      letter-spacing: normal;
      padding: 0.5em 0.75em;
      pointer-events: none;
      position: absolute;
      text-align: center;
      z-index: 1000000;
    }
  }
  `};
`;

const NeedToLoginBeforeFavorite = ({ t, className, sitterName }) => {
    const router = useRouter();
    const loginLink = `/user/login?destination=${router.asPath}`;
    const description = t('loginToFavoriteSitter', { sitterName: sitterName });

    return (
        <WithAriaLabelBox
            aria-label={description}
            className={className}
            onClick={() => {
                trackAnonymousClicks();
                window.location = loginLink;
            }}
        >
            <img
                width={32}
                height={32}
                alt={description}
                src={'https://static1.pawshakecdn.com/global/redHollowHeart.svg'}
            />
        </WithAriaLabelBox>
    );
};

export default withTranslation('common')(NeedToLoginBeforeFavorite);
