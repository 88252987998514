import React from 'react';
import { SkillIconWithLabel } from '../styles/SkillIconWithLabel';
import { withTranslation } from '../../../../i18n';

const DogTraining = ({ t }) => {
    return (
        <SkillIconWithLabel
            label={t('sitter.skills.specificSkills.dogTrainingTechniques')}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M40.3 23.9l-.7-4.2c-.1-.6-.7-1-1.3-.9h-.1l-2.1.5-3.6.9c-.7-1.4-1.8-2.6-2.9-3.5l-.9-.6c1.8-.2 3.6-1 5-2.2 1.6-1.5 2.6-3.5 2.9-5.8.1-.6-.4-1.2-1-1.3h-.1l-5.4-.2c-.8-1.3-2.2-2.1-3.7-2.1H26l-1.3-2.9c-.3-.6-.9-.8-1.5-.6-.3.1-.5.3-.6.6l-.7 1.8L20.2 1c-.4-.5-1.1-.6-1.6-.3-.3.2-.5.6-.5.9V47c-4.3-.2-6.2-2-6.2-6.1 0-.6-.5-1.1-1.1-1.2s-1.1.5-1.2 1.1v.1c0 5.5 3.1 8.3 9.2 8.3h15.4c.6 0 1.1-.5 1.1-1.1 0-3.2-2.6-4.5-4.8-5 2.1-5.3 3.3-9.9 3.5-13.9.1-1.7.1-3.4-.2-4.8l2.6-1.1.8 1.7c.3.6.9.8 1.5.6l.8-.4c.6-.2.9-.7.8-1.3z" fill="none" stroke="#000" stroke-width="2"/></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(DogTraining);
