/**
 * Convert a locale string to a different locale that results in the same output for Safari and Chrome.
 * We received differentresults when parsing `en-BE` in Safari (€ 170,00) vs Chrome (€170.00). This caused
 * Hydration errors because the server would parse the number via node but once hydrated, Safari would parse it.
 */
export const convertLocaleToNumberFormatLocale = (locale: string) => {
    switch (locale) {
        case 'nl-BE':
        case 'fr-BE':
        case 'de-DE':
        case 'fr-FR':
        case 'nl-NL':
        case 'en-CA':
        case 'fr-CA':
        case 'en-GB':
        case 'en-AU':
        case 'da-DK':
        case 'fi-FI':
        case 'en-IE':
        case 'it-IT':
        case 'no-NO':
        case 'sv-SE':
        case 'en-NZ':
            return locale;

        case 'de-CH':
        case 'de-BE':
        case 'de-LU':
        case 'de-AT':
            return 'de-DE';

        case 'en-BE':
        case 'en-DE':
        case 'en-FR':
        case 'en-LU':
        case 'en-NL':
        case 'en-HK':
        case 'en-SG':
        case 'en-JP':
            return 'en-GB';

        case 'fr-CH':
        case 'fr-LU':
            return 'fr-FR';

        case 'it-CH':
            return 'it-IT';

        default:
            return 'en-GB';
    }
};

export const getCurrencySymbol = (currency: string, locale: string) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    });

    let symbol;
    formatter.formatToParts(0).forEach(({ type, value }) => {
        if (type === 'currency') {
            symbol = value;
        }
    });

    return symbol;
};

// Format a rate to a decimal number. numbers from the api are send without decimal point
export const formatRate = (amount: number | undefined | null) => {
    if (!amount) {
        return 0;
    }

    return amount / 100;
};
