import React from 'react';
import { withRouter } from 'next/router';
import { getServiceBasedUponServiceSlug, getServiceSlugInLocale } from '../../Search/ServiceSlugs';
import SearchPagesInLocale from './SearchPagesInLocale';
import SubTitle from '../../../Infrastructure/Shared/Titles/SubTitle';
import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import { getTopCitySlugBasedUponServiceInLanguage } from '../../TopCities/TopCitiesSlugs';
import { getDefaultIndexableSearchableService } from '../../../Pawshake/Core/Domain';
import { withTranslation } from '../../../i18n';

const FeaturedSearchPages = (props) => {
    const { t, router, title, application } = props;
    const { query } = router;
    const { serviceSlug } = query;
    const { locale, country, domain } = application;

    let passedDownService = getDefaultIndexableSearchableService(domain);
    let passedDownServiceSlug = getServiceSlugInLocale(passedDownService, locale);
    let topCitiesSlug = getTopCitySlugBasedUponServiceInLanguage(passedDownService, locale.language);

    if (serviceSlug) {
        passedDownService = getServiceBasedUponServiceSlug(serviceSlug);
        passedDownServiceSlug = getServiceSlugInLocale(passedDownService, locale);
        topCitiesSlug = getTopCitySlugBasedUponServiceInLanguage(passedDownService, locale.language);
    }

    const translatedCountry = t(`geo:geo.countries.${country}`);

    return (
        <>
            <SubTitle>{title}</SubTitle>
            <SearchPagesInLocale serviceSlug={passedDownServiceSlug} service={passedDownService} />
            <a
                href={`/${topCitiesSlug}`}
                title={t(`footer.categories.popularCities.topCitiesTitles.${passedDownService.id}`, {
                    country: translatedCountry,
                })}
            >
                {t(`footer.categories.popularCities.topCities.${passedDownService.id}`)}
            </a>
        </>
    );
};

export default withRouter(withApplicationAwareness(withTranslation('footer')(FeaturedSearchPages)));
