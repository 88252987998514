import React from 'react';
import addDays from 'date-fns/addDays';
import breakpoint from 'styled-components-breakpoint';
import styled, { createGlobalStyle } from 'styled-components';
import { getFirstDayOfTheWeekOfLocale } from '../../Pawshake/Core/Locale/FirstDayOfTheWeek';
import DayPicker from 'react-day-picker';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { DateRangePickerGlobalStyle } from './Styles/DatepickerStyling';
import DatePickerNavigationHeader from './DatePickerNavigationHeader';
import DatePickerLegend from './DatePickerLegend';

const GlobalStyle = createGlobalStyle`
  .BookableServiceAvailability {
    .DayPicker-Day {
      padding: 0.5rem;
      position: relative;
      
      ${breakpoint('md')`
        padding: 2rem
      `};
    }
    
    .DayPicker-Month {
      width: 100%;
      margin: 1rem 0 0;
      
      ${breakpoint('md')`
        margin-top: 0;
      `};
    }
    
    .DayPicker-Weekday {
      color: #4f4f4f;
      font-size: 1rem;
      font-weight: bold;
      border-bottom: 1px solid #EFEFEF;
    }
    
    .DayPicker-Caption {
      text-align: left;
      margin-bottom: 1rem;
      ${breakpoint('md')`
        margin-bottom: 2rem
      `};
      padding: 0;
      color: #4f4f4f; 
    }
    
    .DayPicker-TodayButton {
      position:absolute;
      right: 5rem;
      margin-bottom: 1rem;
      border: 1px solid #4A90E2;
      border-radius: 5px;
      top: 0;
    }
    
    .DayPicker-Body,
    .DayPicker-Week,
    .DayPicker-Day {
      border: 1px solid #EFEFEF;
      border-radius: 0 !important;
    }
    
    .DayPicker-Weekdays {
      border: 1px solid #EFEFEF;
    }
  
    .DayPicker-Day:not(.DayPicker-Day--unavailable):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: #e0f1d8;
      color: #00c38a;
      border-radius: 0;
    }
    
    .DayPicker-Day--unavailable:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: #ffeef0;  
      border-radius: 0;
      color: #f7718d;
    }
  }
`;

const StyledDatePickerNavigationHeader = styled(DatePickerNavigationHeader)`
    top: 0;
`;

const loadIntl = (language) => {
    let intl;
    try {
        intl = require('./languages/' + language + '.js');
    } catch (ex) {
        intl = require('./languages/en.js');
    }

    return intl;
};

const MaxWidthContainer = styled.div`
    margin-bottom: 1rem;
`;

const BookableServiceAvailability = (props) => {
    const { application, unavailable, onMonthNavigation } = props;
    const { locale } = application;

    const intl = loadIntl(locale.language);
    const today = new Date();

    const modifiers = {
        start: today,
        unavailable: unavailable,
        disabled: { before: today, after: addDays(today, 180) },
    };

    return (
        <MaxWidthContainer>
            <DateRangePickerGlobalStyle />
            <GlobalStyle />
            <DayPicker
                className='BookableServiceAvailability'
                numberOfMonths={1}
                navbarElement={<StyledDatePickerNavigationHeader onMonthNavigation={onMonthNavigation} />}
                month={today}
                todayButton={'Today'}
                modifiers={modifiers}
                months={intl.MONTHS}
                weekdaysShort={intl.WEEKDAYS_SHORT}
                weekdaysLong={intl.WEEKDAYS_LONG}
                firstDayOfWeek={getFirstDayOfTheWeekOfLocale(locale)}
            />
            <DatePickerLegend />
        </MaxWidthContainer>
    );
};

export default withApplicationAwareness(BookableServiceAvailability);
