import React from 'react';
import AcceptedPet from '../../../Shared/Pets/AcceptedPet';
import { withTranslation } from '../../../../i18n';

const SmallAnimals = ({ t }) => {
    return (
        <AcceptedPet
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" viewBox="0 0 62.5 62.5" xml:space="preserve"><g id="g3" transform="matrix(1.23005 0 0 1.23004 -12.908 -117.74)" fill="none" fill-opacity="1" stroke="#000" stroke-width="1.524" stroke-linejoin="round" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"><path d="M55.861 116.304c-1.01-2.213-1.47-5.033-3.905-6.18-3.248-.372-1.243-3.636-1.8-5.796-.466-2.255-.54-4.54-1.825-6.516-.7-1.223-2.76-2.27-2.702.016-.62 1.385-.322 3.695-1.15 4.613-2.194-2.469-5.437-4.239-8.802-4.186-1.58.86 1.233 3.147 1.638 4.46 1.28 2.114 3.13 3.786 4.516 5.802 1.305 2.139.724 4.078-.502 5.964.08 1.617-1.04 4.155-1.768 4.548-.841.983-2.477 1.627-4.102 1.202-2.14.308-4.352.437-6.358 1.25-1.992.546-3.616 1.719-5.414 2.775-1.117.305-2.68 2.143-2.794 2.486-1.763 1.433-2.017 2.358-3.16 4.073-.888 1.294-.906 2.814-1.718 4.825.283 1.189-.527 2.966-.265 4.24-.105 1.62-1.073 2.276-.398 3.736 1.105 1.266 2.94 1.967 4.38 1.734 2.6.784 5.321.2 7.967.202 1.984-.1 3.857-.416 5.28-1.692 2.044-.052 1.442-2.29.322-2.691.318-.213 3.632-2.73 4.448-2.076.87 1.28 2.186 2.685 3.096 3.526.824.627 1.787 2.407 2.364 2.072.801.866 2.396.843 3.854 1.032 1.585-.33 5.107-1.949 2.407-3.378-.727-.12-1.145-1.155-2.432-1.539.035-.644-1.049-1.407-.81-2.71-.958-1.542.821-2.17 1.622-2.992 1.17-1.838 1.964-3.18 2.429-5.347 1.141-1.582 1.044-3.045.677-4.836-.347-2.699 2.988-2.682 4.09-4.667 1.456-1.343 2.374-2.058.815-3.95z" id="path5" fill="none" fill-opacity="1" stroke="#000" stroke-width="1.524" stroke-linejoin="round" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></g></svg>'
            }
            label={t('sitter.aboutHome.acceptedPets.smallAnimals.label')}
            description={t('sitter.aboutHome.acceptedPets.smallAnimals.description')}
        />
    );
};

export default withTranslation('sitter')(SmallAnimals);
