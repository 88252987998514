import React from 'react';
import { HomeIconWithLabel } from './styles/HomeIconWithLabel';
import { withTranslation } from '../../../i18n';

const NearbyOffLeashDogArea = ({ t }) => {
    return (
        <HomeIconWithLabel
            label={t('sitter.aboutHome.walkingAreas.nearbyOffLeashDogArea')}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" viewBox="0 0 62.499996 62.500002" xml:space="preserve" width="66.667" height="50pt"><g id="g3" transform="translate(.5 -371.09)"><g id="g5"><path d="M54.567 411.688a1.473 1.473 0 0 0-.376-.53l-4.56-4.082 2.393-10.124c6.667 0 8.876-5.486 8.947-5.676a1.469 1.469 0 0 0-.86-1.89l-4.941-1.85a4.624 4.624 0 0 0-3.208-3.281l-.327-2.915a1.469 1.469 0 0 0-2.572-.795l-.894 1.038-.704-2.248a1.469 1.469 0 0 0-1.388-1.03h-.014c-.63 0-1.192.404-1.392 1.004l-5.41 16.193-20.846 2.011a10.064 10.064 0 0 1-8.55-3.255 1.469 1.469 0 0 0-2.182 1.966 13.008 13.008 0 0 0 8.286 4.215c-1.475 1.155-2.858 2.894-3.875 5.5l-5.224.304a1.468 1.468 0 0 0-1.042.526l-5.05 6.05a1.468 1.468 0 0 0 .239 2.11l1.082.824a1.466 1.466 0 0 0 2-.208l3.912-4.526 6.487 1.384-3.04 2.825a1.47 1.47 0 0 0-.467 1.145l.382 8.132c.033.711.571 1.295 1.277 1.388l1.903.248a1.469 1.469 0 0 0 1.659-1.462l-.024-5.879 9.954-5.038c.044-.022.087-.046.128-.073 1.532-.98 2.529-2.203 3.17-3.47 3.793.822 9.033 1.886 13.148 2.447l-.778 4.815-6.412 5.373a1.468 1.468 0 0 0-.19 2.06l.766.927a1.467 1.467 0 0 0 1.854.344l7.845-4.429a1.47 1.47 0 0 0 .708-.94l1.668-7.054 2.543 1.982 3.973 7.316c.257.474.752.768 1.29.768h1.565a1.469 1.469 0 0 0 1.355-2.033l-4.208-10.107z" id="path9" fill="none" stroke="#000" stroke-width="1.875" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></g></g></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(NearbyOffLeashDogArea);
