import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import StyledH2 from '../Shared/Titles/StyledH2';

const Section = styled.div`
    margin-bottom: 2rem;

    ${breakpoint('lg')`
    margin-bottom: 4rem;
  `};

    &::before {
        display: block;
        content: ' ';
        margin-top: -80px;
        height: 80px;
        visibility: hidden;
        pointer-events: none;
    }
`;

const SitterSection = ({ label, children, className, id }) => {
    return (
        <Section className={className} id={id}>
            <StyledH2>{label}</StyledH2>
            {children}
        </Section>
    );
};

export default SitterSection;
