import React from 'react';
import styled from 'styled-components';
import { groupRatesBasedUponGroup } from '../../Pawshake/Core/Service/Groups';
import Service from '../Service/Service';
import {
    determineAdditionalPetPriceOfServiceRate,
    determineBasePriceOfServiceRate,
    determineSecondDailyVisitPriceOfServiceRate,
} from '../../Pawshake/Core/Service/ServiceRate';
import GroupLabelText from '../Service/Labels/GroupLabelText';

const Wrapper = styled.div`
    margin-bottom: 1rem;
`;

const Group = styled.div`
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }
`;

const GroupHeader = styled.h3`
    margin-bottom: 1rem;
    color: #5c5c5c;
`;

const DisplayedService = styled(Service)`
    margin-bottom: 1rem;
`;

const SitterServices = ({ bookableRates, className, currency, repeatClientsOnlyBookableServices }) => {
    const orderedByGroup = groupRatesBasedUponGroup(bookableRates);

    return (
        <Wrapper className={className}>
            {Object.keys(orderedByGroup).map((group) => (
                <Group key={group}>
                    <GroupHeader>
                        <GroupLabelText group={group} />
                    </GroupHeader>
                    {orderedByGroup[group].map((service) => (
                        <DisplayedService service={service} currency={currency} key={service.id}>
                            <Service.Icon />
                            <Service.LabelWithFullDescriptionUnderneathAndExistingClientsOnly
                                existingClientsOnly={repeatClientsOnlyBookableServices.includes(service.id)}
                                additionalPet={determineAdditionalPetPriceOfServiceRate(service, bookableRates)}
                                secondDailyVisit={determineSecondDailyVisitPriceOfServiceRate(service, bookableRates)}
                            />
                            <Service.Price amount={determineBasePriceOfServiceRate(service, bookableRates)} />
                        </DisplayedService>
                    ))}
                </Group>
            ))}
        </Wrapper>
    );
};

export default SitterServices;
