import React from 'react';
import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import { getSearchPages } from '../../../Pawshake/Core/Drupal/DrupalPages';
import { withTranslation } from '../../../i18n';
import UnorderedHyperlinkList from '../../../Infrastructure/Shared/UnorderedHyperlinkList/UnorderedHyperlinkList';

const SearchPagesInLocale = ({ t, application, service, serviceSlug }) => {
    const { locale } = application;
    const searchPages = getSearchPages(locale).map((city) => {
        return {
            title: t(`search.header.title.${service.id}`, {
                location: city.name,
            }),
            text: city.name,
            href: `/${serviceSlug}/${city.link}`,
        };
    });

    return <UnorderedHyperlinkList items={searchPages} />;
};

export default withApplicationAwareness(withTranslation('search')(SearchPagesInLocale));
