import {
    catSittingService,
    doggyDayCareService,
    dogSittingService,
    dogWalkingService,
    homeDogBoardingService,
    houseSittingService,
    petSittingService,
} from './index';

import { NorwegianNorway, DanishDenmark, DutchNetherlands, DutchBelgium, SwedishSweden } from '../Locale/Locale';

export const getAllSeoServices = (locale) => {
    if ([NorwegianNorway, DanishDenmark, DutchBelgium, DutchNetherlands, SwedishSweden].includes(locale)) {
        return [
            homeDogBoardingService,
            doggyDayCareService,
            dogWalkingService,
            catSittingService,
            petSittingService,
            houseSittingService,
        ];
    }

    return [
        homeDogBoardingService,
        doggyDayCareService,
        dogWalkingService,
        catSittingService,
        dogSittingService,
        petSittingService,
        houseSittingService,
    ];
};
