/* eslint-disable @typescript-eslint/no-var-requires */
const {
    homeDogBoarding,
    doggyDayCare,
    dogWalking,
    houseSitting,
    petSitting,
    dogSitting,
    catSitting,
} = require('../../../Pawshake/Core/Service');

const topCitiesSlugs = {
    en: {
        [homeDogBoarding]: 'top-dog-boarding-cities',
        [doggyDayCare]: 'top-doggy-day-care-cities',
        [dogWalking]: 'top-dog-walking-cities',
        [houseSitting]: 'top-house-sitting-cities',
        [petSitting]: 'top-petsitters-cities',
        [dogSitting]: 'top-dog-sitting-cities',
        [catSitting]: 'top-cat-sitting-cities',
    },
    nl: {
        [homeDogBoarding]: 'top-steden-hondenoppas',
        [doggyDayCare]: 'top-steden-hondenopvang',
        [dogWalking]: 'top-steden-hondenuitlaatservice',
        [houseSitting]: 'top-steden-huisoppas',
        [petSitting]: 'top-steden-dierenoppas',
        [dogSitting]: 'top-steden-dog-sitting',
        [catSitting]: 'top-steden-kattenoppas',
    },
    fr: {
        [homeDogBoarding]: 'villes-populaires-garde-chien',
        [doggyDayCare]: 'villes-populaires-garderie-pour-chien',
        [dogWalking]: 'villes-populaires-promenade-chien',
        [houseSitting]: 'villes-populaires-gardiennage-maison',
        [petSitting]: 'villes-populaires-garde-animaux',
        [dogSitting]: 'villes-populaires-dog-sitter',
        [catSitting]: 'villes-populaires-garde-chat',
    },
    de: {
        [homeDogBoarding]: 'top-staedte-hundebetreuung',
        [doggyDayCare]: 'top-staedte-hundetagesbetreuung',
        [dogWalking]: 'top-staedte-gassi-gehen',
        [houseSitting]: 'top-staedte-haussitting',
        [petSitting]: 'top-staedte-tierbetreuung',
        [dogSitting]: 'top-staedte-hundesitting',
        [catSitting]: 'top-staedte-katzenbetreuung',
    },
    it: {
        [homeDogBoarding]: 'top-citta-pensione-cane',
        [doggyDayCare]: 'top-citta-asilo-cane',
        [dogWalking]: 'top-citta-passeggiata-cane',
        [houseSitting]: 'top-citta-house-sitting',
        [petSitting]: 'top-citta-pet-sitting',
        [dogSitting]: 'top-citta-dog-sitter',
        [catSitting]: 'top-citta-cat-sitter',
    },
    fi: {
        [homeDogBoarding]: 'suosituimmat-koiran-kotihoito-kaupungit',
        [doggyDayCare]: 'suosituimmat-koiran-paivahoito-kaupungit',
        [dogWalking]: 'suosituimmat-koiran-ulkoilutus-kaupungit',
        [houseSitting]: 'suosituimmat-talonvahtipalvelu-kaupungit',
        [petSitting]: 'suosituimmat-lemmikinhoito-kaupungit',
        [dogSitting]: 'suosituimmat-koiranhoito-kaupungit',
        [catSitting]: 'suosituimmat-kissanhoito-kaupungit',
    },
    no: {
        [homeDogBoarding]: 'populaere-hundepass-byer',
        [doggyDayCare]: 'populaere-hundebarnehage-byer',
        [dogWalking]: 'populaere-hundelufting-byer',
        [houseSitting]: 'populaere-huspass-byer',
        [petSitting]: 'populaere-dyrepass-byer',
        [catSitting]: 'populaere-kattepass-byer',
    },
    da: {
        [homeDogBoarding]: 'top-byer-hundepasning',
        [doggyDayCare]: 'top-byer-hundedagpleje',
        [dogWalking]: 'top-byer-hundeluftning',
        [houseSitting]: 'top-byer-huspasning',
        [petSitting]: 'top-byer-dyresitting',
        [catSitting]: 'top-byer-kattesitting',
    },
    sv: {
        [homeDogBoarding]: 'populara-hundvaktnings-orter',
        [doggyDayCare]: 'populara-hunddagis-orter',
        [dogWalking]: 'populara-hundrastnings-orter',
        [houseSitting]: 'populara-husvaktnings-orter',
        [petSitting]: 'populara-djurvaktnings-orter',
        [catSitting]: 'populara-kattvaktnings-orter',
    },
    ja: {
        [homeDogBoarding]: 'top-dog-boarding-cities',
        [doggyDayCare]: 'top-doggy-day-care-cities',
        [dogWalking]: 'top-dog-walking-cities',
        [houseSitting]: 'top-house-sitting-cities',
        [petSitting]: 'top-petsitters-cities',
        [dogSitting]: 'top-dog-sitting-cities',
        [catSitting]: 'top-cat-sitting-cities',
    },
};

const getTopCitySlugBasedUponServiceInLanguage = (service, language) => {
    return topCitiesSlugs[language][service.id];
};

module.exports = {
    topCitiesSlugs,
    getTopCitySlugBasedUponServiceInLanguage,
};
