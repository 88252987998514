import { flowRight } from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import PaymentMethodsByCountry from '../../../Infrastructure/PaymentMethods/PaymentMethodsByCountry';
import SubTitle from '../../../Infrastructure/Shared/Titles/SubTitle';
import {
    isAuthenticatedButWithoutPawshakeAccount,
    isJwtAuthenticated,
    isJwtAuthenticatedLoading,
} from '../../../domain/User/Authentication/selectors';
import { withTranslation } from '../../../i18n';
import Breakpoints from '../../../styles/breakpoints';
import transformImmutableToJS from '../../../utils/transformImmutableToJS';
import Container from '../Container';
import About from './About';
import FeaturedSearchPages from './FeaturedSearchPages';
import FooterCopyright from './FooterCopyright';
import LearnMore from './LearnMore';
import SocialMedia from './SocialMedia';

const CountrySwitcher = dynamic(() => import('./CountrySwitcher'), {
    ssr: false,
});

const Wrapper = styled.div`
    color: #ffffff;
    a {
        text-decoration: none;
    }
    a,
    h2 {
        color: #ffffff;
    }
`;

const TopFooter = styled.div`
    background-color: #5cace2;
`;

const FooterColumn = styled.div`
    width: 100%;

    @media ${Breakpoints.sm} {
        && {
            width: 50%;
        }
    }

    @media ${Breakpoints.md} {
        && {
            width: 25%;
        }
    }
`;

const BottomFooter = styled.div`
    background-color: #5197c6;
    a {
        text-decoration: underline;
    }
`;

const FooterContainer = styled(Container)`
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    @media ${Breakpoints.md} {
        && {
            flex-wrap: nowrap;
        }
    }
`;

const TopFooterContainer = styled(Container)`
    justify-content: space-evenly;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
`;

const Copyright = styled(FooterCopyright)`
    width: 100%;
    text-align: center;

    @media ${Breakpoints.md} {
        && {
            text-align: left;
            width: 80%;
        }
    }
`;

const CountrySwitchWrapper = styled.div`
    width: 100%;

    @media ${Breakpoints.md} {
        && {
            width: 20%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
`;

const showFooter = (
    showTopFooter,
    isAuthenticated,
    isAuthenticatedLoading,
    isAuthenticatedButWithoutPawshakeAccount,
    path
) => {
    if (showTopFooter === true) {
        return true;
    }

    if (path === '/') {
        return true;
    }

    if (isAuthenticatedButWithoutPawshakeAccount === true) {
        return true;
    }

    return isAuthenticated === false && isAuthenticatedLoading === false;
};

const Footer = ({
    showTopFooter = false,
    showBottomFooter = true,
    t,
    isAuthenticated,
    isAuthenticatedLoading,
    isAuthenticatedButWithoutPawshakeAccount,
}) => {
    const router = useRouter();

    return (
        <Wrapper>
            {showFooter(
                showTopFooter,
                isAuthenticated,
                isAuthenticatedLoading,
                isAuthenticatedButWithoutPawshakeAccount,
                router.asPath
            ) && (
                <TopFooter>
                    <TopFooterContainer>
                        <FooterColumn>
                            <LearnMore />
                        </FooterColumn>
                        <FooterColumn>
                            <FeaturedSearchPages title={t('footer.categories.popularCities.title')} />
                        </FooterColumn>
                        <FooterColumn>
                            <About />
                            <SubTitle>{t('footer.categories.paymentMethods.title')}</SubTitle>
                            <PaymentMethodsByCountry />
                        </FooterColumn>
                        <FooterColumn>
                            <SocialMedia />
                        </FooterColumn>
                    </TopFooterContainer>
                </TopFooter>
            )}

            {showBottomFooter && (
                <BottomFooter>
                    <FooterContainer>
                        <Copyright />
                        <CountrySwitchWrapper>
                            <CountrySwitcher />
                        </CountrySwitchWrapper>
                    </FooterContainer>
                </BottomFooter>
            )}
        </Wrapper>
    );
};

const mapStateToProps = (state) => {
    const computedData = createStructuredSelector({
        isAuthenticated: isJwtAuthenticated,
        isAuthenticatedButWithoutPawshakeAccount: isAuthenticatedButWithoutPawshakeAccount,
        isAuthenticatedLoading: isJwtAuthenticatedLoading,
    })(state);

    return transformImmutableToJS(computedData);
};

// flowRight(withTranslation(['user', 'profile']), connect(mapStateToProps, { userLogOutRequest }))(Header);
export default flowRight(withTranslation('footer'), connect(mapStateToProps, {}))(Footer);
