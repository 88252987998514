import React from 'react';
import styled from 'styled-components';
import AnimalAvatar from '../Shared/Avatar/AnimalAvatar';
import { withTranslation } from '../../i18n';

const Pet = styled.div`
    color: #747474;
    text-decoration: none;
    width: 14rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Description = styled.div`
    color: #939393;
    margin-top: 0.5rem;
`;

const Avatar = styled(AnimalAvatar)`
    height: 6rem;
    width: 6rem;
    margin-right: 1rem;
`;

const SitterResidentPet = ({ t, name, petId, breed, species, imageUrl }) => {
    const petDescription = breed ? breed : t(`common:petTypes.${species}`);

    return (
        <Pet
            onClick={() => {
                window.location = `/pet/${petId}`;
            }}
        >
            <Avatar alt={petDescription} src={imageUrl} />
            <Info>
                <div>{name}</div>
                <Description>{petDescription}</Description>
            </Info>
        </Pet>
    );
};

export default withTranslation('common')(SitterResidentPet);
