import React from 'react';
import SubTitle from '../../../Infrastructure/Shared/Titles/SubTitle';
import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import { getSocialMediaLinks } from '../../../Pawshake/Core/SocialMedia';
import { withTranslation } from '../../../i18n';
import UnorderedHyperlinkList from '../../../Infrastructure/Shared/UnorderedHyperlinkList/UnorderedHyperlinkList';

const SocialMedia = ({ t, application }) => {
    return (
        <>
            <SubTitle>{t('footer.categories.socialMedia.title')}</SubTitle>
            <UnorderedHyperlinkList items={getSocialMediaLinks(application.country)} />
        </>
    );
};

export default withApplicationAwareness(withTranslation('footer')(SocialMedia));
