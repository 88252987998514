import React from 'react';
import styled from 'styled-components';
import Service from '../Service/Service';
import { trackClickAdditionalServices } from '../../Application/Sitter/Analytics';
import { withTranslation } from '../../i18n';
import {
    determineAdditionalPetPriceOfServiceRate,
    determineBasePriceOfServiceRate,
    determineSecondDailyVisitPriceOfServiceRate,
} from '../../Pawshake/Core/Service/ServiceRate';

const Wrapper = styled.div`
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cecece;
`;

const DisplayedService = styled(Service)`
    margin-bottom: 1rem;
`;

const SitterHighlightedService = (props) => {
    const { t, className, bookableRates, service, currency, mobile, repeatClientsOnlyBookableServices } = props;
    return (
        <Wrapper className={className}>
            <DisplayedService service={service} currency={currency}>
                <Service.Icon />
                <Service.LabelWithShortDescriptionUnderneathAndExistingClientsOnly
                    existingClientsOnly={repeatClientsOnlyBookableServices.includes(service.id)}
                    additionalPet={determineAdditionalPetPriceOfServiceRate(service, bookableRates)}
                    secondDailyVisit={determineSecondDailyVisitPriceOfServiceRate(service, bookableRates)}
                />
                <Service.Price amount={determineBasePriceOfServiceRate(service, bookableRates)} />
            </DisplayedService>
            <a
                href={'#services'}
                onClick={() => {
                    trackClickAdditionalServices(mobile);
                }}
            >
                {t('sitter.additionalServices')}
            </a>
        </Wrapper>
    );
};

export default withTranslation('sitter')(SitterHighlightedService);
