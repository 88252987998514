import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import FavoriteIndicator from '../../Application/Favorites/FavoriteIndicator';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import withTrackingService from '../../contexts/TrackingServiceContext';
import { withTranslation } from '../../i18n';
import { PoliceCheckBadge } from '../../styles/icons';
import { AnalyticKeys } from '../../utils/constants/analytics.constants';
import { withoutWorldRegionPrefix } from '../../utils/sitter';
import CancellationPolicy from '../Finance/CancellationPolicy';
import PaymentMethodsByCountry from '../PaymentMethods/PaymentMethodsByCountry';
import Avatar from '../Shared/Avatar/Avatar';
import { GreenButton } from '../Shared/Button/Buttons';
import Insurance from '../Shared/Insurance/Insurance';
import SitterContact from './SitterContact';
import SitterHighlightedService from './SitterHighlightedService';
import SitterInfo from './SitterInfo';
import { parsedUrlQueryToURLSearchParams } from './SitterProfile';

const InfoCard = styled.div`
    padding: 1rem;
    border: 1px solid #cecece;
    border-radius: 4px;
`;

const Box = styled.div`
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid #cecece;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
`;

const Suffix = styled.div`
    padding: 1rem;
`;

const StyledCancellationPolicy = styled(CancellationPolicy)`
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #cecece;
`;

const UserName = styled.p`
    font-size: 1.5rem;
    color: #4f4f4f;
    margin-top: 0;
    margin-bottom: 0.5rem;
`;

const AvatarWrapper = styled.div`
    position: relative;
    height: fit-content;
`;

const PoliceBadgeWrapper = styled.div`
    position: absolute;
    bottom: 8px;
    right: 0px;
    width: 1.5rem;
    height: 1.5rem;
`;

const Info = styled.div`
    width: 100%;
    word-break: break-word;
    h2 {
        font-size: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`;

const CenteredInsurance = styled(Insurance)`
    text-align: center;
`;

const StyledFavoriteIndicator = styled(FavoriteIndicator)`
    margin-left: 1rem;
    float: right;
`;

const RequestBooking = styled(GreenButton)`
    text-align: center;
`;

const SitterInfoCard = ({
    t,
    userImage,
    userName,
    sitterName,
    sitterId,
    policy,
    policeCheck,
    showCancellationPolicyExtraEducation,
    application,
    service,
    bookableRates,
    repeatClientsOnlyBookableServices,
    currency,
    placeName,
    canBeContactedByPetOwner,
    trackingService,
}) => {
    const router = useRouter();
    const queryparams = parsedUrlQueryToURLSearchParams(router.query, sitterId).toString();

    return (
        <>
            <InfoCard>
                <Box>
                    <AvatarWrapper>
                        <Avatar alt={userName} src={userImage} />
                        {policeCheck.hasOne === true && (
                            <PoliceBadgeWrapper>
                                <PoliceCheckBadge />
                            </PoliceBadgeWrapper>
                        )}
                    </AvatarWrapper>
                    <Info>
                        <StyledFavoriteIndicator sitterId={sitterId} sitterName={userName} />
                        <UserName>{userName}</UserName>
                        <SitterInfo sitterName={sitterName} placeName={placeName} />
                    </Info>
                </Box>
                <SitterHighlightedService
                    service={service}
                    repeatClientsOnlyBookableServices={repeatClientsOnlyBookableServices}
                    bookableRates={bookableRates}
                    currency={currency}
                    mobile={false}
                />
                {canBeContactedByPetOwner.canBeContacted && (
                    <Link
                        passHref
                        rel={'nofollow'}
                        href={`/booking/request?${queryparams}`}
                        as={`/sitter/${withoutWorldRegionPrefix(sitterId)}/request-booking?${queryparams}`}
                        onClick={() => {
                            trackingService.trackEvent(AnalyticKeys.SITTER_PROFILE_CONTACT);
                        }}
                    >
                        <RequestBooking rel={'nofollow'}>
                            {t('sitter.contactSitter', { name: userName })}
                        </RequestBooking>
                    </Link>
                )}
                {canBeContactedByPetOwner.canBeContacted === false && (
                    <SitterContact name={userName} {...canBeContactedByPetOwner} />
                )}
                <StyledCancellationPolicy
                    cancellationPolicy={policy}
                    showExtraEducation={showCancellationPolicyExtraEducation}
                />
            </InfoCard>
            <Suffix>
                <PaymentMethodsByCountry country={application.country} />
                <CenteredInsurance />
            </Suffix>
        </>
    );
};

export default withApplicationAwareness(withTrackingService(withTranslation('sitter')(SitterInfoCard)));
