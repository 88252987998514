import styled from 'styled-components';
import IconWithLabel from '../../../Shared/IconWithLabel/IconWithLabel';
import breakpoint from 'styled-components-breakpoint';

export const HomeIconWithLabel = styled(IconWithLabel)`
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    width: 100%;

    ${breakpoint('md')`
    margin-bottom: 1rem;
    width: 40%;
  `};
`;
