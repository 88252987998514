import React from 'react';
import Breadcrumbs from '../Shared/Breadcrumb/Breadcrumbs';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { getServiceSlugInLocale } from '../../Application/Search/ServiceSlugs';
import { petSittingService } from '../../Pawshake/Core/Service';
import { withTranslation } from '../../i18n';
import { generateJsonLDSnippet } from '../../Pawshake/StructuredData/Utils';

const SitterBreadcrumbs = ({ t, application, sitterUrl, pageDescription, userName, petSittingSearchPage }) => {
    const { domain, locale } = application;
    const serviceSlug = getServiceSlugInLocale(petSittingService, locale);

    const serviceLabel = t('services:services.' + petSittingService.id + '.label');
    const servicePageDescription = t('services:services.' + petSittingService.id + '.description');

    let crumbs = [
        {
            name: serviceLabel,
            link: `${domain}/${serviceSlug}`,
            title: servicePageDescription,
        },
    ];

    let itemListElements = [
        {
            '@type': 'ListItem',
            name: t('components:components.breadcrumbs.home.title'),
            position: 1,
            description: t('components:components.breadcrumbs.home.description'),
            item: {
                '@type': 'Thing',
                '@id': domain,
            },
        },
        {
            '@type': 'ListItem',
            name: serviceLabel,
            position: 2,
            description: servicePageDescription,
            item: {
                '@type': 'Thing',
                '@id': `${application.domain}/${serviceSlug}`,
            },
        },
    ];

    if (petSittingSearchPage) {
        crumbs.push({
            name: petSittingSearchPage.text,
            title: petSittingSearchPage.title,
            link: petSittingSearchPage.href,
        });

        itemListElements.push({
            '@type': 'ListItem',
            name: petSittingSearchPage.text,
            position: itemListElements.length + 1,
            description: petSittingSearchPage.title,
            item: {
                '@type': 'Thing',
                '@id': petSittingSearchPage.href,
            },
        });
    }

    crumbs.push({
        name: userName,
        title: pageDescription,
        link: sitterUrl,
    });

    itemListElements.push({
        '@type': 'ListItem',
        name: userName,
        position: itemListElements.length + 1,
        description: pageDescription,
        item: {
            '@type': 'Thing',
            '@id': sitterUrl,
        },
    });

    return (
        <>
            <Breadcrumbs homeLink={domain} crumbs={crumbs} />
            {generateJsonLDSnippet({
                '@context': 'http://schema.org/',
                '@type': 'BreadcrumbList',
                itemListElement: itemListElements,
            })}
        </>
    );
};

export default withApplicationAwareness(withTranslation('services')(SitterBreadcrumbs));
