import { createAction } from 'redux-actions';
import {
    TOGGLE_FAVORITE_SITTER_FAILURE,
    TOGGLE_FAVORITE_SITTER_REQUEST,
    TOGGLE_FAVORITE_SITTER_SUCCESS,
    FAVORITE_SITTERS_FETCHED,
} from './constants';

export const favoriteSittersFetched = createAction(FAVORITE_SITTERS_FETCHED);

export const toggleFavoriteSitterRequest = createAction(TOGGLE_FAVORITE_SITTER_REQUEST);
export const toggleFavoriteSitterSuccess = createAction(TOGGLE_FAVORITE_SITTER_SUCCESS);
export const toggleFavoriteSitterFailure = createAction(TOGGLE_FAVORITE_SITTER_FAILURE);
