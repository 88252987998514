export const determineSitterProfileUrl = (profileUrls, localeString) => {
    let selectedProfileUrl = selectBasedUponLocale(profileUrls, localeString);

    if (typeof selectedProfileUrl === 'undefined') {
        selectedProfileUrl = selectBasedUponLanguageOfLocale(profileUrls, localeString);
    }

    if (typeof selectedProfileUrl === 'undefined') {
        selectedProfileUrl = selectFirst(profileUrls);
    }

    return selectedProfileUrl.url;
};

const selectBasedUponLocale = (urls, locale) => {
    return urls.find((url) => url.locale === locale);
};

const selectBasedUponLanguageOfLocale = (urls, locale) => {
    return urls.find((url) => url.locale.split('-')[0] === locale.split('-')[0]);
};

const selectFirst = (urls) => {
    return urls[0];
};
