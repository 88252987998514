import styled from 'styled-components';
import Colors from '../../../styles/colors';

const Wrapper = styled.div`
    display: flex;
    gap: 1rem;
    min-width: 12rem;
`;

const Labels = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

const Caption = styled.div`
    color: ${Colors.text.light};
`;

const IconWrapper = styled.div`
    width: ${(props) => props.iconWidth};
    height: ${(props) => props.iconWidth};
    flex-shrink: 0;
    color: #00bf8e;

    svg {
        width: 100%;
        height: 100%;
    }
`;

const Statistic = ({ value, label, className, icon, iconWidth = '1.5rem' }) => {
    return (
        <Wrapper className={className}>
            <IconWrapper iconWidth={iconWidth}>{icon}</IconWrapper>
            <Labels direction='column'>
                {value}
                <Caption>{label}</Caption>
            </Labels>
        </Wrapper>
    );
};

export default Statistic;
