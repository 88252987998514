export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const GIANT = 'giant';

export const dogSizes = {
    small: { min: 0, max: 10 },
    medium: { min: 10, max: 20 },
    large: { min: 20, max: 40 },
    giant: { min: 40, max: 80 },
};
