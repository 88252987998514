import React from 'react';
import { SkillIconWithLabel } from '../styles/SkillIconWithLabel';
import { withTranslation } from '../../../../i18n';

const AnimalWelfare = ({ t }) => {
    return (
        <SkillIconWithLabel
            inline={true}
            label={t('sitter.skills.experience.animalWelfare')}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" viewBox="0 0 62.500001 62.500002" xml:space="preserve" width="50pt" height="50pt"><g id="g3" transform="translate(-1.477 .937) scale(.1468)" stroke="#000" stroke-width="12.772" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1" fill="none"><path d="M403.677 265.619l-72.994 97.01a7.5 7.5 0 0 0-1.479 5.158l3.922 45.174-91.746-.125-.929-95.695a55.477 55.477 0 0 1 12.779-35.94l64.473-77.646a8.691 8.691 0 0 1 6.137-3.146 8.681 8.681 0 0 1 6.517 2.264c3.351 3.049 3.81 8.25 1.045 11.839l-48.431 62.893a7.498 7.498 0 0 0 1.798 10.826c17.332 11.495 40.938 7.657 53.737-8.737l40.839-52.305a7.5 7.5 0 0 0 1.589-4.69l-.925-93.174c-.063-6.275 4.792-11.548 11.051-12.005a11.83 11.83 0 0 1 8.678 2.885c2.412 2.093 3.86 5 4.079 8.186l7.616 111.077a39.064 39.064 0 0 1-7.756 26.151z" id="path5"/><path d="M186.592 412.836l-91.745.125 3.922-45.174a7.496 7.496 0 0 0-1.479-5.158l-72.995-97.01a39.063 39.063 0 0 1-7.756-26.151l7.617-111.077a11.839 11.839 0 0 1 4.079-8.186 11.826 11.826 0 0 1 8.678-2.885c6.259.456 11.112 5.729 11.05 12.005l-.925 93.174a7.493 7.493 0 0 0 1.589 4.69l40.839 52.305c12.801 16.394 36.405 20.23 53.738 8.738a7.501 7.501 0 0 0 1.798-10.827L96.57 214.513c-2.764-3.59-2.305-8.79 1.047-11.84a8.7 8.7 0 0 1 6.515-2.264 8.693 8.693 0 0 1 6.138 3.147l64.473 77.646a55.48 55.48 0 0 1 12.779 35.94l-.93 95.694z" id="path7"/><path d="M360.908 84.394c0-22.542-8.779-43.735-24.719-59.675C320.249 8.778 299.057 0 276.514 0c-22.542 0-43.735 8.778-59.676 24.718l-2.852 2.853-2.853-2.853C195.194 8.778 174.001 0 151.458 0c-22.542 0-43.735 8.778-59.676 24.718-32.904 32.905-32.904 86.445.001 119.351l116.899 116.899a7.482 7.482 0 0 0 5.304 2.197 7.478 7.478 0 0 0 5.304-2.197l116.899-116.9c15.94-15.939 24.719-37.132 24.719-59.674z" id="path4195"/></g></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(AnimalWelfare);
