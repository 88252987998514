import { createSelector } from 'reselect';

export const selectRoot = (state) => state.getIn(['application', 'device']);

// We should always keep these in sync with the values in _app.js

export const isMobile = createSelector(selectRoot, (root) => root.get('width') < 480);

export const isTablet = createSelector(selectRoot, (root) => root.get('width') < 768);

export const isDesktop = createSelector(selectRoot, (root) => root.get('width') > 767);
