import { withTranslation } from '../../../i18n';
import Statistic from '../../Shared/Statistic/Statistic';

const ResponseTime = ({ t, responseTime }) => {
    return (
        <Statistic
            value={'<' + responseTime + 'h'}
            label={t('sitter.statistics.responseTime')}
            icon={
                <svg viewBox='0 0 11 18' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M10.862 7.284a1.098 1.098 0 0 0-.962-.576H7.246l.45-5.496c.042-.508-.26-.98-.734-1.148-.473-.167-1 .012-1.277.436l-5.5 8.382A1.133 1.133 0 0 0 .13 10.03c.191.363.564.59.97.59h2.662l-.459 6.061c-.039.513.272.987.753 1.148a1.096 1.096 0 0 0 1.276-.47l5.5-8.941c.213-.345.224-.779.03-1.134z'
                        fillRule='nonzero'
                        fill='#00C38A'
                    />
                </svg>
            }
        />
    );
};

export default withTranslation('sitter')(ResponseTime);
