import React from 'react';
import styled from 'styled-components';
import { withTranslation } from '../../i18n';

const Legend = styled.div`
    display: table;
    border-collapse: separate;
    border-spacing: 0.5rem;
`;

const Day = styled.div`
    display: table-cell;
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
    text-align: center;
`;

const AvailableIcon = styled(Day)`
    background-color: #e0f1d8;
    color: #00c38a;
`;

const UnavailableIcon = styled(Day)`
    background-color: #ffeef0;
    color: #f7718d;
    text-decoration: line-through;
`;

const Label = styled.span`
    display: table-cell;
    margin-left: 1rem;
    vertical-align: middle;
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;

const DatePickerLegend = ({ t }) => {
    return (
        <Container>
            <Legend>
                <AvailableIcon />
                <Label>{t('datePicker.available')}</Label>
                <UnavailableIcon />
                <Label>{t('datePicker.unavailable')}</Label>
            </Legend>
            <Label>{t('datePicker.unableToBookMoreThanXDaysInAdvance')}</Label>
        </Container>
    );
};

export default withTranslation('common')(DatePickerLegend);
