import React from 'react';
import Head from 'next/head';

const BasicPageTwitterTags = ({ url, title, description, image }) => {
    return (
        <Head>
            <meta name='twitter:card' content='summary' />
            <meta name='twitter:url' content={url} />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            {image && <meta name='twitter:image' content={image} />}
        </Head>
    );
};

export default BasicPageTwitterTags;
