import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SitterReview from '../Review/SitterReview';
import { getServiceBasedUponId } from '../../Pawshake/Core/Service';
import { sitterDetailReviewsRequest } from '../../domain/Sitter/actions';
import { selectReviews, selectTotalReviews } from '../../domain/Sitter/selectors';
import transformImmutableToJS from '../../utils/transformImmutableToJS';
import Pager from '../Shared/Pager/Pager';
import { trackSitterReviewsPageChange } from '../../Application/Sitter/Analytics';
import { isRequired } from '../../Pawshake/Pager/PagerUtilities';

export const PAGE_SIZE = 2;

const SitterReviews = ({ sitterId, sitterDetailReviewsRequest, total, reviews }) => {
    const [currentPage, setCurrentPage] = useState(1);
    return (
        <>
            {reviews.map((review) => (
                <SitterReview
                    key={review.reviewId}
                    comment={review.comment}
                    service={getServiceBasedUponId(review.service)}
                    rating={review.starRating}
                    reviewDate={review.reviewedOnDate}
                    reviewerImage={review.reviewer.profileImageUrl}
                    reviewerName={review.reviewer.userName}
                />
            ))}
            {isRequired(total, PAGE_SIZE) && (
                <Pager
                    current={currentPage}
                    total={total}
                    size={PAGE_SIZE}
                    onPageChange={(page) => {
                        setCurrentPage(page);
                        sitterDetailReviewsRequest({
                            sitterId,
                            pageSize: PAGE_SIZE,
                            pageNumber: page,
                        });
                    }}
                    trackOnPageChange={trackSitterReviewsPageChange}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    const computedData = createStructuredSelector({
        reviews: selectReviews,
        total: selectTotalReviews,
    })(state);

    return transformImmutableToJS(computedData);
};

export default connect(mapStateToProps, { sitterDetailReviewsRequest })(SitterReviews);
