import { doggyDayCare, dogWalking, homeDogBoarding } from '../Pawshake/Core/Service';
import { getBookableServiceBasedUponId } from '../Pawshake/Core/Service/BookableService';

export const sitterProvidesHomeServices = (rates) => {
    return doggyDayCare in rates || homeDogBoarding in rates;
};

export const sitterProvidesDoggyDayCareOrDogWalks = (rates) => {
    return doggyDayCare in rates || dogWalking in rates;
};

export const determineHighlightedService = (rates, searchedService) => {
    const services = Object.keys(rates);

    let selectedService;
    if (searchedService && services.includes(searchedService)) {
        selectedService = searchedService;
    } else {
        if (searchedService === 'homeVisits') {
            selectedService = services.find((service) => service === 'oneHomeVisit');
            if (!selectedService) {
                selectedService = services[0];
            }
        } else {
            selectedService = services[0];
        }
    }

    return getBookableServiceBasedUponId(selectedService);
};

export const selectSearchPageUrl = (searchPageUrls, service, locale) => {
    if (searchPageUrls.length === 0) {
        return false;
    }

    const specificSearchPageUrl = searchPageUrls.find(
        (searchPageUrl) => searchPageUrl.service === service.id && searchPageUrl.locale === locale.toString()
    );

    if (typeof specificSearchPageUrl === 'undefined') {
        return false;
    }

    return specificSearchPageUrl;
};

export const withoutWorldRegionPrefix = (prefixedId) => {
    return prefixedId.replace('APAC_', '').replace('EU_', '');
};
