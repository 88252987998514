import PropTypes from 'prop-types';
import { Fragment } from 'react';
import styled from 'styled-components';
import Colors from '../../styles/colors';
import StarRatingBasedUponReviews from '../Shared/StartRatingBasedUponReviews/StarRatingBasedUponReviews';
import Vibrancy from '../Shared/Vibrancy/Vibrancy';
import Favorited from './Statistics/Favorited';

const Achievements = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
`;

const Divider = styled.div`
    width: 1px;
    height: 1rem;
    background-color: ${Colors.border.card};
`;

const SitterAchievements = ({ overallAverage, reviewCount, userId, favoriteCount, vibrancy, className }) => {
    return (
        <Achievements className={className}>
            {overallAverage && (
                <>
                    <StarRatingBasedUponReviews
                        addReviewsLink={true}
                        rating={overallAverage}
                        userId={userId}
                        reviews={reviewCount}
                    />
                    {(favoriteCount > 0 || vibrancy.length > 0) && <Divider />}
                </>
            )}
            {favoriteCount > 0 && (
                <>
                    <Favorited count={favoriteCount} />
                    {vibrancy.length > 0 && <Divider />}
                </>
            )}
            {vibrancy.map((item, index) => (
                <Fragment key={item.label}>
                    <Vibrancy vibrancy={item} />
                    {index !== vibrancy.length - 1 && <Divider />}
                </Fragment>
            ))}
        </Achievements>
    );
};

SitterAchievements.propTypes = {
    overallAverage: PropTypes.number,
    reviewCount: PropTypes.number,
    favoriteCount: PropTypes.number,
    vibrancy: PropTypes.array.isRequired,
};

export default SitterAchievements;
