import React from 'react';
import Head from 'next/head';

const BasicPageOpenGraph = ({ url, title, description, image, type }) => {
    return (
        <Head>
            <meta property='og:site_name' content='Pawshake' />
            <meta property='og:type' content={type} />
            <meta property='og:url' content={url} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            {image && <meta property='og:image' content={image} />}
            <meta property='fb:app_id' content={'243656042425757'} />
        </Head>
    );
};

export default BasicPageOpenGraph;
