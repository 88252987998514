const socialMedia = {
    AT: {
        Facebook: 'https://www.facebook.com/PawshakeOesterreich',
        Twitter: 'https://www.twitter.com/Pawshakede',
        Instagram: 'https://www.instagram.com/Pawshakede',
        Pinterest: 'https://www.pinterest.com/Pawshakeat/pins',
    },
    AU: {
        Facebook: 'https://www.facebook.com/PawshakeAustralia',
        Twitter: 'https://twitter.com/PawshakeAU',
        Instagram: 'https://www.instagram.com/PawshakeAU/',
    },
    BE: {
        Facebook: 'https://www.facebook.com/PawshakeBelgium',
        Twitter: 'https://www.twitter.com/PawshakeBE',
        Instagram: 'https://www.instagram.com/PawshakeBE',
        Pinterest: 'https://www.pinterest.com/PawshakeBE/pins',
    },
    CA: {
        Facebook: 'https://www.facebook.com/PawshakeCanada',
        Twitter: 'https://www.twitter.com/PawshakeCA',
        Instagram: 'https://www.instagram.com/PawshakeCA',
        Pinterest: 'https://www.pinterest.com/PawshakeCA/pins',
    },
    CH: {
        Facebook: 'https://www.facebook.com/PawshakeSwitzerland',
        Twitter: 'https://www.twitter.com/Pawshake',
        Pinterest: 'https://www.pinterest.com/PawshakeCH/pins',
    },
    DE: {
        Facebook: 'https://www.facebook.com/PawshakeDeutschland',
        Twitter: 'https://www.twitter.com/PawshakeDE',
        Instagram: 'https://www.instagram.com/PawshakeDE',
        Pinterest: 'https://www.pinterest.com/PawshakeDE/pins',
    },
    DK: {
        Facebook: 'https://www.facebook.com/PawshakeDenmark',
        Twitter: 'https://www.twitter.com/PawshakeDK',
        Instagram: 'https://www.instagram.com/pawshakescandinavia/',
        Pinterest: 'https://www.pinterest.com/PawshakeDK/pins',
    },
    FI: {
        Facebook: 'https://www.facebook.com/PawshakeFinland',
        Twitter: 'https://www.twitter.com/PawshakeFI',
        Instagram: 'https://www.instagram.com/pawshakesuomi/',
    },
    FR: {
        Facebook: 'https://www.facebook.com/PawshakeFrance',
        Twitter: 'https://www.twitter.com/PawshakeFR',
        Instagram: 'https://www.instagram.com/PawshakeFR',
        Pinterest: 'https://www.pinterest.com/PawshakeFR/pins',
    },
    HK: {
        Facebook: 'https://www.facebook.com/PawshakeHongKong',
        Twitter: 'https://www.twitter.com/Pawshake',
        Instagram: 'https://www.instagram.com/PawshakeHK',
        Pinterest: 'https://www.pinterest.com/PawshakeHK/pins',
    },
    IE: {
        Facebook: 'https://www.facebook.com/PawshakeIreland',
        Twitter: 'https://www.twitter.com/PawshakeIE',
        Pinterest: 'https://www.pinterest.com/PawshakeIE/pins/',
    },
    IT: {
        Facebook: 'https://www.facebook.com/PawshakeItalia',
        Twitter: 'https://www.twitter.com/PawshakeIT',
        Pinterest: 'https://www.pinterest.com/PawshakeIT/pins',
    },
    JP: {
        Facebook: 'https://www.facebook.com/PawshakeJapan',
        Twitter: 'https://www.twitter.com/PawshakeJP',
    },
    LU: {
        Facebook: 'https://www.facebook.com/Pawshake',
        Twitter: 'https://www.twitter.com/Pawshake',
    },
    NL: {
        Facebook: 'https://www.facebook.com/PawshakeNederland',
        Twitter: 'https://www.twitter.com/PawshakeNL',
        Instagram: 'https://www.instagram.com/PawshakeNL',
        Pinterest: 'https://www.pinterest.com/PawshakeNL/pins',
    },
    NO: {
        Facebook: 'https://www.facebook.com/PawshakeNorway',
        Twitter: 'https://www.twitter.com/PawshakeNO',
        Instagram: 'https://www.instagram.com/pawshakescandinavia/',
    },
    NZ: {
        Facebook: 'https://www.facebook.com/PawshakeNewZealand',
        Twitter: 'https://www.twitter.com/PawshakeNZ',
        Instagram: 'https://www.instagram.com/PawshakeNZ/',
        Pinterest: 'https://www.pinterest.com/PawshakeNZ/pins/',
    },
    SE: {
        Facebook: 'https://www.facebook.com/PawshakeSweden',
        Twitter: 'https://www.twitter.com/PawshakeSE',
        Instagram: 'https://www.instagram.com/pawshakescandinavia/',
    },
    SG: {
        Facebook: 'https://www.facebook.com/PawshakeSingapore',
        Twitter: 'https://www.twitter.com/PawshakeSG',
        Instagram: 'https://www.instagram.com/PawshakeSG/',
        Pinterest: 'https://www.pinterest.com/PawshakeSG/pins/',
    },
    UK: {
        Facebook: 'https://www.facebook.com/PawshakeUK',
        Twitter: 'https://www.twitter.com/PawshakeUK',
        Instagram: 'https://www.instagram.com/PawshakeUK',
        Pinterest: 'https://www.pinterest.com/PawshakeUK/pins',
    },
    GB: {
        Facebook: 'https://www.facebook.com/PawshakeUK',
        Twitter: 'https://www.twitter.com/PawshakeUK',
        Instagram: 'https://www.instagram.com/PawshakeUK',
        Pinterest: 'https://www.pinterest.com/PawshakeUK/pins',
    },
};

export const getSocialMediaLinks = (country) => {
    const links = socialMedia[country];
    if (links) {
        return Object.keys(links).map((link) => {
            return {
                text: link,
                rel: 'noopener',
                target: '_blank',
                href: links[link],
            };
        });
    }
};
