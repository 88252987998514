import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Lightbox from '../Shared/ImageVisualations/Lightbox';
import { trackClickSeeMorePhotos } from '../../Application/Sitter/Analytics';
import { withTranslation } from '../../i18n';

const Gallery = styled.div`
    position: relative;
    margin-bottom: 1rem;
`;

const Title = styled.h1`
    left: calc(8rem + 8px);
    ${breakpoint('md')`
    left: calc(10rem + 8px);  
  `}
    ${breakpoint('lg')`
    left: 1rem;
  `};

    position: absolute;
    bottom: 1rem;

    color: #ffffff;
    margin: 0;
    line-height: 2rem;
`;

const SitterGallery = (props) => {
    const { images, t, userName, location, mobile } = props;

    const alternateText = t('sitter.metaData.imageGalleryAltTag', {
        name: userName,
        location: location,
    });

    return (
        <Gallery>
            <Lightbox
                images={images}
                alt={alternateText}
                mobile={mobile}
                openLightboxTracking={trackClickSeeMorePhotos}
            />
            <Title>{userName}</Title>
        </Gallery>
    );
};

export default withTranslation('sitter')(SitterGallery);
