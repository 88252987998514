import { withTranslation } from '../../../i18n';
import Statistic from '../../Shared/Statistic/Statistic';

const minutesToObject = (timestamp) => {
    let r = {};
    let s = {
        year: 525600,
        week: 10080,
        day: 1440,
        hour: 60,
        minute: 1,
    };

    Object.keys(s).forEach(function (key) {
        r[key] = Math.floor(timestamp / s[key]);
        timestamp -= r[key] * s[key];
    });

    return r;
};

const LastActivityStatistic = ({ t, value }) => {
    const valueObject = minutesToObject(value);

    let timeKey = 'minute';
    let time = 0;
    let translationKey = 'sitter.statistics.lastActivityValueMinutesSuffix';
    const filtered = Object.keys(valueObject).filter((key) => valueObject[key]);
    if (filtered.length) {
        timeKey = filtered[0];
        time = valueObject[timeKey];

        switch (timeKey) {
            case 'hour':
                translationKey = 'sitter.statistics.lastActivityValueHoursSuffix';
                break;
            case 'day':
                translationKey = 'sitter.statistics.lastActivityValueDaysSuffix';
                break;
            case 'week':
                translationKey = 'sitter.statistics.lastActivityValueWeeksSuffix';
                break;
            case 'year':
                translationKey = 'sitter.statistics.lastActivityValueYearsSuffix';
                break;
        }
    }

    return (
        <Statistic
            label={t('sitter.statistics.lastActivity')}
            icon={
                <svg viewBox='0 0 17 17' xmlns='http://www.w3.org/2000/svg'>
                    <g fillRule='nonzero' fill='none'>
                        <path
                            d='M8.5 0C3.813 0 0 3.813 0 8.5 0 13.187 3.813 17 8.5 17c4.687 0 8.5-3.813 8.5-8.5C17 3.813 13.187 0 8.5 0z'
                            fill='#00BF8E'
                        />
                        <path
                            d='M13.29 8.536H9.42V3.732A.721.721 0 0 0 8.709 3 .721.721 0 0 0 8 3.732v5.536c0 .404.318.732.71.732h4.58c.392 0 .71-.328.71-.732a.721.721 0 0 0-.71-.732z'
                            fill='#FFF'
                        />
                    </g>
                </svg>
            }
            value={t(translationKey, {
                amount: time,
            })}
        />
    );
};

export default withTranslation('sitter')(LastActivityStatistic);
