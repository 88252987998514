import {
    EnglishBelgium,
    GermanBelgium,
    FrenchBelgium,
    DutchBelgium,
    EnglishSwitzerland,
    GermanSwitzerland,
    FrenchSwitzerland,
    ItalianSwitzerland,
    GermanLuxembourg,
    FrenchLuxembourg,
    EnglishLuxembourg,
    FrenchCanada,
    EnglishCanada,
    EnglishGermany,
    EnglishFrance,
    FrenchFrance,
    EnglishNetherlands,
    DutchNetherlands,
    GermanAustria,
    EnglishNewZealand,
    EnglishGreatBritain,
    EnglishAustralia,
    EnglishHongKong,
    EnglishSingapore,
    GermanGermany,
    DanishDenmark,
    FinnishFinland,
    EnglishIreland,
    ItalianItaly,
    EnglishJapan,
    NorwegianNorway,
    SwedishSweden,
} from '../Locale/Locale';

const searchPages = new Map([
    [
        GermanAustria,
        [
            {
                name: 'Wien',
                link: 'wien-wien',
            },
            {
                name: 'Graz',
                link: 'graz-steiermark',
            },
            {
                name: 'Salzburg',
                link: 'salzburg-salzburg',
            },
            {
                name: 'Wiener Neustadt',
                link: 'wiener-neustadt-niederoesterreich',
            },
        ],
    ],
    [
        EnglishAustralia,
        [
            {
                name: 'Melbourne',
                link: 'melbourne-vic',
            },
            {
                name: 'Sydney',
                link: 'sydney-nsw',
            },
            {
                name: 'Brisbane',
                link: 'brisbane-qld',
            },
            {
                name: 'Perth',
                link: 'perth-wa',
            },
            {
                name: 'Adelaide',
                link: 'adelaide-sa',
            },
            {
                name: 'Gold Coast',
                link: 'gold-coast-qld',
            },
            {
                name: 'Sunshine Coast',
                link: 'sunshine-coast-qld',
            },
            {
                name: 'Penrith',
                link: 'penrith-nsw-2750',
            },
            {
                name: 'Tweed Heads',
                link: 'tweed-heads-nsw',
            },
            {
                name: 'Mornington',
                link: 'mornington-vic-3931',
            },
        ],
    ],
    [
        GermanBelgium,
        [
            {
                name: 'Schaerbeek/Schaarbeek',
                link: 'schaerbeek-schaarbeek',
            },
            {
                name: 'Brüssel',
                link: 'brussel',
            },
            {
                name: 'Mecheln',
                link: 'mecheln',
            },
            {
                name: 'Antwerpen',
                link: 'antwerpen',
            },
            {
                name: 'Löwen',
                link: 'loewen',
            },
            {
                name: 'Sint-Niklaas',
                link: 'sint-niklaas',
            },
            {
                name: 'Gent',
                link: 'gent',
            },
            {
                name: 'Kortrijk',
                link: 'kortrijk',
            },
            {
                name: 'Aalst',
                link: 'aalst',
            },
            {
                name: 'Genk',
                link: 'genk',
            },
        ],
    ],
    [
        EnglishBelgium,
        [
            {
                name: 'Schaerbeek',
                link: 'schaerbeek',
            },
            {
                name: 'Brussels',
                link: 'brussels',
            },
            {
                name: 'Mechelen',
                link: 'mechelen',
            },
            {
                name: 'Antwerp',
                link: 'antwerp',
            },
            {
                name: 'Leuven',
                link: 'leuven',
            },
            {
                name: 'Sint-Niklaas',
                link: 'sint-niklaas',
            },
            {
                name: 'Ghent',
                link: 'ghent',
            },
            {
                name: 'Kortrijk',
                link: 'kortrijk',
            },
            {
                name: 'Aalst',
                link: 'aalst',
            },
            {
                name: 'Genk',
                link: 'genk',
            },
        ],
    ],
    [
        FrenchBelgium,
        [
            {
                name: 'Bruxelles',
                link: 'bruxelles',
            },
            {
                name: 'Schaerbeek',
                link: 'schaerbeek',
            },
            {
                name: 'Anvers',
                link: 'anvers',
            },
            {
                name: 'Gand',
                link: 'gand',
            },
            {
                name: 'Louvain',
                link: 'louvain',
            },
            {
                name: 'Malines',
                link: 'malines',
            },
            {
                name: 'Hasselt',
                link: 'hasselt',
            },
            {
                name: 'Ostende',
                link: 'ostende',
            },
            {
                name: 'Courtrai',
                link: 'courtrai',
            },
            {
                name: 'Turnhout',
                link: 'turnhout',
            },
        ],
    ],
    [
        DutchBelgium,
        [
            {
                name: 'Schaarbeek',
                link: 'schaarbeek',
            },
            {
                name: 'Brussel',
                link: 'brussel',
            },
            {
                name: 'Mechelen',
                link: 'mechelen',
            },
            {
                name: 'Antwerpen',
                link: 'antwerpen',
            },
            {
                name: 'Leuven',
                link: 'leuven',
            },
            {
                name: 'Sint-Niklaas',
                link: 'sint-niklaas',
            },
            {
                name: 'Gent',
                link: 'gent',
            },
            {
                name: 'Kortrijk',
                link: 'kortrijk',
            },
            {
                name: 'Aalst',
                link: 'aalst',
            },
            {
                name: 'Genk',
                link: 'genk',
            },
        ],
    ],
    [
        EnglishCanada,
        [
            {
                name: 'Montreal',
                link: 'montreal-qc',
            },
            {
                name: 'Ottawa',
                link: 'ottawa-on',
            },
            {
                name: 'Mississauga',
                link: 'mississauga-on',
            },
            {
                name: 'Vancouver',
                link: 'vancouver-bc',
            },
            {
                name: 'Calgary',
                link: 'calgary-ab',
            },
            {
                name: 'Edmonton',
                link: 'edmonton-ab',
            },
            {
                name: 'Kitchener',
                link: 'kitchener-on',
            },
            {
                name: 'Winnipeg',
                link: 'winnipeg-mb',
            },
            {
                name: 'London',
                link: 'london-on',
            },
        ],
    ],
    [
        FrenchCanada,
        [
            {
                name: 'Montréal',
                link: 'montreal-qc',
            },
            {
                name: 'Vancouver',
                link: 'vancouver-bc',
            },
            {
                name: 'Calgary',
                link: 'calgary-ab',
            },
            {
                name: 'Ottawa',
                link: 'ottawa-on',
            },
            {
                name: 'Mississauga',
                link: 'mississauga-on',
            },
        ],
    ],
    [
        FrenchSwitzerland,
        [
            {
                name: 'Lausanne',
                link: 'lausanne-lausanne-vd',
            },
            {
                name: 'Genève',
                link: 'geneve-geneve-ge',
            },
        ],
    ],
    [
        GermanSwitzerland,
        [
            {
                name: 'Genf',
                link: 'genf-geneva-ge',
            },
            {
                name: 'Lausanne',
                link: 'lausanne-bezirk-lausanne-vd',
            },
            {
                name: 'Winterthur',
                link: 'winterthur-winterthur-zh',
            },
            {
                name: 'Zürich',
                link: 'zurich-zurich-zh',
            },
            {
                name: 'Zürich',
                link: 'zuerich-zuerich-zh',
            },
        ],
    ],
    [
        ItalianSwitzerland,
        [
            {
                name: 'Losanna',
                link: '/pensione-per-cani/losanna-losanna-vd',
            },
            {
                name: 'Ginevra',
                link: '/pensione-per-cani/ginevra-geneva-ge',
            },
            {
                name: 'Zurigo',
                link: '/pensione-per-cani/zurigo-distretto-di-zurigo-zh',
            },
            {
                name: 'Winterthur',
                link: '/pensione-per-cani/winterthur-distretto-di-winterthur-zh',
            },
        ],
    ],
    [
        GermanGermany,
        [
            {
                name: 'Berlin',
                link: 'berlin-berlin',
            },
            {
                name: 'München',
                link: 'muenchen-by',
            },
            {
                name: 'Hamburg',
                link: 'hamburg-hh',
            },
            {
                name: 'Köln',
                link: 'koeln-nrw',
            },
            {
                name: 'Potsdam',
                link: 'potsdam-bb',
            },
            {
                name: 'Bochum',
                link: 'bochum-nrw',
            },
            {
                name: 'Düsseldorf',
                link: 'duesseldorf-nrw',
            },
            {
                name: 'Mainz',
                link: 'mainz-rp',
            },
            {
                name: 'Bonn',
                link: 'bonn-nrw',
            },
            {
                name: 'Dortmund',
                link: 'dortmund-nrw',
            },
        ],
    ],
    [
        EnglishGermany,
        [
            {
                name: 'Berlin',
                link: 'berlin-berlin',
            },
            {
                name: 'Munich',
                link: 'munich-by',
            },
            {
                name: 'Hamburg',
                link: 'hamburg-hh',
            },
            {
                name: 'Frankfurt',
                link: 'frankfurt-he',
            },
            {
                name: 'Cologne',
                link: 'cologne-nrw',
            },
            {
                name: 'Potsdam',
                link: 'potsdam-bb',
            },
            {
                name: 'Bochum',
                link: 'bochum-nrw',
            },
            {
                name: 'Düsseldorf',
                link: 'duesseldorf-nrw',
            },
            {
                name: 'Mainz',
                link: 'mainz-rp',
            },
            {
                name: 'Bonn',
                link: 'bonn-nrw',
            },
        ],
    ],
    [
        DanishDenmark,
        [
            {
                name: 'København',
                link: 'kobenhavn',
            },
            {
                name: 'Aarhus',
                link: 'aarhus',
            },
            {
                name: 'Aalborg',
                link: 'aalborg',
            },
            {
                name: 'Roskilde',
                link: 'roskilde',
            },
            {
                name: 'Kolding',
                link: 'kolding',
            },
        ],
    ],
    [
        FinnishFinland,
        [
            {
                name: 'Helsinki',
                link: 'hki',
            },
            {
                name: 'Vantaa',
                link: 'vantaa',
            },
            {
                name: 'Espoo',
                link: 'espoo',
            },
            {
                name: 'Turku',
                link: 'turku',
            },
            {
                name: 'Jyväskylä',
                link: 'jyvaeskylae',
            },
            {
                name: 'Tampere',
                link: 'tampere',
            },
            {
                name: 'Oulu',
                link: 'oulu',
            },
            {
                name: 'Lahti',
                link: 'lahti',
            },
            {
                name: 'Kuopio',
                link: 'kuopio',
            },
        ],
    ],
    [
        FrenchFrance,
        [
            {
                name: 'Lyon',
                link: 'lyon-auvergne-rhone-alpes',
            },
            {
                name: 'Paris',
                link: 'paris-ile-de-france',
            },
            {
                name: 'Montpellier',
                link: 'montpellier-occitanie',
            },
            {
                name: 'Marseille',
                link: 'marseille-provence-alpes-cote-dazur',
            },
            {
                name: 'Boulogne-Billancourt',
                link: 'boulogne-billancourt-ile-de-france',
            },
            {
                name: 'Bordeaux',
                link: 'bordeaux-nouvelle-aquitaine',
            },
            {
                name: 'Strasbourg',
                link: 'strasbourg-grand-est',
            },
            {
                name: 'Nantes',
                link: 'nantes-pays-de-la-loire',
            },
            {
                name: 'Toulouse',
                link: 'toulouse-occitanie',
            },
            {
                name: 'Lille',
                link: 'lille-hauts-de-france',
            },
        ],
    ],
    [
        EnglishFrance,
        [
            {
                name: 'Paris',
                link: 'paris-ile-de-france',
            },
            {
                name: 'Boulogne-Billancourt',
                link: 'boulogne-billancourt-ile-de-france',
            },
            {
                name: 'Rueil-Malmaison',
                link: 'rueil-malmaison-ile-de-france',
            },
            {
                name: 'Lyon',
                link: 'lyon-auvergne-rhone-alpes',
            },
            {
                name: 'Montpellier',
                link: 'montpellier-occitanie',
            },
            {
                name: 'Bordeaux',
                link: 'bordeaux-nouvelle-aquitaine',
            },
            {
                name: 'Strasbourg',
                link: 'strasbourg-grand-est',
            },
            {
                name: 'Lille',
                link: 'lille-hauts-de-france',
            },
            {
                name: 'Marseille',
                link: 'marseille-provence-alpes-cote-dazur',
            },
            {
                name: 'Toulouse',
                link: 'toulouse-occitanie',
            },
        ],
    ],
    [
        EnglishGreatBritain,
        [
            {
                name: 'London',
                link: 'london-greater-london-england',
            },
            {
                name: 'Glasgow',
                link: 'glasgow-glasgow-city-scotland',
            },
            {
                name: 'Edinburgh',
                link: 'edinburgh-edinburgh-scotland',
            },
            {
                name: 'Bristol',
                link: 'bristol-city-of-bristol-england',
            },
            {
                name: 'St Albans',
                link: 'st-albans-hertfordshire-england',
            },
            {
                name: 'Bath',
                link: 'bath-bath-and-north-east-somerset-england',
            },
            {
                name: 'Brighton',
                link: 'brighton-the-city-of-brighton-and-hove-england',
            },
            {
                name: 'Ascot',
                link: 'ascot-windsor-and-maidenhead-england',
            },
            {
                name: 'Manchester',
                link: 'manchester-greater-manchester-england',
            },
            {
                name: 'Leeds',
                link: 'leeds-west-yorkshire-england',
            },
        ],
    ],
    [
        EnglishHongKong,
        [
            {
                name: 'Central',
                link: 'central',
            },
            {
                name: 'Wan Chai',
                link: 'wan-chai',
            },
            {
                name: 'Causeway Bay',
                link: 'causeway-bay',
            },
            {
                name: 'Kowloon',
                link: 'kowloon',
            },
            {
                name: 'Sham Shui Po',
                link: 'sham-shui-po',
            },
            {
                name: 'Chuk Un',
                link: 'chuk-un',
            },
            {
                name: 'Sha Tin',
                link: 'sha-tin',
            },
            {
                name: 'Tung Chung',
                link: 'tung-chung',
            },
            {
                name: 'Sai Kung',
                link: 'sai-kung',
            },
            {
                name: 'Yuen Long Kau Hui',
                link: 'yuen-long-kau-hui',
            },
        ],
    ],
    [
        EnglishIreland,
        [
            {
                name: 'Dublin',
                link: 'dublin-county-dublin',
            },
            {
                name: 'Cork',
                link: 'cork-county-cork',
            },
            {
                name: 'Galway',
                link: 'county-galway',
            },
        ],
    ],
    [
        ItalianItaly,
        [
            {
                name: 'Milano',
                link: 'milano-lombardia',
            },
            {
                name: 'Torino',
                link: 'torino-piemonte',
            },
            {
                name: 'Roma',
                link: 'roma-lazio',
            },
            {
                name: 'Bologna',
                link: 'bologna-emilia-romagna',
            },
            {
                name: 'Padova',
                link: 'padova-veneto',
            },
            {
                name: 'Genova',
                link: 'genova-liguria',
            },
            {
                name: 'Bergamo',
                link: 'bergamo-lombardia',
            },
            {
                name: 'Firenze',
                link: 'firenze-toscana',
            },
            {
                name: 'Parma',
                link: 'parma-emilia-romagna',
            },
            {
                name: 'Como',
                link: 'como-lombardia',
            },
        ],
    ],
    [EnglishJapan, []],
    [
        GermanLuxembourg,
        [
            {
                name: 'Luxembourg City',
                link: 'luxembourg-city',
            },
            {
                name: 'Esch-sur-Alzette',
                link: 'esch-an-der-alzette',
            },
            {
                name: 'Differdange',
                link: 'differdingen',
            },
            {
                name: 'Dudelange',
                link: 'dudelingen',
            },
        ],
    ],
    [
        EnglishLuxembourg,
        [
            {
                name: 'Luxembourg City',
                link: 'luxembourg-city',
            },
            {
                name: 'Esch-sur-Alzette',
                link: 'esch-sur-alzette',
            },
            {
                name: 'Differdange',
                link: 'differdange',
            },
            {
                name: 'Dudelange',
                link: 'dudelange',
            },
        ],
    ],
    [
        FrenchLuxembourg,
        [
            {
                name: 'Luxembourg City',
                link: 'luxembourg-city',
            },
            {
                name: 'Esch-sur-Alzette',
                link: 'esch-sur-alzette',
            },
            {
                name: 'Differdange',
                link: 'differdange',
            },
            {
                name: 'Dudelange',
                link: 'dudelange',
            },
        ],
    ],
    [
        DutchNetherlands,
        [
            {
                name: 'Amstelveen',
                link: 'amstelveen',
            },
            {
                name: 'Amsterdam',
                link: 'amsterdam',
            },
            {
                name: 'Haarlem',
                link: 'haarlem',
            },
            {
                name: 'Den Haag',
                link: 'den-haag',
            },
            {
                name: 'Hilversum',
                link: 'hilversum',
            },
            {
                name: 'Rotterdam',
                link: 'rotterdam',
            },
            {
                name: 'Utrecht',
                link: 'utrecht',
            },
            {
                name: 'Almere',
                link: 'almere',
            },
            {
                name: 'Eindhoven',
                link: 'eindhoven',
            },
            {
                name: 'Amersfoort',
                link: 'amersfoort',
            },
        ],
    ],
    [
        EnglishNetherlands,
        [
            {
                name: 'Amstelveen',
                link: 'amstelveen',
            },
            {
                name: 'Amsterdam',
                link: 'amsterdam',
            },
            {
                name: 'Haarlem',
                link: 'haarlem',
            },
            {
                name: 'The Hague',
                link: 'the-hague',
            },
            {
                name: 'Hilversum',
                link: 'hilversum',
            },
            {
                name: 'Rotterdam',
                link: 'rotterdam',
            },
            {
                name: 'Utrecht',
                link: 'utrecht',
            },
            {
                name: 'Almere',
                link: 'almere',
            },
            {
                name: 'Eindhoven',
                link: 'eindhoven',
            },
            {
                name: 'Amersfoort',
                link: 'amersfoort',
            },
        ],
    ],
    [
        NorwegianNorway,
        [
            {
                name: 'Oslo',
                link: 'oslo',
            },
            {
                name: 'Fornebu',
                link: 'fornebu',
            },
            {
                name: 'Bergen',
                link: 'bergen',
            },
            {
                name: 'Drammen',
                link: 'drammen',
            },
            {
                name: 'Stavanger',
                link: 'stavanger',
            },
        ],
    ],
    [
        EnglishNewZealand,
        [
            {
                name: 'Auckland',
                link: 'auckland-auckland',
            },
            {
                name: 'Christchurch',
                link: 'christchurch-canterbury',
            },
            {
                name: 'Hamilton',
                link: 'hamilton-waikato',
            },
            {
                name: 'Wellington',
                link: 'wellington-wellington',
            },
        ],
    ],
    [
        SwedishSweden,
        [
            {
                name: 'Lund',
                link: 'lund',
            },
            {
                name: 'Malmö',
                link: 'malmoe',
            },
            {
                name: 'Stockholm',
                link: 'stockholms-laen',
            },
            {
                name: 'Upplands Väsby',
                link: 'upplands-vaesby',
            },
            {
                name: 'Norrköping',
                link: 'norrkoeping',
            },
            {
                name: 'Göteborg',
                link: 'goeteborg',
            },
        ],
    ],
    [
        EnglishSingapore,
        [
            {
                name: 'Singapore',
                link: 'singapore',
            },
            {
                name: 'Hougang',
                link: 'hougang-singapore',
            },
            {
                name: 'Ang Mo Kio',
                link: 'ang-mo-kio-singapore',
            },
            {
                name: 'Tampines',
                link: 'tampines-singapore',
            },
            {
                name: 'Toa Payoh',
                link: 'toa-payoh-singapore',
            },
            {
                name: 'Marine Parade',
                link: 'marine-parade-singapore',
            },
            {
                name: 'Clementi',
                link: 'clementi-singapore',
            },
            {
                name: 'Jurong West',
                link: 'jurong-west-singapore',
            },
            {
                name: 'Woodlands',
                link: 'woodlands-singapore',
            },
            {
                name: 'Bedok',
                link: 'bedok-singapore',
            },
        ],
    ],
]);

export const getSearchPages = (locale) => {
    return searchPages.get(locale);
};

const pages = new Map([
    [
        GermanBelgium,
        {
            terms: '/allgemeine-geschaeftsbedingungen',
            privacy: '/datenschutzerklärung-und-erklärung-zur-verwendung-von-cookies',
        },
    ],
    [
        EnglishBelgium,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        DutchBelgium,
        {
            terms: '/gebruikersvoorwaarden',
            privacy: '/privacy-en-cookiesbeleid',
        },
    ],
    [
        FrenchBelgium,
        {
            terms: '/pawshake-conditions-générales',
            privacy: '/règlement-sur-le-respect-de-la-vie-privée',
        },
    ],
    [
        GermanSwitzerland,
        {
            terms: '/allgemeine-geschaeftsbedingungen',
            privacy: '/datenschutzerklärung-und-erklärung-zur-verwendung-von-cookies',
        },
    ],
    [
        FrenchSwitzerland,
        {
            terms: '/pawshake-conditions-générales',
            privacy: '/règlement-sur-le-respect-de-la-vie-privée',
        },
    ],
    [
        ItalianSwitzerland,
        {
            terms: '/accordo-gli-utenti',
            privacy: '/regole-sulla-privacy-e-cookie',
        },
    ],
    [
        GermanLuxembourg,
        {
            terms: '/allgemeine-geschaeftsbedingungen',
            privacy: '/datenschutzerklärung-und-erklärung-zur-verwendung-von-cookies',
        },
    ],
    [
        EnglishLuxembourg,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/node/5338',
        },
    ],
    [
        FrenchLuxembourg,
        {
            terms: '/pawshake-conditions-générales',
            privacy: '/règlement-sur-le-respect-de-la-vie-privée',
        },
    ],
    [
        EnglishCanada,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        FrenchCanada,
        {
            terms: '/pawshake-conditions-générales',
            privacy: '/règlement-sur-le-respect-de-la-vie-privée',
        },
    ],
    [
        EnglishGermany,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        GermanGermany,
        {
            terms: '/allgemeine-geschaeftsbedingungen',
            privacy: '/datenschutzerklärung-und-erklärung-zur-verwendung-von-cookies',
        },
    ],
    [
        EnglishFrance,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        FrenchFrance,
        {
            terms: '/pawshake-conditions-générales',
            privacy: '/règlement-sur-le-respect-de-la-vie-privée',
        },
    ],
    [
        EnglishNetherlands,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        DutchNetherlands,
        {
            terms: '/gebruikersvoorwaarden',
            privacy: '/privacy-en-cookiesbeleid',
        },
    ],
    [
        GermanAustria,
        {
            terms: '/allgemeine-geschaeftsbedingungen',
            privacy: '/datenschutzerklärung-und-erklärung-zur-verwendung-von-cookies',
        },
    ],
    [
        EnglishNewZealand,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        EnglishGreatBritain,
        {
            charity: '/charity',

            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        EnglishAustralia,
        {
            charity: '/charity',

            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        EnglishHongKong,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        EnglishSingapore,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        DanishDenmark,
        {
            terms: '/vilkår-brug-af-pawshake',
            privacy: '/cookie-og-privatlivspolitik',
        },
    ],
    [
        FinnishFinland,
        {
            terms: '/käyttäjäsopimus',
            privacy: '/yksityisyys-ja-eväste-ehdot',
        },
    ],
    [
        EnglishIreland,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        ItalianItaly,
        {
            terms: '/accordo-gli-utenti',
            privacy: '/regole-sulla-privacy-e-cookie',
        },
    ],
    [
        EnglishJapan,
        {
            terms: '/pawshake-terms-conditions',
            privacy: '/privacy-cookie-policy',
        },
    ],
    [
        NorwegianNorway,
        {
            terms: '/vilkår',
            privacy: '/personvernpolicy',
        },
    ],
    [
        SwedishSweden,
        {
            terms: '/allmänna-villkor',
            privacy: '/sekretesspolicy',
        },
    ],
]);

export const getPages = (locale) => {
    return pages.get(locale);
};

const userAgreementPages = new Map([
    [EnglishBelgium, 5330],
    [GermanBelgium, 114],
    [FrenchBelgium, 166],
    [DutchBelgium, 114],
    [EnglishSwitzerland, 1],
    [GermanSwitzerland, 114],
    [FrenchSwitzerland, 4277],
    [ItalianSwitzerland, 24936],
    [GermanLuxembourg, 114],
    [FrenchLuxembourg, 4275],
    [EnglishLuxembourg, 5331],
    [FrenchCanada, 4278],
    [EnglishCanada, 5336],
    [EnglishGermany, 166],
    [EnglishFrance, 166],
    [FrenchFrance, 2718],
    [EnglishNetherlands, 5333],
    [DutchNetherlands, 2717],
    [GermanAustria, 114],
    [EnglishNewZealand, 16850],
    [EnglishGreatBritain, 5332],
    [EnglishAustralia, 16849],
    [EnglishHongKong, 16848],
    [EnglishSingapore, 16851],
    [GermanGermany, 5592],
    [DanishDenmark, 24944],
    [FinnishFinland, 24939],
    [EnglishIreland, 16847],
    [ItalianItaly, 24937],
    [EnglishJapan, 24937],
    [NorwegianNorway, 24935],
    [SwedishSweden, 5332],
]);

export const getUserAgreementPage = (locale) => {
    return userAgreementPages.get(locale);
};

const privacyAndCookiePages = new Map([
    [EnglishBelgium, 5337],
    [GermanBelgium, 117],
    [FrenchBelgium, 167],
    [DutchBelgium, 117],
    [EnglishSwitzerland, 1],
    [GermanSwitzerland, 117],
    [FrenchSwitzerland, 5366],
    [ItalianSwitzerland, 24952],
    [GermanLuxembourg, 117],
    [FrenchLuxembourg, 4280],
    [EnglishLuxembourg, 5338],
    [FrenchCanada, 5365],
    [EnglishCanada, 5345],
    [EnglishGermany, 167],
    [EnglishFrance, 167],
    [FrenchFrance, 2721],
    [EnglishNetherlands, 5346],
    [DutchNetherlands, 2720],
    [GermanAustria, 117],
    [EnglishNewZealand, 16852],
    [EnglishGreatBritain, 5339],
    [EnglishAustralia, 16854],
    [EnglishHongKong, 16853],
    [EnglishSingapore, 16855],
    [GermanGermany, 5598],
    [DanishDenmark, 24946],
    [FinnishFinland, 24947],
    [EnglishIreland, 16856],
    [ItalianItaly, 24949],
    [EnglishJapan, 24949],
    [NorwegianNorway, 24953],
    [SwedishSweden, 5339],
]);

export const getPrivacyAndCookiePage = (locale) => {
    return privacyAndCookiePages.get(locale);
};
