import React from 'react';
import { SkillIconWithLabel } from '../styles/SkillIconWithLabel';
import { withTranslation } from '../../../../i18n';

const SpeakLanguages = ({ t, languages }) => {
    const value = languages.map((language) => t('common:languages.' + language.toLowerCase())).join(' & ');

    return (
        <SkillIconWithLabel
            label={t('sitter.skills.specificSkills.languages', {
                languages: value,
            })}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" viewBox="0 0 62.500001 62.500002" xml:space="preserve" width="50pt" height="50pt"><g id="g3" transform="translate(-34 -353.811)"><g id="g5" fill="none" stroke="#000" stroke-width="1.875" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"><path d="M95.562 391.147c0 4.38-2.586 8.553-7.134 11.432a2.55 2.55 0 0 0-1.127 2.545l1.085 6.092-9.012-4.84a2.564 2.564 0 0 0-1.46-.292c-.96.083-1.962.167-2.88.167-10.306 0-18.86-5.633-20.278-12.935h.668c12.6 0 23.073-7.26 25.118-16.731 8.804 1.836 15.02 7.802 15.02 14.562z" id="path4173"/><path d="M55.466 358.101c11.307 0 20.486 6.76 20.486 15.104 0 8.345-9.18 15.104-20.486 15.104-.918 0-1.92-.041-2.88-.166a2.565 2.565 0 0 0-1.46.292l-9.012 4.84 1.085-6.092c.167-1.002-.25-2.003-1.127-2.545-4.548-2.88-7.135-7.052-7.135-11.433.042-8.303 9.222-15.104 20.529-15.104z" id="path7"/></g></g></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(SpeakLanguages);
