import React, { createContext } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const TrackingServiceContext = createContext({});

export default function withTrackingService(WrappedComponent) {
    const TrackingServiceComponent = function TrackingServiceComponent(props) {
        return (
            <TrackingServiceContext.Consumer>
                {(trackingService) => <WrappedComponent {...props} trackingService={trackingService} />}
            </TrackingServiceContext.Consumer>
        );
    };
    hoistNonReactStatics(TrackingServiceComponent, WrappedComponent);
    return TrackingServiceComponent;
}
