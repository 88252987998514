import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

const List = styled.ul`
    list-style: none;
    padding: 0 1rem 0 0;
    display: block;
`;

const NormalLink = styled.a``;

const ActiveLink = styled.a`
    font-weight: bold;
`;

const renderLink = (activePath, link) => {
    let LinkRender = NormalLink;

    if (typeof link.as === 'undefined') {
        if (link.href === activePath) {
            LinkRender = ActiveLink;
        }
    } else {
        if (link.as === activePath) {
            LinkRender = ActiveLink;
        }
    }

    if (link.as) {
        return (
            <Link href={link.href} as={link.as} key={link.as} passHref={true}>
                <LinkRender title={link.title ? link.title : link.text}>{link.text}</LinkRender>
            </Link>
        );
    }

    return (
        <LinkRender
            href={link.href}
            key={link.href}
            title={link.title ? link.title : link.text}
            target={link.target ? link.target : undefined}
            rel={link.rel ? link.rel : undefined}
            onClick={link.onClick ? link.onClick : undefined}
        >
            {link.text}
        </LinkRender>
    );
};

const UnorderedHyperlinkList = ({ items, onClick, className, activePath }) => {
    return (
        <List onClick={onClick} className={className}>
            {items.map((item, index) => (
                <li key={index}>{renderLink(activePath, item)}</li>
            ))}
        </List>
    );
};

export default UnorderedHyperlinkList;
