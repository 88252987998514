import React from 'react';
import { SkillIconWithLabel } from '../styles/SkillIconWithLabel';
import { withTranslation } from '../../../../i18n';

const FirstAidAndCPR = ({ t }) => {
    return (
        <SkillIconWithLabel
            label={t('sitter.skills.administer.firstAidAndCPR')}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" width="50pt" height="50pt" viewBox="0 0 62.5 62.500002" xml:space="preserve"><g id="g3" transform="matrix(.126 0 0 .126 -1.004 -1.521)" fill="none" stroke="#000" stroke-width="14.882" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"><path d="M462.222 148.915c18.964 0 34.37 15.407 34.37 34.37V458.25c0 18.963-15.406 34.37-34.37 34.37H49.778c-18.948 0-34.37-15.407-34.37-34.37V183.285c0-18.963 15.422-34.37 34.37-34.37h412.444" id="path4175"/><path d="M363.652 108.33V81.417c0-29.726-24.1-53.826-53.826-53.826H202.174c-29.725 0-53.826 24.1-53.826 53.826v26.913" id="path4171"/><path d="M352 288h-64v-64h-64v64h-64v64h64v64h64v-64h64v-64z" id="path5"/></g></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(FirstAidAndCPR);
