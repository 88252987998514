import Immutable from 'immutable';
import { createSelector } from 'reselect';

export const selectRoot = (state) => state.getIn(['domain', 'user', 'authentication'], new Immutable.Map());

export const isJwtAuthenticated = createSelector(selectRoot, (root) => typeof root.get('jwtToken') !== 'undefined');

export const isAuthenticatedButWithoutPawshakeAccount = createSelector(
    selectRoot,
    (root) => root.get('isFirebaseAuthenticated') && typeof root.get('jwtToken') === 'undefined'
);

export const isJwtAuthenticatedLoading = createSelector(selectRoot, (root) => root.get('jwtTokenLoading'));

export const jwtTokenSelect = createSelector(selectRoot, (root) => {
    return root.get('jwtToken');
});

const selectDecodedJwtToken = createSelector(selectRoot, (root) => root.get('jwtTokenDecoded'));

export const selectJwtTokenFirstName = createSelector(selectDecodedJwtToken, (decoded) => decoded.get('fna'));

export const selectJwtTokenShouldCompleteProfile = createSelector(
    selectDecodedJwtToken,
    (decoded) => decoded.get('samprbb') ?? decoded.get('svcpn')
);

export const selectJwtTokenLocale = createSelector(selectDecodedJwtToken, (decoded) => decoded.get('loc'));

export const selectJwtTokenCountry = createSelector(selectDecodedJwtToken, (decoded) => decoded.get('cc'));

export const selectJwtTokenAvatar = createSelector(
    selectDecodedJwtToken,
    (decoded) => decoded.get('ava') || 'https://static1.pawshakecdn.com/icons/icon-placeholder-user.png'
);

export const selectJwtTokenSitterId = createSelector(selectDecodedJwtToken, (decoded) => decoded.get('sid'));

export const selectJwtTokenUserId = createSelector(selectDecodedJwtToken, (decoded) => decoded.get('sub'));

export const selectIsPrivilegedSitter = createSelector(selectDecodedJwtToken, (decoded) => decoded.get('ips') ?? false);

export const selectHasACompletedSitterApplication = createSelector(
    selectDecodedJwtToken,
    (decoded) => decoded.get('hacss') ?? true
);

export const selectJwtTokenBelongsToASitter = createSelector(
    selectDecodedJwtToken,
    (decoded) => decoded.get('is') ?? false
);

export const selectEnrichedUsers = createSelector(selectRoot, (root) => root.get('enriched'));
