import React from 'react';
import AcceptedPet from '../../../Shared/Pets/AcceptedPet';
import { withTranslation } from '../../../../i18n';

const Cats = ({ t }) => {
    return (
        <AcceptedPet
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0" y="0" viewBox="0 0 62.5 62.5" xml:space="preserve"><path d="M40.383.938s-1.715 0-3.43 5.023c0 0-6.248 1.102-5.024 8.82-1.96 6.738-9.555 4.165-13.598 12.863-4.043 8.698-2.083 16.294-.736 19.479.156 1.654-3.839 3.09-1.678 9.126 2.74 7.656 19.932 5.9 20.422 1.838 0 0-11.598 2.47-16.6-1.776 0 0-2.022-1.96-.797-4.655.613-1.103 12.313 1.653 17.274-.55 0 0 1.286-2.45-2.756-2.45 0 0 .613-1.287 1.102-2.756.49-1.47.613 5.574 5.39 5.574 0 0 2.696.306 2.145-2.145 0 0 1.96-2.328-1.715-2.328 0 0-1.43-4.859-.286-12.863.163-4.083 4.246-3.756 4.246-9.719 0-5.963-1.225-8.004-1.225-8.004s3.333-1.122 4.042-4.471c.09-.428.095-1.878-.94-1.899 0 0-1.715-3.593-5.063-3.593.003-.003.388-5.505-.773-5.514z" id="path3" fill="none" fill-opacity="1" stroke="#000" stroke-width="1.875" stroke-linejoin="round" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></svg>'
            }
            label={t('sitter.aboutHome.acceptedPets.cats.label')}
            description={t('sitter.aboutHome.acceptedPets.cats.description')}
        />
    );
};

export default withTranslation('sitter')(Cats);
