import React from 'react';
import { HomeIconWithLabel } from './styles/HomeIconWithLabel';
import { withTranslation } from '../../../i18n';

const Has247Supervision = ({ t }) => {
    return (
        <HomeIconWithLabel
            label={t('sitter.aboutHome.home.features.has247Supervision')}
            inline={true}
            icon={
                '<svg width="22" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M9.417 12.804H5.891v-.612L7.598 10.3c.144-.161.263-.304.357-.428.096-.127.172-.242.227-.345a1.196 1.196 0 0 0 .155-.564 1.01 1.01 0 0 0-.054-.337.744.744 0 0 0-.152-.27.654.654 0 0 0-.241-.176.789.789 0 0 0-.331-.066.992.992 0 0 0-.397.073.76.76 0 0 0-.277.205.892.892 0 0 0-.166.311c-.036.12-.054.252-.054.396H5.79c0-.235.038-.454.115-.66.08-.207.195-.388.346-.541.151-.154.337-.275.558-.363.221-.09.473-.135.757-.135.262 0 .495.035.699.106.204.068.375.166.515.293.141.127.248.282.32.465.075.18.112.382.112.604a1.6 1.6 0 0 1-.08.494 2.534 2.534 0 0 1-.219.484 4.119 4.119 0 0 1-.335.487c-.13.161-.271.323-.425.487l-1.17 1.274h2.434v.71zM13.045 10.903h.652v.714h-.652v1.187h-.876v-1.187H9.904l-.026-.542 2.262-3.603h.905v3.431zm-2.248 0h1.372V8.677l-.065.117-1.307 2.11z" fill="#00C38A" fill-rule="nonzero"/><path d="M18.788 10.776c.337-3.71-1.892-7.475-5.278-8.905-1.508-.574-3.593-.791-4.947-.585-3.367.548-6.223 3.301-6.994 6.67-.886 3.47.544 7.39 3.445 9.43.969.689 2.075 1.198 3.212 1.516 1.084.232.858-.312-.145-.785-1.982-.82-3.794-2.236-4.723-4.238-1.567-3.144-.688-7.326 2.08-9.477 2.444-2.008 6.218-2.055 8.675-.049 1.881 1.493 2.988 4 2.65 6.424h-1.985l2.907 3.891 3.155-3.891h-2.052z" stroke="#00C38A"/></g></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(Has247Supervision);
