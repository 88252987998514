import styled from 'styled-components';
import Button from './Button';

export const FacebookButton = styled(Button)`
    border: 1px solid #37599d;
    border-radius: 4px;
    background-color: #37599d;
    touch-action: manipulation;
    display: block;
    position: relative;
    width: 100%;
    padding: 10px;
    color: white;
    cursor: pointer;
    box-sizing: border-box;

    &:before {
        position: absolute;
        width: 50px;
        height: 40px;
        content: '';
        top: 5px;
        left: 5px;
        border-right: 1px solid white;
        background: url('https://static1.pawshakecdn.com/icons/icon-facebook.svg') no-repeat center center;
    }
`;

export const HollowBlackButton = styled(Button)`
    border-radius: 4px;
    touch-action: manipulation;
    display: block;
    box-sizing: border-box;
    cursor: pointer;
    background-color: white;
    border: 1px solid #cecece;
    color: #4f4f4f;
`;

export const HollowBlueButton = styled(Button)`
    border-radius: 4px;
    touch-action: manipulation;
    display: block;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: white;
    border: 1px solid #00afed;
    color: #00afed;
    padding: 10px;
`;

export const InlineGreenButton = styled(Button)`
    border: 1px solid #00c38a;
    border-radius: 4px;
    background-color: #00c38a;
    touch-action: manipulation;
    display: block;
    width: 100%;
    color: white;
    cursor: pointer;
    box-sizing: border-box;

    :disabled {
        background-color: #cecece;
        border-color: #f4f4f4;
    }
`;

export const HollowGreenButton = styled(Button)`
    border-radius: 4px;
    touch-action: manipulation;
    display: block;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: white;
    color: #00c38a;
    border: 1px solid #00c38a;
    padding: 10px;
`;

export const ColoredButton = styled(Button)`
    border: 1px solid ${(props) => props.color};
    border-radius: 0.5rem;
    background-color: ${(props) => props.color};
    touch-action: manipulation;
    display: block;
    width: 100%;
    color: white;
    cursor: pointer;
    box-sizing: border-box;

    :disabled {
        background-color: #cecece;
        border-color: #f4f4f4;
    }
    padding: 10px;
`;

export const GreenButton = styled(Button)`
    border: 1px solid #00c38a;
    border-radius: 4px;
    background-color: #00c38a;
    touch-action: manipulation;
    display: block;
    width: 100%;
    color: white;
    cursor: pointer;
    box-sizing: border-box;

    :hover {
        text-decoration: underline;
    }

    :disabled {
        background-color: #a0e8d5;
        border-color: #f4f4f4;
    }
    padding: 10px;
`;

export const RedButton = styled(Button)`
    border: 1px solid #eb4c52;
    border-radius: 4px;
    background-color: #eb4c52;
    touch-action: manipulation;
    text-align: center;
    display: block;
    width: 100%;
    color: white;
    cursor: pointer;
    box-sizing: border-box;

    :disabled {
        background-color: #cecece;
        border-color: #f4f4f4;
    }
    padding: 10px;
`;
