import React from 'react';
import styled from 'styled-components';
import { DOG_TRAINING, GROOMING, PICKUP_DROP_OFF } from '../../Pawshake/Sitter/BusinessDetails/AdditionalOffer';
import DogTraining from './AdditionalOffers/DogTraining';
import DogGrooming from './AdditionalOffers/DogGrooming';
import PickupDropOff from './AdditionalOffers/PickupDropOff';
import { withTranslation } from '../../i18n';

const Title = styled.h3`
    margin-bottom: 1rem;
    color: #5c5c5c;
`;

const SitterAdditionalOffers = ({ t, name, offers }) => {
    return (
        <>
            <Title>{t('sitter.additionalOffers.title', { name })}</Title>
            {offers.includes(DOG_TRAINING) && <DogTraining />}
            {offers.includes(GROOMING) && <DogGrooming />}
            {offers.includes(PICKUP_DROP_OFF) && <PickupDropOff />}
        </>
    );
};

export default withTranslation('sitter')(SitterAdditionalOffers);
