import React from 'react';
import AcceptedPet from '../../../Shared/Pets/AcceptedPet';
import { withTranslation } from '../../../../i18n';

const DogAges = ({ t, min, max }) => {
    return (
        <AcceptedPet
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0" y="0" viewBox="0 0 62.5 62.5" xml:space="preserve"><path id="polygon3" transform="matrix(.13508 0 0 .13508 -3.307 -3.267)" clip-rule="evenodd" fill="none" fill-rule="evenodd" stroke="#000" stroke-width="13.881" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1" d="M317.615 425.552l17.615-88.113 7.26 102.768 21.135 21.709 21.875-.018L393.438 449l-14.303-11.397-7.285-14.201 14.197-229.916 16.133-10.92 12.322-.525 29.918 9.348 35.992-15.722-4.205-30.778-32.256-19.037-26.633-26.541-12.873-46.493-9.754-2.734-86.166 99.115-36.347 23.453-35.846 5.069-152.455-.143-17.936-12.996-13.845-52.644-15.516-6.202-4.992 9.713 3.369 34.012 18.955 51.32.109.803-17.574 50.139 11.645 62.027-1.924 40.822-6.225 21.907-.386 58.908 11.634 26.506 29.217.023 10.229-9.629-16.248-17.533-4.75-29.465 13.408-41.641 3.982-6.128 1.522.908.732 38.32 14.526 44.846L114.5 457.5l31.699.107 5.301-10.273-20.167-21.667-2.103-50.28 19.838-54.762 13.813-22.648 19.709-2.645 108.314 20.238 4.528 16.787-10.403 101.382 14.336 23.93 24.26-.002 13.195-9.725-2.955-14.14z"/></svg>'
            }
            label={t('sitter.aboutHome.acceptedPets.dogs.ages.label')}
            description={t('sitter.aboutHome.acceptedPets.dogs.ages.description', {
                max: max,
                min: min,
            })}
        />
    );
};

export default withTranslation('sitter')(DogAges);
