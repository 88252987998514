import React from 'react';
import styled from 'styled-components';

const SitterName = styled.h2`
    margin-top: 1rem;
    line-height: normal;
    margin-right: 1rem;
`;

const PlaceName = styled.span`
    margin-top: 1rem;
    margin-right: 1rem;
`;

const SitterInfo = ({ sitterName, placeName }) => {
    return (
        <>
            <SitterName>{sitterName}</SitterName>
            <PlaceName>{placeName}</PlaceName>
        </>
    );
};

export default SitterInfo;
