import styled from 'styled-components';
import { withTranslation } from '../../i18n';
import EmailVerified from './Statistics/EmailVerified';
import FacebookVerified from './Statistics/FacebookVerified';
import PhoneInterviewed from './Statistics/PhoneInterviewed';
import PoliceCheck from './Statistics/PoliceCheck';
import SMSVerified from './Statistics/SMSVerified';

const InlineStatistics = styled.div`
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
`;

const SitterVerifications = ({
    isPhoneInterviewed,
    isFacebookVerified,
    isTextMessageVerified,
    isEmailVerified,
    policeCheck,
    className,
}) => {
    return (
        <InlineStatistics className={className}>
            {policeCheck.hasOne && <PoliceCheck lastSpecifiedOn={policeCheck.lastSpecifiedOn} />}
            {isPhoneInterviewed && <PhoneInterviewed />}
            {isFacebookVerified && <FacebookVerified />}
            {isTextMessageVerified && <SMSVerified />}
            {isEmailVerified && <EmailVerified />}
        </InlineStatistics>
    );
};

export default withTranslation('sitter')(SitterVerifications);
