import React from 'react';
import styled from 'styled-components';
import { Trans, withTranslation } from '../../../i18n';

const Text = styled.p`
    color: #acacac;
    a {
        line-height: 1rem;
    }
`;

const Insurance = ({ className }) => {
    const link = '/pawshake-guarantee';

    return (
        <Text className={className}>
            <Trans i18nKey='finance:insurance'>
                Book via Pawshake to enjoy <a href={link}>premium insurance</a>, 24/7 support, booking guarantee, safe
                cashless payments, photo updates and more!
            </Trans>
        </Text>
    );
};

export default withTranslation('finance')(Insurance);
