import React from 'react';
import styled from 'styled-components';
import StarRating from '../StarRating/StarRating';

const Reviews = styled.span`
    color: #fab660;
`;

const ReviewsLink = styled.a`
    color: #fab660;
    &:hover {
        text-decoration: underline;
    }
`;

const StarRatingBasedUponReviews = ({ rating, reviews, className, addReviewsLink }) => {
    return (
        <div className={className}>
            <StarRating rating={rating} />
            {addReviewsLink ? <ReviewsLink href={'#reviews'}>{reviews}</ReviewsLink> : <Reviews>{reviews}</Reviews>}
        </div>
    );
};

export default StarRatingBasedUponReviews;
