import { trackEvent } from '../../lib/analytics';

const trackFavoritesEvent = (action) => {
    trackEvent('Favorites', action);
};

export const trackAnonymousClicks = () => {
    trackFavoritesEvent('Anonymous - Click');
};

export const trackToggleFavoriteClicks = (isFavorite) => {
    trackFavoritesEvent(`${isFavorite ? 'Favorite' : 'UnFavorite'} - Click`);
};
