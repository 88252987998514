import PropTypes from 'prop-types';
import styled from 'styled-components';

const Link = styled.a`
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
`;

const Crumb = (props) => {
    const { link, title, name, noFollow } = props;

    return (
        <li>
            <Link href={link} rel={noFollow ? 'nofollow' : undefined} title={title ? title : name}>
                {name}
            </Link>
        </li>
    );
};

Crumb.defaultProps = {
    noFollow: false,
};

Crumb.propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    noFollow: PropTypes.bool,
};

export default Crumb;
