import React from 'react';
import styled from 'styled-components';
import { withTranslation } from '../../i18n';
import StarRating from '../Shared/StarRating/StarRating';

const Item = styled.div`
    margin-bottom: 0.5rem;
`;

const SitterRatings = ({ t, value, communication, profileCorrectness, accuracy, animalTreatment }) => {
    return (
        <>
            <Item>
                <StarRating rating={communication} />
                {t('sitter.reviews.communication')}
            </Item>
            <Item>
                <StarRating rating={profileCorrectness} />
                {t('sitter.reviews.conscientious')}
            </Item>
            <Item>
                <StarRating rating={accuracy} />
                {t('sitter.reviews.profileAccuracy')}
            </Item>
            <Item>
                <StarRating rating={animalTreatment} />
                {t('sitter.reviews.treatmentOfAnimals')}
            </Item>
            <Item>
                <StarRating rating={value} />
                {t('sitter.reviews.value')}
            </Item>
        </>
    );
};

export default withTranslation('sitter')(SitterRatings);
