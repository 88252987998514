import React from 'react';
import SubTitle from '../../../Infrastructure/Shared/Titles/SubTitle';
import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import { getServiceSlugInLocale } from '../../Search/ServiceSlugs';
import { withTranslation } from '../../../i18n';
import { getAllSeoServices } from '../../../Pawshake/Core/Service/SeoService';
import UnorderedHyperlinkList from '../../../Infrastructure/Shared/UnorderedHyperlinkList/UnorderedHyperlinkList';

const LearnMore = ({ t, application }) => {
    const { zendeskLanguage, locale, domain } = application;

    let items = [
        {
            text: t('footer.categories.learnMore.howItWorks'),
            href: `/howDoesPawshakeWork?localeString=${locale.toString()}`,
            as: '/how-does-pawshake-work',
        },
        {
            text: t('footer.categories.learnMore.testimonials'),
            href: `/reviews/overview?localeString=${locale.toString()}`,
            as: '/customer-reviews',
        },
        {
            text: t('footer.categories.learnMore.insurance'),
            href: `/guarantees/pawshake?localeString=${locale.toString()}`,
            as: '/pawshake-guarantee',
        },
        {
            text: t('footer.categories.learnMore.paymentPolicy'),
            href: `/paymentPolicy?localeString=${locale.toString()}`,
            as: '/payment-policy',
        },
        {
            text: t('footer.categories.learnMore.faqPetOwners'),
            href: `https://pawshake.zendesk.com/hc/${zendeskLanguage}?c=pet-owner`,
        },
        {
            text: t('footer.categories.learnMore.faqPetSitters'),
            href: `https://pawshake.zendesk.com/hc/${zendeskLanguage}?c=pet-sitter`,
        },
    ];

    getAllSeoServices(locale).forEach((service) => {
        const serviceSlug = getServiceSlugInLocale(service, locale);
        items.push({
            text: t(`footer.categories.services.${service.id}`),
            href: `${domain}/${serviceSlug}`,
        });
    });

    return (
        <>
            <SubTitle>{t('footer.categories.learnMore.title')}</SubTitle>
            <UnorderedHyperlinkList items={items} />
        </>
    );
};

export default withApplicationAwareness(withTranslation('footer')(LearnMore));
