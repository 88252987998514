export const PUPPY = 'puppy';
export const YOUNG = 'young';
export const ADULT = 'adult';
export const SENIOR = 'senior';

export const dogAges = {
    puppy: { min: 0, max: 1 },
    young: { min: 1, max: 3 },
    adult: { min: 3, max: 8 },
    senior: { min: 8, max: 20 },
};
