import React from 'react';
import { SkillIconWithLabel } from '../styles/SkillIconWithLabel';
import { withTranslation } from '../../../../i18n';

const RescuingPets = ({ t }) => {
    return (
        <SkillIconWithLabel
            label={t('sitter.skills.experience.rescuingPets')}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" width="50pt" height="50pt" viewBox="0 0 62.500003 62.5" xml:space="preserve"><g id="g3" transform="matrix(.99979 0 0 .99979 -1.861 -24.696)" stroke-width="0" stroke-miterlimit="4" stroke-dasharray="none"><path d="M24.468 68.575c5.21 4.45 10.474 8.048 12.66 9.498 7.347-4.79 23.567-16.978 25.556-25.966.519-1.588.753-3.032.753-4.498 0-5.103-2.778-9.807-7.251-12.271-5.713-3.17-13.36-1.709-17.54 3.33-.751.91-2.296.91-3.061 0-4.192-5.042-11.834-6.493-17.54-3.336a14.007 14.007 0 0 0-7.265 12.269c0 1.456.246 2.906.706 4.307.294 1.311.858 2.593 1.576 3.923" id="path5" fill="none" stroke="#000" stroke-width="1.875" stroke-linecap="round" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></g><path d="M.95 33.11h23.148l4.27-9.95 4.597 16.646 6.098-10.285h7.048" id="path4189" fill="none" fill-rule="evenodd" stroke="#000" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="miter" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(RescuingPets);
