import { ReactNode } from 'react';
import InlineSVG from 'react-inlinesvg';
import styled from 'styled-components';

const BannerWrapper = styled.div`
    display: flex;
    background: #ebf8ff;
    color: #2a4365;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border: 1px solid #bee3f8;
    box-shadow:
        0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const BannerContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const Icon = styled(InlineSVG)`
    max-width: 24px;
    margin-right: 1rem;
`;

const Banner = ({ icon, children }: { icon: string; children: ReactNode }) => {
    return (
        <BannerWrapper>
            <BannerContent>
                <Icon src={icon} />
                {children}
            </BannerContent>
        </BannerWrapper>
    );
};

export default Banner;
