import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import flowRight from 'lodash/flowRight';
import { createStructuredSelector } from 'reselect';
import transformImmutableToJS from '../../utils/transformImmutableToJS';
import { sitterProfileBookableServiceUnavailableDatesRequest } from '../../domain/Sitter/actions';
import BookableServiceAvailability from '../Dates/BookableServiceAvailability';
import Select from '../../components/Common/Select';
import { withTranslation } from '../../i18n';
import { selectSitterBookableServiceUnavailableDates } from '../../domain/Sitter/selectors';

const BookableServiceSelect = styled(Select)`
    margin-bottom: 2rem;
`;

const SitterBookableServiceAvailability = ({
    t,
    sitterId,
    bookableServices,
    sitterBookableServiceUnavailableDates,
    sitterProfileBookableServiceUnavailableDatesRequest,
}) => {
    const [loadForBookableService, setLoadForBookableService] = useState(undefined);
    const bookableServiceIds = Object.keys(bookableServices);

    useEffect(() => {
        if (window) {
            const params = new URLSearchParams(window.location.search);

            if (params.service === 'homeVisits') {
                if (bookableServiceIds.includes('oneHomeVisit')) {
                    setLoadForBookableService('oneHomeVisit');
                } else {
                    if (bookableServices.includes('twoHomeVisits')) {
                        setLoadForBookableService('twoHomeVisits');
                    }
                }
            } else {
                if (bookableServiceIds.includes(params.service)) {
                    setLoadForBookableService(params.service);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (typeof loadForBookableService === 'undefined') {
            return;
        }
        sitterProfileBookableServiceUnavailableDatesRequest({
            sitterId,
            date: new Date(),
            bookableService: loadForBookableService,
        });
    }, [loadForBookableService]);

    let options = [
        {
            label: '- ' + t('services.defaultValueBookableServiceSelect') + ' -',
            value: 'noBookableServiceSelected',
            isPlaceholder: true,
        },
    ];

    bookableServiceIds.forEach((bookableServiceId) => {
        options.push({
            value: bookableServiceId,
            label: t(`services.${bookableServiceId}.dropdownLabel`),
        });
    });

    return (
        <>
            <BookableServiceSelect
                onChange={({ target }) => {
                    setLoadForBookableService(target.value);
                }}
                name={'bookableService'}
                value={loadForBookableService}
                options={options}
            />
            {loadForBookableService && (
                <BookableServiceAvailability unavailable={sitterBookableServiceUnavailableDates} />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    const computedData = createStructuredSelector({
        sitterBookableServiceUnavailableDates: selectSitterBookableServiceUnavailableDates,
    })(state);

    return transformImmutableToJS(computedData);
};

export default flowRight(
    withTranslation('services'),
    connect(mapStateToProps, {
        sitterProfileBookableServiceUnavailableDatesRequest,
    })
)(SitterBookableServiceAvailability);
