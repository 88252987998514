import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 1168px;
    margin: 1rem auto;
    padding: 1rem;
`;

export const Container = (props) => {
    const { children, className } = props;
    return <Wrapper className={className}>{children}</Wrapper>;
};

export default Container;
