import React from 'react';
import styled from 'styled-components';
import { withTranslation } from '../../i18n';

const Title = styled.h2`
    margin-bottom: 1rem;
`;

const SitterContact = ({ t, reason, name }) => {
    if (reason.awayMode) {
        return (
            <>
                <Title>{t('sitter.unavailable.title')}</Title>
                {t('sitter.unavailable.info', { name })}
            </>
        );
    }

    if (reason.existingClientsOnly) {
        return (
            <>
                <Title>{t('sitter.existingClientsOnly.title')}</Title>
                {t('sitter.existingClientsOnly.info', { name })}
            </>
        );
    }

    return null;
};

export default withTranslation('sitter')(SitterContact);
