import PropTypes from 'prop-types';
import { Fragment } from 'react';
import styled from 'styled-components';
import { withTranslation } from '../../../i18n';
import Crumb from './Crumb';
import Separator from './Separator';

const List = styled.ol`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.25rem;
    li {
        display: block;
        height: fit-content;
    }
`;

const Breadcrumbs = (props) => {
    const { t, crumbs, homeLink, className } = props;
    return (
        <List className={className}>
            <Crumb name={t('components.breadcrumbs.home.title')} link={homeLink} />
            {crumbs.map((crumb, index) => {
                return (
                    <Fragment key={crumb.name + '_' + index + '_fragment'}>
                        <Separator />
                        {index < crumbs.length - 1 ? (
                            <Crumb
                                key={crumb.name + '_' + index}
                                name={crumb.name}
                                link={crumb.link}
                                title={crumb.title}
                                noFollow={crumb.noFollow}
                            />
                        ) : (
                            <li>{crumb.name}</li>
                        )}
                    </Fragment>
                );
            })}
        </List>
    );
};

Breadcrumbs.propTypes = {
    t: PropTypes.func,
    homeLink: PropTypes.string.isRequired,
    crumbs: PropTypes.array.isRequired,
};

export default withTranslation('components')(Breadcrumbs);
