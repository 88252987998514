import React from 'react';
import AcceptedPet from '../../../Shared/Pets/AcceptedPet';
import { withTranslation } from '../../../../i18n';

const Ferrets = ({ t }) => {
    return (
        <AcceptedPet
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" viewBox="0 0 62.5 62.5" xml:space="preserve" id="svg2"><path d="M1.166 41.584c1.488 3.584 5.005 6.087 9.063 6.087 5.41 0 9.807-4.396 9.807-9.807v.068c.384-3.104 1.356-5.109 2.705-7.71 2.976-5.613 10.618-8.995 16.232-6.02l5.749 2.977c1.826.946 4.26.338 4.396-2.165v-5.342c.059-2.748 1.629-4.547 3.179-6.493 3.006-.167 6.32-2.878 9.265-1.826l-.405 3.043c-.55.37-.343.935-.339 1.488l-2.84 2.976v7.237c.125 4.201.214 8.137-2.3 11.43l-.646 7.667c2.82.466 4.46 1.845 4.083 3.385l-3.725-.864s2.057.773 2.797 1.31c0 0 .634 1.146.459 2.048-1.849.018-6.282-.088-6.282-.088-1.197-3.407-1.62-9.94-3.943-10.089-3.763.45-6.346-.363-9.854-1.747-3.024.18-3.975 3.88-3.342 4.641-.658 1.855 5.924 1.333 5.348 4.256l-5.984-1.329c-2.307.84 3.37.597 4.74 1.35 1.377.338 1.354 1.726 1.876 2.918H23.553l-1.894-4.734c-1.69 2.299-4.734 4.53-9.672 5.072-5.597.69-12.409-3.88-10.82-9.739z" id="path6" fill="none" stroke="#000" stroke-width="1.875" stroke-linejoin="round" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></svg>'
            }
            label={t('sitter.aboutHome.acceptedPets.ferrets.label')}
            description={t('sitter.aboutHome.acceptedPets.ferrets.description')}
        />
    );
};

export default withTranslation('sitter')(Ferrets);
