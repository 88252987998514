import React from 'react';
import styled from 'styled-components';
import ResponseTime from './Statistics/ResponseTime';
import ResponsePercentage from './Statistics/ResponsePercentage';
import { withTranslation } from '../../i18n';
import LastActivityStatistic from './Statistics/LastActivityStatistic';

const InlineStatistics = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const SitterHighlightedStatistics = ({ responsePercentage, responseTime, lastActiveInMinutes, className }) => {
    return (
        <InlineStatistics className={className}>
            {lastActiveInMinutes > 0 && <LastActivityStatistic value={lastActiveInMinutes} />}
            {responseTime > 0 && <ResponseTime responseTime={responseTime} />}
            {responsePercentage > 0 && <ResponsePercentage responseRatePercentage={responsePercentage} />}
        </InlineStatistics>
    );
};

export default withTranslation('sitter')(SitterHighlightedStatistics);
