import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.h2`
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const StyledH2 = ({ children, className }) => {
    return <Wrapper className={className}>{children}</Wrapper>;
};

StyledH2.propTypes = {
    className: PropTypes.string,
};

export default StyledH2;
