import React from 'react';
import { SkillIconWithLabel } from '../styles/SkillIconWithLabel';
import { withTranslation } from '../../../../i18n';

const BehaviouralProblems = ({ t }) => {
    return (
        <SkillIconWithLabel
            label={t('sitter.skills.experience.behaviouralProblems')}
            inline={true}
            icon={
                '<svg width="52" height="57" viewBox="0 0 52 57" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Artboard" stroke="#000" stroke-width="1.876" stroke-linejoin="round"><g id="attention" transform="translate(3 3)"><path d="M25.007 0h-3.618l-6.084 17.362h15.753L25.007 0zM33.097 23.127H13.3l-2.828 8.118h25.455l-2.829-8.118zM45.957 46.288h-4.77l-3.255-9.31-29.5.032-3.223 9.278H.045v4.605h45.912v-4.605z" id="Shape"/></g></g></g></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(BehaviouralProblems);
