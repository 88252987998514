import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import Colors from '../../styles/colors';
import { PaymentMethodsPerCountry, mapPaymentMethod } from '../../utils/payment-methods.utils';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
    width: fit-content;

    @media (min-width: 1024px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.background.body};
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${Colors.border.card};
`;

const PaymentMethodsByCountry = (props) => {
    const { application } = props;
    const { country } = application;

    return (
        <Grid className='grid w-fit grid-cols-3 gap-sm lg:grid-cols-5'>
            {PaymentMethodsPerCountry[country].map((paymentMethod) => {
                const mappedPaymentMethod = mapPaymentMethod(paymentMethod);
                return (
                    <div key={`paymentMethod-${paymentMethod}`}>
                        <Tooltip title={mappedPaymentMethod.label}>
                            <Center>
                                <mappedPaymentMethod.icon style={{ width: '1.5rem', height: '1.5rem' }} />
                            </Center>
                        </Tooltip>
                    </div>
                );
            })}
        </Grid>
    );
};

export default withApplicationAwareness(PaymentMethodsByCountry);
