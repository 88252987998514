import React from 'react';
import { HomeIconWithLabel } from './styles/HomeIconWithLabel';
import { withTranslation } from '../../../i18n';

const HasFullyFencedBackyard = ({ t }) => {
    return (
        <HomeIconWithLabel
            label={t('sitter.aboutHome.home.features.hasFullyFencedBackyard')}
            inline={true}
            icon={
                '<svg width="20" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M17.143 0l-2.857 2.833V4.25h-1.429V2.833L10 0 7.143 2.833V4.25H5.714V2.833L2.857 0 0 2.833V17h5.714v-1.417h1.429V17h5.714v-1.417h1.429V17H20V2.833L17.143 0zM5 4.25v12.042H.714V3.127l2.143-2.125L5 3.127V4.25zm2.143 10.625H5.714V12.75h1.429v2.125zm0-2.833H5.714v-4.25h1.429v4.25zm0-4.959H5.714V4.958h1.429v2.125zm5-2.833v12.042H7.857V3.127L10 1.002l2.143 2.125V4.25zm2.143 10.625h-1.429V12.75h1.429v2.125zm0-2.833h-1.429v-4.25h1.429v4.25zm0-4.959h-1.429V4.958h1.429v2.125zm5 9.209H15V3.127l2.143-2.125 2.143 2.125v13.165z" fill-rule="nonzero" fill="#00C38A"/></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(HasFullyFencedBackyard);
