import React from 'react';
import { HomeIconWithLabel } from './styles/HomeIconWithLabel';
import { withTranslation } from '../../../i18n';

const Kids = ({ t, kids }) => {
    return (
        <HomeIconWithLabel
            label={t('sitter.aboutHome.home.features.' + kids)}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" width="50pt" height="50pt" viewBox="0 0 62.500001 62.500001" id="svg2" version="1.1"><path d="M51.809 15.494L40.854 26.448v30.913q0 1.725-1.238 2.963-1.238 1.239-2.964 1.239-1.725 0-2.963-1.239-1.238-1.238-1.238-2.963V42.955H30.05V57.36q0 1.725-1.238 2.963-1.238 1.239-2.964 1.239t-2.964-1.239q-1.238-1.238-1.238-2.963V26.448L10.692 15.494q-1.05-1.05-1.05-2.552 0-1.5 1.05-2.55 1.05-1.051 2.55-1.051 1.501 0 2.552 1.05l8.553 8.554h13.806l8.554-8.554q1.05-1.05 2.55-1.05 1.501 0 2.552 1.05 1.05 1.05 1.05 2.551 0 1.501-1.05 2.552z" id="path4159" fill="none" stroke="#000" stroke-width="1.875" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/><path d="M39.654 9.341q0 3.489-2.458 5.946-2.457 2.457-5.946 2.457t-5.946-2.457q-2.457-2.457-2.457-5.946t2.457-5.946Q27.761.938 31.25.938q3.49 0 5.946 2.457 2.458 2.457 2.458 5.946z" id="path4" fill="none" stroke="#000" stroke-width="1.875" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(Kids);
