import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';

const Image = styled.img`
    width: auto;
    height: 100%;
`;

const SquareBox = styled.div`
    display: inline-block;
    position: relative;
    width: 6rem;
    height: 6rem;
    overflow: hidden;
    border-radius: 50%;
`;

const SquareBoxWithNegativeMargin = styled(SquareBox)`
    img {
        margin-left: -24px;
    }
`;

const AnimalAvatar = (props) => {
    const { src, alt, className } = props;

    const hasNoImageSource = typeof src === 'undefined' || src === null;
    const source = hasNoImageSource ? 'https://static1.pawshakecdn.com/icons/icon-placeholder-pet.svg' : src;
    const Box = hasNoImageSource ? SquareBox : SquareBoxWithNegativeMargin;

    return (
        <LazyLoad threshold={200} width={96} height={96} className={className}>
            <Box>
                <Image src={source} alt={alt} title={alt} />
            </Box>
        </LazyLoad>
    );
};

AnimalAvatar.propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.string,
};

export default AnimalAvatar;
