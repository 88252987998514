import React from 'react';
import { HomeIconWithLabel } from './styles/HomeIconWithLabel';
import { withTranslation } from '../../../i18n';

const IsAHome = ({ t }) => {
    return (
        <HomeIconWithLabel
            label={t('sitter.aboutHome.home.features.isAHome')}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0" y="0" width="50pt" height="50pt" viewBox="0 0 62.499999 62.499999" xml:space="preserve"><g id="_x38_03-large-house-toolbar_x40_2x.png" transform="translate(-.787 -3.787) scale(1.52439)"><g id="g4" fill="none" stroke="#000" stroke-width="1.23" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"><path d="M23.876 40.786h-5.72V29.017c0-1.082 1.281-1.961 2.86-1.961s2.86.879 2.86 1.961v11.769z" id="path4182"/><path d="M36.924 18.976V40.88H5.108V18.976" id="path4180"/><path d="M40.901 19.007H27.477l-6.461-8.22-6.46 8.22H1.13V5.087h39.77z" id="path4178"/><path d="M10.151 27.033h3.842v5.24h-3.842z" id="path6"/><path id="path4206" d="M27.927 27.033h3.842v5.24h-3.842z"/></g></g></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(IsAHome);
