import React from 'react';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { getSocialMediaLinks } from '../../Pawshake/Core/SocialMedia';
import { withTranslation } from '../../i18n';
import { generateJsonLDSnippet } from '../../Pawshake/StructuredData/Utils';

const SitterJsonLD = ({
    t,
    application,
    userName,
    userImage,
    sitterUrl,
    placeName,
    country,
    bookableRates,
    currency,
    coordinates,
}) => {
    const { domain, locale } = application;
    const logoUrl = domain + '/pawshake_logo.png';

    return generateJsonLDSnippet({
        '@context': 'http://schema.org/',
        '@type': 'WebSite',
        name: 'Pawshake',
        url: domain,
        author: {
            '@type': 'Organization',
            name: 'Pawshake',
            legalName: 'Pawshake Inc.',
            logo: logoUrl,
            image: logoUrl,
            url: domain,
            sameAs: getSocialMediaLinks(locale.country).map((socialLink) => {
                return socialLink.href;
            }),
            member: {
                '@type': 'Person',
                givenName: userName,
                image: userImage,
                url: sitterUrl,
                address: {
                    '@type': 'PostalAddress',
                    addressCountry: country,
                    addressLocality: placeName,
                },
                worksFor: {
                    '@type': 'Organization',
                    name: 'Pawshake',
                    legalName: 'Pawshake Inc.',
                    logo: logoUrl,
                    image: logoUrl,
                    url: domain,
                },
                hasOfferCatalog: {
                    '@type': 'OfferCatalog',
                    name: '',
                    itemListElement: Object.keys(bookableRates).map((serviceId) => {
                        return {
                            '@type': 'Offer',
                            price: bookableRates[serviceId].basePrice,
                            priceCurrency: currency,
                            itemOffered: {
                                '@type': 'Service',
                                serviceType: t('services.' + serviceId + '.label'),
                                description: t('services.' + serviceId + '.description'),
                            },
                            areaServed: {
                                '@type': 'Place',
                                address: {
                                    '@type': 'PostalAddress',
                                    name: placeName,
                                },
                                geo: {
                                    '@type': 'GeoCoordinates',
                                    latitude: coordinates.latitude,
                                    longitude: coordinates.longitude,
                                },
                            },
                        };
                    }),
                },
            },
        },
    });
};

export default withApplicationAwareness(withTranslation('services')(SitterJsonLD));
