import {
    IcAffirm,
    IcAmericanExpress,
    IcApplePay,
    IcBancontact,
    IcCreditCard,
    IcEps,
    IcGooglePay,
    IcGrabPay,
    IcIDeal,
    IcKlarna,
    IcLink,
    IcMastercard,
    IcMobilePay,
    IcPayNow,
    IcRevolut,
    IcSepa,
    IcSwish,
    IcVisa,
    IcZip,
} from '../styles/payment-methods.icons';

export const ALL_PAYMENT_METHODS = [
    'affirm',
    'americanExpress',
    'applePay',
    'bancontact',
    'eps',
    'googlePay',
    'grabPay',
    'ideal',
    'klarna',
    'link',
    'masterCard',
    'mobilePay',
    'payNow',
    'revolut',
    'sepa',
    'swish',
    'visa',
    'zip',
] as const;
export type PawshakePaymentMethod = (typeof ALL_PAYMENT_METHODS)[number];

export const PaymentMethodsPerCountry: Record<string, PawshakePaymentMethod[]> = {
    AT: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'eps', 'sepa', 'link', 'klarna'],
    AU: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna', 'zip'],
    BE: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'bancontact', 'sepa', 'link', 'klarna'],
    CA: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna', 'affirm'],
    CH: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna'],
    DE: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'sepa', 'link', 'klarna'],
    DK: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna'],
    FI: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'sepa', 'link', 'klarna', 'mobilePay'],
    FR: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'sepa', 'link', 'klarna'],
    GB: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna', 'revolut'],
    HK: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna'],
    IE: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'sepa', 'link', 'klarna'],
    IT: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'sepa', 'link', 'klarna'],
    JP: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna'],
    LU: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'sepa', 'link', 'klarna'],
    NL: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'ideal', 'sepa', 'link', 'klarna'],
    NO: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna', 'mobilePay'],
    NZ: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'link', 'klarna'],
    SE: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'swish', 'link', 'klarna', 'mobilePay'],
    SG: ['masterCard', 'visa', 'americanExpress', 'applePay', 'googlePay', 'payNow', 'link', 'klarna', 'grabPay'],
};

const iconForPaymentMethod = (paymentMethod: PawshakePaymentMethod) => {
    switch (paymentMethod) {
        case 'affirm':
            return IcAffirm;
        case 'americanExpress':
            return IcAmericanExpress;
        case 'applePay':
            return IcApplePay;
        case 'bancontact':
            return IcBancontact;
        case 'eps':
            return IcEps;
        case 'googlePay':
            return IcGooglePay;
        case 'grabPay':
            return IcGrabPay;
        case 'ideal':
            return IcIDeal;
        case 'klarna':
            return IcKlarna;
        case 'link':
            return IcLink;
        case 'masterCard':
            return IcMastercard;
        case 'mobilePay':
            return IcMobilePay;
        case 'payNow':
            return IcPayNow;
        case 'revolut':
            return IcRevolut;
        case 'sepa':
            return IcSepa;
        case 'swish':
            return IcSwish;
        case 'visa':
            return IcVisa;
        case 'zip':
            return IcZip;
        default:
            return IcCreditCard;
    }
};

const labelForPaymentMethod = (paymentMethod: PawshakePaymentMethod) => {
    switch (paymentMethod) {
        case 'affirm':
            return 'Affirm';
        case 'americanExpress':
            return 'American Express';
        case 'applePay':
            return 'Apple Pay';
        case 'bancontact':
            return 'Bancontact';
        case 'eps':
            return 'Eps';
        case 'googlePay':
            return 'Google Pay';
        case 'grabPay':
            return 'Grab Pay';
        case 'ideal':
            return 'iDeal';
        case 'klarna':
            return 'Klarna';
        case 'link':
            return 'Link';
        case 'masterCard':
            return 'Mastercard';
        case 'mobilePay':
            return 'MobilePay';
        case 'payNow':
            return 'PayNow';
        case 'revolut':
            return 'Revolut Pay';
        case 'sepa':
            return 'Sepa';
        case 'swish':
            return 'Swish';
        case 'visa':
            return 'Visa';
        case 'zip':
            return 'Zip';
        default:
            return 'Creditcard';
    }
};

export const mapPaymentMethod = (paymentMethod: PawshakePaymentMethod) => {
    return {
        icon: iconForPaymentMethod(paymentMethod),
        label: labelForPaymentMethod(paymentMethod),
    };
};
