import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import { withTranslation } from '../../../i18n';
import { formatPercentage } from '../../../utils/numbers.utils';
import Statistic from '../../Shared/Statistic/Statistic';

const ResponsePercentage = ({ t, application, responseRatePercentage }) => {
    const { locale } = application;
    return (
        <Statistic
            value={formatPercentage(responseRatePercentage, locale.toString())}
            label={t('sitter.statistics.responseRate')}
            icon={
                <svg viewBox='0 0 18 14' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M9 4.667V.333A.327.327 0 0 0 8.679 0a.306.306 0 0 0-.187.069L8.49.064.132 6.397l.003.005A.33.33 0 0 0 0 6.667c0 .11.055.203.135.264l-.003.005 8.357 6.333.003-.005c.053.04.116.07.187.07A.327.327 0 0 0 9 13V8.667c6.107 0 9 5.333 9 5.333 0-9.333-9-9.333-9-9.333z'
                        fillRule='nonzero'
                        fill='#00BF8E'
                    />
                </svg>
            }
        />
    );
};

export default withApplicationAwareness(withTranslation('sitter')(ResponsePercentage));
