import React from 'react';
import styled from 'styled-components';
import InlineSVG from 'react-inlinesvg';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
`;

const Icon = styled(InlineSVG)`
    fill: #00c38a;
    display: flex;
    margin-right: 0.5rem;

    svg {
        width: 21px !important;
        height: 21px !important;

        path {
            stroke: #00c38a;
        }
    }
`;

const InlineIcon = styled(SVGInline)`
    fill: #00c38a;
    display: flex;
    margin-right: 0.5rem;

    svg {
        width: 21px !important;
        height: 21px !important;

        path {
            stroke: #00c38a;
        }
    }
`;

const Label = styled.span`
    color: #939393;
    line-height: 1rem;
    display: flex;
    align-items: center;
`;

const IconWithLabel = (props) => {
    const { icon, label, className, inline } = props;

    return (
        <Wrapper className={className}>
            {inline ? <InlineIcon svg={icon} /> : <Icon src={icon} cacheGetRequests={true} />}
            <Label>{label}</Label>
        </Wrapper>
    );
};

IconWithLabel.defaultProps = {
    inline: false,
};

IconWithLabel.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default IconWithLabel;
