import React from 'react';
import { HomeIconWithLabel } from './styles/HomeIconWithLabel';
import { withTranslation } from '../../../i18n';

const OutdoorArea = ({ t, outdoorArea }) => {
    return (
        <HomeIconWithLabel
            label={t('sitter.aboutHome.home.features.' + outdoorArea)}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" width="50pt" height="50pt" viewBox="0 0 62.5 62.499999" xml:space="preserve"><path d="M23.803 61.673C24.93 17.939 5.926 1.597 5.926 1.597c28.57 14.661 26.981 60.076 26.981 60.076" id="path4137" fill="none" fill-rule="evenodd" stroke="#000" stroke-width="1.875" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/><path id="path4139" d="M32.898 61.661c-1.19-40.1 18.44-50.29 18.44-50.29-15.664 2.882-22.546 20.37-22.546 20.37" fill="none" fill-rule="evenodd" stroke="#000" stroke-width="1.875" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/><path d="M47.152 61.661c.047-20.364 12.833-37.634 12.833-37.634-21.37 7.085-22.314 37.63-22.314 37.63" id="path4141" fill="none" fill-rule="evenodd" stroke="#000" stroke-width="1.875" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/><path id="path4143" d="M14.492 61.676C16.039 38.21.63 24.204.63 24.204 25.81 36.34 23.81 61.656 23.81 61.656" fill="none" fill-rule="evenodd" stroke="#000" stroke-width="1.875" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(OutdoorArea);
