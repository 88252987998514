import { withTranslation } from '../../../i18n';
import { PoliceCheckBadge } from '../../../styles/icons';
import Statistic from '../../Shared/Statistic/Statistic';

const PoliceCheck = ({ t }) => {
    return (
        <Statistic
            value={t('sitter.statistics.policeCheck')}
            label={t('sitter.statistics.verified')}
            icon={<PoliceCheckBadge />}
        />
    );
};

export default withTranslation('sitter')(PoliceCheck);
