import React from 'react';
import IconWithLabel from '../../Shared/IconWithLabel/IconWithLabel';
import { withTranslation } from '../../../i18n';

const DogTraining = ({ t }) => {
    return (
        <IconWithLabel
            inline={true}
            icon={
                '<svg width="22" height="14" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M2.323 13.645a.322.322 0 1 1-.001-.644.322.322 0 0 1 0 .644z"/><path d="M16 6l-5.005 1C9 6.602 7.82 6.37 6 6.007" stroke="#00BF8E"/><path d="M21 4a.428.428 0 0 0-.298-.4c-3.171-.86-6.364-1.717-9.544-2.573l-.001.003A.476.476 0 0 0 11 1a.477.477 0 0 0-.157.03l-.001-.003-9.546 2.57c-.213.096-.229.278-.296.403 0 .184.125.338.298.399.535.15 1.092.297 1.636.443a7.65 7.65 0 0 0-.417 1.334c-.608.13-1.062.64-1.062 1.252 0 .562.385 1.034.917 1.209-.097 1.187-.405 2.646-1.27 3.665a.413.413 0 0 0 .07.603.59.59 0 0 0 .284.093c.097.014.273-.069.356-.16.282-.16 1.098-1.906 1.301-2.923l.624 2.745a.45.45 0 0 0 .444.339.49.49 0 0 0 .096-.01.43.43 0 0 0 .35-.509l-.928-4.078c.293-.236.483-.583.483-.974 0-.496-.302-.922-.739-1.136.097-.413.218-.823.377-1.21l2.123.571-.395 5.587-.003.044s4.023.947 5.723 1.194l5.173-1.237c-.108-1.842-.253-3.73-.384-5.588l4.647-1.251c.213-.096.229-.278.296-.402z" stroke="#00BF8E"/></g></svg>'
            }
            label={t('sitter.additionalOffers.dogTraining')}
        />
    );
};

export default withTranslation('sitter')(DogTraining);
