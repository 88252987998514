import React from 'react';

export const generateJsonLDSnippet = (json) => {
    return (
        <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(json),
            }}
        />
    );
};
