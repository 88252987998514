import Error from 'next/error';
import Head from 'next/head';
import parameterize from 'parameterize-string';
import { useEffect } from 'react';
import { getServiceSlugInLocale } from '../Application/Search/ServiceSlugs';
import { trackSitterPageNotFound, trackSitterPageUnknownError } from '../Application/Sitter/Analytics';
import SitterProfile from '../Infrastructure/Sitter/SitterProfile';
import { PAGE_SIZE } from '../Infrastructure/Sitter/SitterReviews';
import { getDomainBasedUponLocale } from '../Pawshake/Core/Domain';
import { getLocaleFromString } from '../Pawshake/Core/Locale/Locale';
import { petSittingService } from '../Pawshake/Core/Service';
import { sitterDetailRequest, sitterDetailReviewsRequest } from '../domain/Sitter/actions';
import { selectDetailRedirectToPage, selectDetailRequestStatus } from '../domain/Sitter/selectors';

const Sitter = (props) => {
    const { statusCode } = props;

    useEffect(() => {
        if (statusCode > 200) {
            statusCode === 404 ? trackSitterPageNotFound() : trackSitterPageUnknownError();
        }
    }, []);

    if (statusCode > 200) {
        return (
            <>
                <Head>
                    <title>Pawshake</title>
                    <meta name='robots' content='noindex' />
                </Head>
                <Error statusCode={statusCode} />
            </>
        );
    }

    return <SitterProfile {...props} />;
};

Sitter.getInitialProps = async (ctx) => {
    const { store, req, query, isServer, res } = ctx;

    let sitterId = isServer ? req.params.sitterId : query.sitterId;
    const sitterTitle = isServer ? req.params.sitterTitle : query.sitterTitle;
    const localeString = isServer ? req.params.localeString || query.localeString : query.localeString;

    const locale = getLocaleFromString(localeString);
    const domain = getDomainBasedUponLocale(locale);

    if (typeof sitterId === 'undefined') {
        const examined = sitterTitle.split('-');
        sitterId = examined.pop();
    }

    const accessedByUrl = isServer ? `${domain}${req.path}` : false;

    store.dispatch(sitterDetailRequest({ sitterId, accessedByUrl }));
    store.dispatch(
        sitterDetailReviewsRequest({
            sitterId,
            pageSize: PAGE_SIZE,
            pageNumber: 1,
        })
    );

    await store.stopSagaTask();

    const status = selectDetailRequestStatus(store.getState());

    if (status === 303) {
        const redirectToPageData = selectDetailRedirectToPage(store.getState());

        let redirectToPage;
        if (typeof redirectToPageData.place === 'undefined') {
            redirectToPage = redirectToPageData;
        } else {
            const addressSlug = parameterize(redirectToPageData.place);
            const servicePath = getServiceSlugInLocale(petSittingService, localeString);
            redirectToPage = `${domain}/${servicePath}/${addressSlug}`;
        }

        res.writeHead(301, {
            Location: redirectToPage,
        });
        res.end();
    }

    if (isServer && status) {
        res.statusCode = status;
    }

    return {
        localeString,
        statusCode: status,
        domain: domain,
        namespacesRequired: ['sitter', 'vibrancy', 'finance', 'booking'],
    };
};

export default Sitter;
