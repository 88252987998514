import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const SubTitle = ({ children, className }) => {
    return <Title className={className}>{children}</Title>;
};

export default SubTitle;
