import React from 'react';
import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import { Trans } from '../../../i18n';

const FooterCopyright = (props) => {
    const { className } = props;

    // override global a { line-height: 46px }
    const style = { lineHeight: 'inherit' };

    return (
        <div className={className}>
            <Trans
                i18nKey='footer:footer.copyright'
                values={{ year: new Date().getFullYear() }}
                components={{
                    1: <a href={'/terms-and-conditions'} style={style} />,
                    2: <a href={'/privacy-policy'} style={style} />,
                    3: <a href={'/impressum'} style={style} />,
                }}
            />
        </div>
    );
};

export default withApplicationAwareness(FooterCopyright);
