import React from 'react';
import styled from 'styled-components';
import UnorderedHyperlinkList from '../Shared/UnorderedHyperlinkList/UnorderedHyperlinkList';
import { homeVisits, petSitting } from '../../Pawshake/Core/Service';
import { withApplicationAwareness } from '../../contexts/ApplicationContext';
import { withTranslation } from '../../i18n';

const CityLinks = styled(UnorderedHyperlinkList)`
    margin-right: 1rem;
    a {
        color: #939393;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const SitterMoreServicesAroundPlace = ({ t, searchPageUrls, application }) => {
    const { locale } = application;

    const filteredSearchPageUrls = searchPageUrls.filter(
        (searchPageUrl) =>
            searchPageUrl.service !== homeVisits &&
            searchPageUrl.service !== petSitting &&
            searchPageUrl.locale === locale.toString()
    );

    const items = filteredSearchPageUrls.map((searchPageUrl) => {
        return {
            text: t(`search.header.title.${searchPageUrl.service}`, {
                location: searchPageUrl.placeName,
            }),
            href: searchPageUrl.url,
        };
    });

    return <CityLinks items={items} />;
};

export default withApplicationAwareness(withTranslation('search')(SitterMoreServicesAroundPlace));
