import styled from 'styled-components';
import React from 'react';
import Service from '../Service/Service';
import breakpoint from 'styled-components-breakpoint';
import SitterUserAvatar from '../Shared/Avatar/SitterUserAvatar';
import StarRating from '../Shared/StarRating/StarRating';

const Wrapper = styled.div`
    padding: 1rem;
    position: relative;
    margin-top: 3rem;
    margin-bottom: 1rem;
    border: 1px solid #cecece;
    border-radius: 4px;
`;

const ReviewerAvatar = styled(SitterUserAvatar)`
    position: absolute;
    right: 1rem;
    top: -2rem;
    width: 5rem;
    height: 5rem;
    img {
        border: 1px solid #cecece;
    }
`;

const Reviewer = styled.div`
    color: #939393;
    margin-bottom: 1rem;

    ${breakpoint('sm')`
    position: absolute;
    right: 8rem;
    top: 1rem;
    text-align: right;
  `};
`;
const Comment = styled.div`
    color: #939393;

    ${breakpoint('sm')`
    margin-top: 1rem;
  `};
`;

const SitterReview = (props) => {
    const { reviewerImage, reviewerName, service, rating, comment, reviewDate, className } = props;

    return (
        <Wrapper className={className}>
            <ReviewerAvatar alt={reviewerName} src={reviewerImage} />
            <StarRating rating={rating} />
            <Service service={service}>
                <Service.Label />
            </Service>
            <Reviewer>{reviewerName + ', ' + reviewDate}</Reviewer>
            <Comment>
                {comment.split('\n').map((line, index) => (
                    <p key={`${reviewerName}_comment_${index}`}>{line}</p>
                ))}
            </Comment>
        </Wrapper>
    );
};

export default SitterReview;
