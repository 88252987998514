import React from 'react';
import { SkillIconWithLabel } from '../styles/SkillIconWithLabel';
import { withTranslation } from '../../../../i18n';

const ExoticAnimals = ({ t }) => {
    return (
        <SkillIconWithLabel
            inline={true}
            label={t('sitter.skills.specificSkills.exoticAnimals')}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" viewBox="0 0 62.500001 62.500002" xml:space="preserve" width="50pt" height="50pt"><path d="M55.34 16.2c-6.033.283-4.118 7.264-9.527 7.791.016-.413.025-.829.025-1.252a.615.615 0 0 0-.603-.615c-2.068-.042-5.158-.537-8.43-1.059-3.851-.613-7.834-1.248-10.727-1.248-.827 0-1.513.05-2.098.155-5.517.985-9.745 6.238-13.832 11.317-3.278 4.07-6.372 7.915-9.562 8.594a.616.616 0 1 0 .046 1.215c1.176.16 2.277.313 3.317.462L.715 46.305h5.508l1.963-4.122c1.431.216 2.721.419 3.89.6 1.027.16 1.96.306 2.824.435l-1.32 3.087h5.546v-2.52c1.475.159 2.817.242 4.25.242.715 0 1.435-.021 2.206-.064a29.83 29.83 0 0 0 4.052-.5v2.843h4.905l-.742-4.025c1.772-.682 3.536-1.621 5.148-2.91.623-.5 1.202-1.038 1.74-1.61 2.815 3.548 2.688 8.545 2.688 8.545h5.722c-.125-4.324-3.883-9.25-6.037-11.731.89-1.548 1.568-3.27 2.03-5.165 4.53-1.542 8.952-6.005 10.895-6.67 2.044-.702 4.52-.188 4.52-2.686 0-3.036-3.354-3.938-5.162-3.854z" id="path3" fill="none" stroke="#000" stroke-width="1.875" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(ExoticAnimals);
