import React from 'react';
import SubTitle from '../../../Infrastructure/Shared/Titles/SubTitle';
import { withApplicationAwareness } from '../../../contexts/ApplicationContext';
import { withTranslation } from '../../../i18n';
import UnorderedHyperlinkList from '../../../Infrastructure/Shared/UnorderedHyperlinkList/UnorderedHyperlinkList';

const About = ({ t, application }) => {
    const { locale, zendeskLanguage } = application;

    let items = [
        {
            text: t('footer.categories.about.ourStory'),
            href: `/aboutUs?localeString=${locale.toString()}`,
            as: '/about-us',
        },
        {
            text: t('footer.categories.about.inPress'),
            href: '/press',
        },
        {
            text: t('footer.categories.about.blog'),
            href: '/blog/',
        },
        {
            text: t('user:user.menu.help'),
            href: `https://pawshake.zendesk.com/hc/${zendeskLanguage}`,
        },
    ];

    return (
        <>
            <SubTitle>{t('footer.categories.about.title')}</SubTitle>
            <UnorderedHyperlinkList items={items} />
        </>
    );
};

export default withApplicationAwareness(withTranslation(['footer'])(About));
