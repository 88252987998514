import React from 'react';
import IconWithLabel from '../../Shared/IconWithLabel/IconWithLabel';
import { withTranslation } from '../../../i18n';

const DogGrooming = ({ t }) => {
    return (
        <IconWithLabel
            inline={true}
            icon={
                '<svg width="21" height="21" xmlns="http://www.w3.org/2000/svg"><g stroke="#00BF8E" fill="none" fill-rule="evenodd" stroke-linecap="round"><path d="M2.536 11.899v1.143a4.808 4.808 0 0 0 4.804 4.805h5.49a4.808 4.808 0 0 0 4.804-4.805V11.9H2.536z" stroke-linejoin="round"/><path d="M7.86 3.646c-.041-1.822-.715-2.422-2.737-2.442-1.81-.019-2.552.75-2.552 2.46l-.054 8.042m3.818-7.838H9.36"/><path d="M.307 11.87l2.05.004" stroke-linejoin="round"/><path d="M19.855 11.891h-2.722"/><path d="M6.48 17.987c-.364 1.51-1.635 1.709-2.326 1.677" stroke-linejoin="round"/><path d="M13.724 17.946c.282.957.773 1.713 2.347 1.708"/></g></svg>'
            }
            label={t('sitter.additionalOffers.grooming')}
        />
    );
};

export default withTranslation('sitter')(DogGrooming);
