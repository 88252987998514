import React from 'react';
import IconWithLabel from '../../Shared/IconWithLabel/IconWithLabel';
import { withTranslation } from '../../../i18n';

const PickupDropOff = ({ t }) => {
    return (
        <IconWithLabel
            inline={true}
            icon={
                '<svg width="21" height="19" xmlns="http://www.w3.org/2000/svg"><g stroke="#00BF8E" fill="none" fill-rule="evenodd"><path d="M19.202 8.356c-.663-6.636-3.98-7.3-4.644-7.3h-3.981-3.982c-.663 0-3.98.664-4.644 7.3a.664.664 0 0 0-.664.663v6.968a1.659 1.659 0 1 0 3.318 0v-.996h11.943v.995a1.659 1.659 0 1 0 3.318 0V9.02a.664.664 0 0 0-.664-.663z"/><path d="M6.949 2.32h7.256c.302 0 3.023.604 3.628 6.046H3.32c.605-5.442 3.326-6.047 3.629-6.047zM4.48 13.125a1.327 1.327 0 1 0 0-2.655 1.327 1.327 0 0 0 0 2.655zM16.424 13.125a1.327 1.327 0 1 0 0-2.655 1.327 1.327 0 0 0 0 2.655z"/></g></svg>'
            }
            label={t('sitter.additionalOffers.pickupDropOff')}
        />
    );
};

export default withTranslation('sitter')(PickupDropOff);
