import React from 'react';
import { SkillIconWithLabel } from '../styles/SkillIconWithLabel';
import { withTranslation } from '../../../../i18n';

const YearsOfExperience = ({ t, years }) => {
    return (
        <SkillIconWithLabel
            label={t('sitter.skills.specificSkills.experience.label', {
                years: '$t(sitter.skills.specificSkills.experience.' + years + ')',
            })}
            inline={true}
            icon={
                '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0" y="0" width="50pt" height="50pt" viewBox="0 0 62.500001 62.500001" xml:space="preserve"><g id="g3" transform="matrix(.98388 0 0 .98388 8.48 -8.386)" stroke="#000" stroke-width="1.906" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"><g id="_x32_2" stroke="#000" stroke-width="1.906" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"><g id="g6" stroke="#000" stroke-width="1.906" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"><path d="M33.863 53.198a1.173 1.173 0 0 0-.616 1.135v15.768l-9.296-7.207a1.167 1.167 0 0 0-1.427 0l-9.282 7.207V54.338c0-.438-.245-.839-.634-1.041C4.832 49.309 0 41.398 0 32.657 0 19.875 10.38 9.476 23.142 9.476c12.761 0 23.143 10.399 23.143 23.18a23.13 23.13 0 0 1-12.422 20.542z" id="path8" fill="none" stroke="#000" stroke-width="1.906" stroke-miterlimit="4" stroke-dasharray="none" stroke-opacity="1"/></g></g></g></svg>'
            }
        />
    );
};

export default withTranslation('sitter')(YearsOfExperience);
